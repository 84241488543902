import React, { Component } from "react";
import { CustomGesture, moves } from "react-touch";
import { getPlace } from "./func";
import { filter } from "./chulsFunc";
class NameList extends Component {
  getBan(student) {
    return this.props.onoff === "on" &&
      (this.props.time === "12:40" || this.props.time === "1:40")
      ? student.ban
      : null;
  }
  getButtonClasses(student) {
    let class1 = "btn m-1 btn-";
    let class2 = "warning";
    if (this.props.onoff === "on") {
      return class1 + class2;
    }
    if (this.props.onoff === "off") {
      return class1 + class2;
    }
  }
  getBadgeClasses1(student) {
    let class1 = "ml-2 badge badge-";
    let class2 = "info";
    if (this.props.onoff === "on") {
      return class1 + class2;
    }
    if (this.props.onoff === "off") {
      return class1 + class2;
    }
  }
  getBadges(student) {
    return (
      <>
        <span className={this.getBadgeClasses1(student)}>
          {getPlace(student, this.props.day, this.props.onoff)}
        </span>
      </>
    );
  }

  fieldSorter = fields => (a, b) =>
    fields
      .map(o => {
        let dir = 1;
        if (o[0] === "-") {
          dir = -1;
          o = o.substring(1);
        }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);
  render() {
    let students = [...this.props.students];
    students = filter(
      students,
      this.props.day,
      this.props.onoff,
      this.props.time
    );

    let students1학년 = [...students];
    students1학년 = students1학년.filter(m => m.hag === 1);

    let students그밖에 = [...students];
    students그밖에 = students그밖에.filter(m => m.hag !== 1);
    students1학년 = students1학년.sort(this.fieldSorter(["ban", "name"]));
    let students1 = [...students1학년, ...students그밖에];
    students =
      this.props.time === "12:40" || this.props.time === "1:40"
        ? students1
        : students;
    const 복구제스쳐 = [
      moves.RIGHT,
      moves.DOWNRIGHT,
      moves.DOWN,
      moves.DOWNLEFT,
      moves.LEFT
    ];
    return (
      <div>
        {students.map(student => (
          <>
            <CustomGesture
              config={복구제스쳐}
              onGesture={() => this.cancelAction(student, this.props.onoff)}
            >
              <button
                className={this.getButtonClasses(student)}
                key={student._id}
                onClick={() => this.handleClick(student, this.props.onoff)}
              >
                {student.name}
                {this.getBadges(student)}
              </button>
            </CustomGesture>
          </>
        ))}
      </div>
    );
  }
}

export default NameList;
