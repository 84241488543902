import React from "react";
import { createKey } from "../../common/func7";
import { Td } from './td'

export const TableBody = (props) => {
  const handleMusicClick = (item, changedItem) => {
    props.onFindMusic(item, changedItem);
  };
  const { data, fontSize, onChangeColor, onChanged, onChanged1, onWinOpen, selectedMusic, onEditMode, small } = props;
  let columns1
  return (
    <tbody>
      {data.map(item => {
        if (item.v_f) {
          if (small)
            columns1 = [
              { path: "name", label: "이름", minWidth: 70 },
              { path: "button", label: "" },
              { path: "melodizer_s", label: "작곡자" },
              { path: "musicTitle_s", label: "곡명" },
              { path: "musicBook_s", label: "교재명" },
            ];
          else
            columns1 = [
              { path: "name", label: "이름", minWidth: 70 },
              { path: "hag", label: "학년" },
              { path: "jindo", label: "진도", minWidth: 100 },
              { path: "concr", label: "콩쿨" },
              { path: "button", label: "" },
              { path: "melodizer_s", label: "작곡자" },
              { path: "musicTitle_s", label: "곡명" },
              { path: "musicBook_s", label: "교재명" },
            ];
        } else {
          if (small)
            columns1 = [
              { path: "name", label: "이름", minWidth: 70 },
              { path: "button", label: "" },
              { path: "melodizer", label: "작곡자" },
              { path: "musicTitle", label: "곡명" },
              { path: "musicBook", label: "교재명" },
            ];
          else
            columns1 = [
              { path: "name", label: "이름", minWidth: 70 },
              { path: "hag", label: "학년" },
              { path: "jindo", label: "진도", minWidth: 100 },
              { path: "concr", label: "콩쿨" },
              { path: "button", label: "" },
              { path: "melodizer", label: "작곡자" },
              { path: "musicTitle", label: "곡명" },
              { path: "musicBook", label: "교재명" },
            ];
        }
        return (
          <tr key={item._id}>
            {columns1.map(column =>
              <Td
                key={createKey(item, column)}
                item={item}
                column={column}
                fontSize={fontSize}
                handleClick={(path, value) => onEditMode(item, path, value)}
                handleMusicClick={handleMusicClick}
                onChangeColor={onChangeColor}
                onChanged={onChanged}
                onChanged1={onChanged1}
                onWinOpen={onWinOpen}
                selectedMusic={selectedMusic}
              ></Td>
            )}
          </tr>
        )
      })}
    </tbody>
  );
}
