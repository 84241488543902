import React from "react";
import { getBookColorHex } from "./colorCode";
import { YYMMDD_Book } from "../common/bookChartFunc";

const Books = ({ Books: data }) => {
  return (
    <td style={{ minWidth: 183 }}>
      {data.books.map(b =>
        b.books.map((b1, idx) => {
          return (
            <div key={idx} className="badge m-0"
              style={{ backgroundColor: getBookColorHex(b1.book) }}
            >
              {YYMMDD_Book(b1.date, b1.book)}&nbsp;
            </div>
          );
        })
      )
      }
    </td >
  );
}

export default Books 