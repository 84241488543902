import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
// import "../students/studentAllCs.css";
// 20200701 import "../../my.css";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  className,
  print,
  esc,
  onChanged,
  onEditMode
}) => {
  return (
    <table className={className}>
      <TableHeader
        columns={columns}
        sortColumn={sortColumn}
        onSort={onSort}
        print={print}
      />
      <TableBody
        columns={columns}
        data={data}
        print={print}
        esc={esc}
        onEditMode={(_id, path) => onEditMode(_id, path)}
        onChanged={(id, path, value) => onChanged(id, path, value)}
      />
    </table>
  );
};
export default Table;
