import React from "react";
import { getCurrentUser } from "../../services/authService";

const user = getCurrentUser()

const BanName = () => (
  < div className="badge badge-danger mx-2" >
    {
      user && user.isPrincipal
        ? "전체"
        : user.name === "HR" || user.name === "YB" ? "베토벤반" : "바하반"
    }
  </div>)

export default BanName;
