import React, { Component } from "react";
import auth from "../../../services/authService";
import Table from "../../common/table19";
// 20200701 import "../../../my.css";

class StudentAllCsTable extends Component {
  columns = [];
  deleteColumn = {
    key: "delete",
    content: student => (
      <div className="noPrint">
        <button
          onClick={
            () => this.props.onDelete(student)
          }
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      </div>
    )
  };
  constructor(props) {
    super();
    const user = auth.getCurrentUser();
    props.columns.map(c => this.columns.push(c));
    if (user && user.isAdmin && !props.print)
      this.columns.push(this.deleteColumn);
  }
  render() {
    const {
      students,
      onSort,
      sortColumn,
      print,
      esc,
      classString,
      onChanged,
      onAdd,
      onDelete,
      onEditMode,
      fontSize
    } = this.props;
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div className="noPrint">
            <code> students/studentAllCsTable.jsx</code>
          </div>
        )}
        <Table
          columns={this.columns}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          onAdd={onAdd}
          onDelete={onDelete}
          className={classString}
          print={print}
          esc={esc}
          onEditMode={(item, path) => onEditMode(item, path)}
          onChanged={(id, path, value) => onChanged(id, path, value)}
          fontSize={fontSize}
        />
      </>
    );
  }
}

export default StudentAllCsTable;
