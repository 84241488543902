import * as rh from "./ridesHandlers";

export const onFuncs = ({ chuls, setState, doSubmit, submitable, socket }) => {
  // HandlerProvider value funcs
  return {
    onAddButton: ({
      student,
      onoff,
      time,
      dow,
      students,
      allStudents,
      onAddSelect
    }) =>
      rh.handleAddButton({
        student,
        onoff,
        time,
        dow,
        students,
        allStudents,
        onAddSelect,
        chuls,
        setState: setState,
        socket
      }),
    onAddSelect: ({ student, dow, onoff, time }) =>
      rh.handleAddSelect({
        student,
        dow,
        onoff,
        time,
        chuls,
        setState: setState,
        socket
      }),
    onCancelAction: ({ student, onoff }) =>
      rh.handleCancelAction({
        student,
        onoff,
        chuls,
        setState: setState,
        doSubmit: doSubmit,
        socket
      }),
    onChangeCar: ({
      student,
      onoff,
      dow,
      time,
      place,
      timeToday,
      placeToday
    }) =>
      rh.handleChangeCar({
        student,
        onoff,
        dow,
        time,
        place,
        timeToday,
        placeToday,
        chuls,
        setState: setState,
        socket
      }),
    onChangeCarCancel: student =>
      rh.handleChangeCarCancel({
        student,
        chuls,
        setState: setState,
        socket
      }),
    onEditButton: ({ student, onoff, time, dow }) =>
      rh.handleEditButton({
        student,
        onoff,
        time,
        dow,
        chuls,
        setState: setState,
        socket
      }),
    onImageButton: student =>
      rh.handleImageButton({
        student, chuls, setState: setState,
        socket
      }),
    onMoveButton: ({ student, onoff, time, dow }) =>
      rh.handleMoveButton({
        student,
        onoff,
        time,
        dow,
        chuls,
        setState: setState,
        socket
      }),
    onNameClick: ({ student, onoff, dow, todaysDow }) =>
      rh.handleNameClick({
        student,
        onoff,
        dow,
        todaysDow,
        chuls,
        setState: setState,
        doSubmit: doSubmit,
        submitable: submitable,
        socket
      }),
    onNextCar: ({ student, onoff, time, dow }) =>
      rh.handleNextCar({
        student,
        onoff,
        time,
        dow,
        chuls,
        setState: setState,
        socket
      }),
    onRemoveButton: ({ student, onoff, time, dow }) =>
      rh.handleRemoveButton({
        student,
        onoff,
        time,
        dow,
        chuls,
        setState: setState,
        socket
      }),
    onSelectTime: ({ student, dow, onoff, time }) =>
      rh.handleSelectTime({
        student,
        dow,
        onoff,
        time,
        chuls,
        setState: setState,
        socket
      }),
    onShowButtons: ({ student, onoff, time, dow }) =>
      rh.handleShowButtons({
        student,
        onoff,
        time,
        dow,
        chuls,
        setState: setState,
        socket
      }),
    onTodayInRideButton: student =>
      rh.handleTodayInRideButton({
        student,
        chuls,
        setState: setState,
        socket
      }),
    onTodayOutRideButton: student =>
      rh.handleTodayOutRideButton({
        student,
        chuls,
        setState: setState,
        socket
      })
  }
};

// export const on = { onAddButton, onAddSelect, onCancelAction, onChangeCar, onChangeCarCancel, onEditButton, onImageButton, onMoveButton, onNameClick, onNextCar, onRemoveButton, onSelectTime, onShowButtons, onTodayInRideButton, onTodayOutRideButton }

