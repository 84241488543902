import React, { Component } from "react";
import TBody from './bookReportBody'

export default class Table extends Component {
  render() {
    return (
      <>
        <h4 className="text-center">2020년 7월   학생별   피아노 + 이론 교재/진도 현황</h4>
        <table className="table table-bordered">
          <thead >
            <tr>
              <th >교재명</th>
              <th colSpan="5" className="text-center">피아노</th>
              <th colSpan="6" className="text-center">이론</th>

            </tr>
            <tr>
              <th >이름</th>
              <th ><div>바이엘</div><div>체르니</div></th>
              <th ><div>동요집</div><div>소곡집</div><div>소나티네</div></th>
              <th >하농</th>
              <th >반주완성</th>
              <th >기타</th>
              <th >계이름공부</th>
              <th >음악이론</th>
              <th >초코렛문제은행</th>
              <th >음악이론평가문제집</th>
              <th >기타</th>
              <th ><div>음악노트</div><div>음악종합장</div></th>
            </tr>
          </thead>
          <tbody>
            <TBody
              students={this.props.students}
              studentBooks={this.props.studentBooks}
              studentBooks1={this.props.studentBooks}
            />
          </tbody>
        </table>
      </>
    );
  }
}