import React, { Component } from "react";
import fileName from "../../common/func2";
import Loading from '../../common/loading'
import { btnStyle1 } from "../../common/setting";
import auth from "../../../services/authService";
import { getLessons } from "../../../services/lessonService";
import { getStudentsJindo as getStudents } from "../../../services/studentService";
import { makeYear, getTeacherName } from "../../common/chulsFunc";
import { 진도, getThisMonthYYYYMM } from "../../common/jindoFunc";
import { onlyUnique, fieldSorter } from "../../common/func1";
export default class Lessons extends Component {
  state = {
    lessons: [],
    students: [],
    date: "",
    date1: "",
    month: "",
    year: "",
    td_teacher: "",
    td_pJin: "",
    td: {}
  };
  async componentDidMount() {
    let today = new Date();
    let m = String(today.getMonth() + 1); //January is 0!
    // m = '8' // 지나간 달 조회할때
    let yyyy = today.getFullYear();
    const { data: lessons } = await getLessons({ month: getThisMonthYYYYMM() });
    // 지나간 달 조회할때
    // const { data: lessons } = await getLessons({ month: '201908' });
    const { data: students } = await getStudents();
    this.setState({
      lessons,
      students,
      month: m,
      year: yyyy
    });
  }
  //todo: 출석부 피아노반 번호 각각 매길것
  handleClickTd = lesson => {
    this.setState({
      win1open: true,
      td_teacher: lesson[0].teacher,
      td_pJin: lesson[0].pJin.split("\n")[0]
    });
  };
  dates = makeYear();
  user = auth.getCurrentUser();
  teacherClassName = ["danger", "dark", "primary", "success", "warning"];
  tClass = []; // {teacher : Class}
  render() {
    let teachers = this.state.lessons.map(m => m.teacher);
    teachers = teachers.filter(onlyUnique).sort();
    teachers = ["ES", "HN", "YB", "HR"]; // 선생님 출력순서 바꿈. 바하반,베토벤반
    let students1 = this.state.students.sort(fieldSorter(["pianoBan", "name"]));
    students1 = students1.filter(s => s.pianoBan !== "");
    let students = students1.map(m => m.name);
    teachers.map((m, index) => {
      const a = {};
      a[m] = this.teacherClassName[index % 5];
      this.tClass.push(a);
      return null
    });
    if (students.length === 0) {
      return <Loading></Loading>
    }
    else
      return (
        <>
          {fileName("chulBu.jsx", this.user)}
          {fileName(
            "레슨에서 출석부만들기 --> chulBu1.jsx  (원본, 클릭되지만 엉터리, 반정렬 안되있고)",
            this.user
          )}
          <br />
          {fileName(
            "퇴근전 체크하는 출석부",
            this.user
          )}
          <div className="form-row align-items-center">
            <div className="col-auto">
              {teachers.map((m, index) => (
                <span
                  className={
                    "badge m-2 badge-" + this.teacherClassName[index % 5]
                  }
                >
                  {m}
                </span>
              ))}
              {/* //todo: Change to <Ibtn/> */}
              <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
                this.props.history.push("/Chuls5");
              }} style={btnStyle1}
              ></i>
            </div>
          </div>
          <StudentsTable
            students={students}
            teachers={teachers}
            dates={this.dates}
            month={this.state.month}
            lessons={this.state.lessons}
            tClass={this.tClass}
          />
        </>
      );
  }
}
class StudentsTable extends Component {
  render() {
    const student = this.props.lessons.map(m => m.student).filter(onlyUnique);
    // const teacher = this.props.lessons.map(m => m.teacher).filter(onlyUnique);
    const { dates, month } = this.props;
    // const a = String(month);
    const a = month;
    let num = 0;
    let key = 0;
    if (dates[a] !== undefined)
      return (
        <>
          <table className="table table-bordered">
            <thead>
              <tr>
                <td children={"No"} />
                <td>수업일</td>
                {dates[a].map(m => {
                  num = num + 1;
                  return <td>{num}</td>;
                })}
              </tr>

              <tr>
                <td>{student.length}</td>
                <th>날짜</th>
                {dates[a].map(m => (
                  <th>
                    {String(m)
                      .substring(6, 8)
                      .substring(0, 1) === "0"
                      ? String(m).substring(7, 8)
                      : String(m).substring(6, 8)}
                  </th>
                ))}
              </tr>
              <tr>
                <td children={null} />
                <td>학생수</td>
                {dates[a].map(m => {
                  return (
                    <td>
                      {this.props.lessons.filter(l => l.date === m).length > 0
                        ? this.props.lessons.filter(l => l.date === m).length
                        : null}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <TBody1
                key={key}
                teachers={this.props.teachers}
                dates={dates[a]}
                lessons={this.props.lessons}
                tClass={this.props.tClass}
              />
              <TBody
                key={key}
                students={this.props.students}
                dates={dates[a]}
                lessons={this.props.lessons}
                tClass={this.props.tClass}
              />
            </tbody>
          </table>
        </>
      );
    else return null;
  }
}
class TBody1 extends Component {
  render() {
    return this.props.teachers.map((name, index) => {
      return (
        <tr>
          <td style={{ minWidth: 40 }}>{index + 1}</td>
          <th style={{ minWidth: 70 }}>{getTeacherName(name)}</th>
          <ShowDates1
            key={this.props.key + "b" + name}
            lessons={this.props.lessons}
            teacher={name}
            dates={this.props.dates}
            tClass={this.props.tClass}
          />
        </tr>
      );
    });
  }
}
class TBody extends Component {
  render() {
    return this.props.students.map((name, index) => {
      return (
        <tr>
          <td style={{ minWidth: 40 }}>{index + 1}</td>
          <th style={{ minWidth: 70 }}>{name}</th>
          <ShowDates
            key={this.props.key + "b" + name}
            lessons={this.props.lessons}
            student={name}
            dates={this.props.dates}
            tClass={this.props.tClass}
          />
        </tr>
      );
    });
  }
}
class ShowDates1 extends Component {
  render() {
    return this.props.dates.map(d => (
      <>
        <Teacher1
          key={this.props.key + "a" + d}
          date={d}
          lessons={this.props.lessons}
          teacher={this.props.teacher}
          tClass={this.props.tClass}
        />
      </>
    ));
  }
}
class ShowDates extends Component {
  render() {
    return this.props.dates.map(d => (
      <>
        <Lesson1
          key={this.props.key + "a" + d}
          date={d}
          lessons={this.props.lessons}
          student={this.props.student}
          tClass={this.props.tClass}
        />
      </>
    ));
  }
}
class Teacher1 extends Component {
  render() {
    const lesson = this.props.lessons
      .filter(l => l.date === this.props.date)
      .filter(m => m.teacher === this.props.teacher);
    return (
      <td key={this.props.key + lesson.teacher + lesson.date}>
        {lesson.length > 0 ? lesson.length : null}
      </td>
    );
  }
}
class Lesson1 extends Component {
  render() {
    const lesson = this.props.lessons
      .filter(l => l.date === this.props.date)
      .filter(m => m.student === this.props.student);
    return (
      <td key={this.props.key + lesson.student + lesson.date}>
        <Lesson
          key={this.props.key + lesson.student + lesson.date}
          lesson={lesson}
          tClass={this.props.tClass}
        />
      </td>
    );
  }
}
class Lesson extends Component {
  a = { ...this.props.lesson[0] };
  render() {
    let pJin;
    let c;
    if (this.a.teacher !== undefined && this.props.tClass !== undefined) {
      this.props.tClass.map(m => {
        if (this.a.teacher in m) c = m[this.a.teacher];
        return null
      });
      pJin = this.a.pJin;
    }
    return (
      <div>
        {/* <badge className="badge badge-info mr-2">
          {this.props.lesson.onTime.substring(11, 16)}
        </badge> */}
        {/* <badge className="badge badge-info mr-2">
          {this.props.lesson.onTime.substring(14, 15)==="0" &&
          this.props.lesson.onTime.substring(15, 16)}
          {this.props.lesson.onTime.substring(14, 15) !== "0" &&
          this.props.lesson.onTime.substring(14, 16)}
          분
      </badge> */}
        {/* {this.props.lesson.student} */}
        {/* {JSON.stringify(this.props.lesson[0])} */}
        {/* {JSON.stringify(a)} */}
        {/* {a.pJin} */}
        {/* {l.pJin} */}
        {/* {this.props.lesson[0].pJin} */}
        <span className={"text-" + c}>{진도(pJin)}</span>
        {/* {this.a.teacher==="HJ" && (
        <span className="text-dark">{진도(this.a.pJin)}</span>
      )}
      {this.a.teacher==="HN" && (
        <span className="text-primary">{진도(this.a.pJin)}</span>
      )}
      {this.a.teacher==="HR" && (
        <span className="text-success">{진도(this.a.pJin)}</span>
      )}
      {this.a.teacher==="원장쌤" && (
        <span className="text-warning">{진도(this.a.pJin)}</span>
      )} */}
        {/* {a.teacher=this.진도(a.pJin)} */}
        {/* {this.진도1(this.props.lesson.pJin)} */}
        {/* <badge className="badge badge-warning mr-2 ml-2">
          {this.props.lesson.pJin}
        </badge> */}
        {/* {this.props.lesson.theory} */}
      </div>
    );
  }
}
