import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Filename from '../common/filename';

const WHO = gql`
query WhoHasNotPaidTuition{whoHasNotPaidTuition{
  id
  studentName
  studentId
  userName
  userId
  dueDate
  paidDate
  amount
  by
  school
  jindo
  discount
  reason
  sender
}}
`;

const Unpaid = (props) => {
  const { loading, error, data } = useQuery(WHO);
  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;

  return (
    <>
      <br />
      <br />
      {Filename('unpaid.jsx')}
      <div className="container text-center">
        <div className='row'>
          <h4 className='col-12 mb-5'>교 육 비 미 납 자</h4>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">이름</th>
              <th scope="col">납부기일</th>
              <th scope="col">미납교육비</th>
            </tr>
          </thead>
          <tbody>
            {data.whoHasNotPaidTuition.map((t, idx) => <Record info={t} n={idx} history={props.history} />)}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Record = ({ info, n, history }) => {
  return (
    <tr onClick={() => {
      localStorage.setItem("id", info.id)
      localStorage.setItem("studentId", info.studentId)
      localStorage.setItem("studentName", info.studentName)
      localStorage.setItem("amount", info.amount)
      history.push(`/recordPay/${info.id}`)
    }}>
      <th scope="row">{n}</th>
      <td>{info.studentName}</td>
      <td>{info.dueDate} </td>
      <td>{info.amount}</td>
    </tr >
  )
}

export default Unpaid