import React, { Component } from "react";
import Books from './showBooks'

export default class TBody extends Component {
  render() {
    return this.props.students.map((s, idx) => {
      const studentBook = this.props.studentBooks.filter(function (element) {
        return element.studentId === s._id;
      });
      const a = studentBook[0];
      return (
        <tr key={idx}>
          <th style={{ minWidth: 70 }}><div>{a.name.substr(0, 1)}</div><div>{a.name.substr(1, 1)}</div><div>{a.name.substr(2, 1)}</div></th>
          <Books
            key={this.props.key + "a" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["주교재"]}
          />
          <Books
            key={this.props.key + "b" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["동요집", "소곡집", "소나티네"]}
          />
          <Books
            key={this.props.key + "c" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["하농"]}
          />
          <Books
            key={this.props.key + "d" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["반주완성"]}
          />
          <Books
            key={this.props.key + "e" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["명곡집", "K pop", "k-pop"]}
          />
          <Books
            key={this.props.key + "f" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["계이름공부"]}
          />
          <Books
            key={this.props.key + "f" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["음악이론"]}
          />
          <Books
            key={this.props.key + "g" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["초코렛문제은행"]}
          />
          <Books
            key={this.props.key + "h" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["평가문제집"]}
          />
          <Books
            key={this.props.key + "i" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["마무리음악이론", "악전교실", "아이좋아", "음악유치원", "음악놀이"]}
          />
          <Books
            key={this.props.key + "j" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
            bookFilter={["음악노트", "음악종합장"]}
          />
        </tr>
      );
    });
  }
}