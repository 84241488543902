import React, { Component } from "react";
import { toast } from "react-toastify";
import Loading from '../common/loading'
import StudentAllCsTable from "./studentAllCsTable2";
import Pagination from "../common/pagination";
import {
  getStudentsIot as getStudents,
  deleteStudent
} from "../../services/studentService";
//todo: route/students.js 에서 날짜 세팅하고 사용해야함. 자동으로 되게 고칠것
//todo: 차타는 학생들을 실제시간과 차시간으로 선택해서 보여주는 체크박스 만들것
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import authService from "../../services/authService";
import { fieldSorter } from "../common/func1";
import fileName from "../common/func2";
import { btnStyle1 } from "../common/setting";
// 20200701 import "./iot.css";
//import "../../my.css";

class Iot extends Component {
  state = {
    students: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };
  user = authService.getCurrentUser();
  async componentDidMount() {
    // const { data: students } = await getStudents();
    const { data } = await getStudents();
    this.setState({ students: data.students, lessons: data.lessons });
  }

  handleDelete = async student => {
    const originalStudents = this.state.students;
    const students = originalStudents.filter(m => m._id !== student._id);
    this.setState({ students });

    try {
      await deleteStudent(student._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This student has already been deleted.");
    }
  };

  handleLike = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allStudents
    } = this.state;

    let filtered = allStudents;
    if (searchQuery)
      filtered = allStudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
        // m.이름 !== null &&
        // ((searchQuery.length === 1 &&
        //   m.이름.substring(0, 1) === searchQuery) ||
        //   (searchQuery.length === 2 &&
        //     m.이름.substring(0, 2) === searchQuery) ||
        //   (searchQuery.length === 3 &&
        //     m.이름.substring(0, 3) === searchQuery))
      );
    // else if (selectedGenre && selectedGenre._id)
    //   filtered = allMovies.filter(m => m.genre._id === selectedGenre._id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const students = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: students };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    let { totalCount, data: students } = this.getPagedData();
    students = students.filter(s => {
      let h = (s.hag === "" || s.hag === undefined || s.hag === null) ? 0 : s.hag;
      return parseInt(h) < 10;
    });

    students.map(s => {
      if (s.age === 5 || s.age === 6 || s.age === 7) s.hag = s.age
      return null;
    })
    let studentsTemp = students.filter(s => { return s.age > 0 })
    studentsTemp = studentsTemp.sort(fieldSorter(["hag", "name"]));
    let studentsTemp1 = students.filter(s => { return s.age === null || s.age === '' || s.age === undefined })
    studentsTemp1 = studentsTemp1.sort(fieldSorter(["hag", "name"]));
    students = []
    studentsTemp.map(s => students.push(s))
    studentsTemp1.map(s => students.push(s))

    // students = students.sort(fieldSorter(["hag", "name"]));

    if (students.length === 0) {
      return <Loading></Loading>
    }
    else
      return (
        <>
          <div className='noPrint'>
            {fileName("iot.jsx", this.user)}

            {user && (
              <>
                {/* {/* //todo: Change to <Ibtn/> */}
                <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
                  this.props.history.push("/Chuls5");
                }} style={btnStyle1}
                ></i>
              </>
            )}
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <h6 style={{ color: "red" }}>route/students.js 에서 get/iot 날짜 먼저 맞추고 출력해야합니다</h6>
          </div>
          <StudentAllCsTable
            user={this.user}
            students={students}
            lessons={this.state.lessons}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </>
      );
  }
}

export default Iot;
