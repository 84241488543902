import http from "./httpService";

const apiEndpoint = "/cars";

function studentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getStudents() {
  return http.get(apiEndpoint);
}

export function getStudent(studentId) {
  return http.get(studentUrl(studentId));
}

export function saveStudent(student) {
  if (student._id) {
    const body = { ...student };
    delete body._id;
    return http.put(studentUrl(student._id), body);
  }

  return http.post(apiEndpoint, student);
}

export function deleteStudent(studentId) {
  return http.delete(studentUrl(studentId));
}
