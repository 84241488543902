import React from "react";
import { Formik, Form, Field, useField } from 'formik';
import * as Yup from 'yup';
import { TextField, Checkbox, Button } from "@material-ui/core";
import _ from 'lodash';
import fileName from "../common/func2";
import auth from "../../services/authService";
import { getStudent, saveStudent } from "../../services/studentService";

const MyTextField = ({ label, disabled, placeholder, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField disabled={disabled} placeholder={placeholder} label={label} {...field} helperText={errorText} error={!!errorText} />
  )
}
const validationSchema = Yup.object().shape({
  data: Yup.object().shape({
    it1: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it2: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it3: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it4: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it5: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot1: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot2: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot3: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot4: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot5: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it1t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it2t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it3t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it4t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    it5t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot1t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot2t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot3t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot4t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
    ot5t: Yup.string().matches(/^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { excludeEmptyString: true }).nullable(),
  })
});

class BusOnOff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        age: "",
        hag: "",
        ban: "",
        busDetail: "",
        eduDetail: "",
        offDetail: "",
        i1ignore: false,
        i2ignore: false,
        i3ignore: false,
        i4ignore: false,
        i5ignore: false,
        o1ignore: false,
        o2ignore: false,
        o3ignore: false,
        o4ignore: false,
        o5ignore: false,
        it1: "",
        ip1: "",
        ot1: "",
        op1: "",
        it2: "",
        ip2: "",
        ot2: "",
        op2: "",
        it3: "",
        ip3: "",
        ot3: "",
        op3: "",
        it4: "",
        ip4: "",
        ot4: "",
        op4: "",
        it5: "",
        ip5: "",
        ot5: "",
        op5: "",
        it1t: "",
        ip1t: "",
        ot1t: "",
        op1t: "",
        it2t: "",
        ip2t: "",
        ot2t: "",
        op2t: "",
        it3t: "",
        ip3t: "",
        ot3t: "",
        op3t: "",
        it4t: "",
        ip4t: "",
        ot4t: "",
        op4t: "",
        it5t: "",
        ip5t: "",
        ot5t: "",
        op5t: "",
        todayInRide: "",
        todayOutRide: ""
      },
      errors: {}
    };
  }

  async populateStudent() {
    try {
      const studentId = this.props.match.params.id;
      if (studentId === "new") return;
      let { data } = await getStudent(studentId);
      data = _.pick(data, [
        '_id',
        'name',
        'age',
        'ban',
        'busDetail',
        'eduDetail',
        'offDetail',
        'hag',
        'i1ignore',
        'i2ignore',
        'i3ignore',
        'i4ignore',
        'i5ignore',
        'o1ignore',
        'o2ignore',
        'o3ignore',
        'o4ignore',
        'o5ignore',
        'ip1',
        'ip2',
        'ip3',
        'ip4',
        'ip5',
        'it1',
        'it2',
        'it3',
        'it4',
        'it5',
        'op1',
        'op2',
        'op3',
        'op4',
        'op5',
        'ot1',
        'ot2',
        'ot3',
        'ot4',
        'ot5',
        'ip1t',
        'ip2t',
        'ip3t',
        'ip4t',
        'ip5t',
        'it1t',
        'it2t',
        'it3t',
        'it4t',
        'it5t',
        'op1t',
        'op2t',
        'op3t',
        'op4t',
        'op5t',
        'ot1t',
        'ot2t',
        'ot3t',
        'ot4t',
        'ot5t'
      ]);
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateStudent();
    let params = {
      name: "",
      room: ""
    };
    params = { room: "레슨", name: this.user.name };
    // this.props.socket.emit("join", params, function (err) {
    // });
  }
  async componentUnMount() {

    // this.props.socket.emit("leave", params, function (err) {
    // });
  }

  user = auth.getCurrentUser();

  render() {
    return (
      <>
        <div>
          {fileName("busOnOff.jsx", this.user)}
        </div>
        <h3>
          <span className="col-12 badge badge-warning">
            요일별 등하원 시간/장소
            </span>
        </h3>
        <Formik
          enableReinitialize
          validateOnChange={true}
          initialValues={this.state}

          validationSchema={validationSchema}

          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // same shape as initial values
            setSubmitting(true);
            // console.log("submit:", values);
            // await saveBook1(values);
            await saveStudent(values.data);
            this.props.socket.emit("downAll", {}, function (err) { });
            this.props.socket.emit("board", {}, function (err) { });
            this.props.history.goBack();
            setSubmitting(false);
            resetForm();
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            < Form >
              <div>
                <MyTextField disabled={true} name="data.name" placeholder="이름" label="이름" />&nbsp;
                <MyTextField name="data.age" placeholder="연령" label="연령" />&nbsp;
                <MyTextField name="data.hag" placeholder="학년" label="학년" />&nbsp;
                <MyTextField name="data.ban" placeholder="반" label="반" /><br />
                <MyTextField name="data.eduDetail" placeholder="교육관련사항" label="교육관련사항" />
                <br />
                <MyTextField name="data.offDetail" placeholder="결석관련사항" label="결석관련사항" />
                <br />
                <MyTextField name="data.busDetail" placeholder="차 관련사항" label="차 관련사항" />
                <br />
                <div>
                  <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저장</Button>
                </div>
                <div>선생님 걸어서 픽업/동행시에는 장소 앞에 '*' 붙일것</div>
                <div>선생님이 차로 픽업할때는 장소 앞에 '+' 붙일것</div>
                <div className="mr-2 badge badge-info">월</div>
                <div className="mr-2 badge badge-warning">등원</div>
                무시<Field name="data.i1ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.it1" placeholder="승차시간" label="승차시간" />
                &nbsp;
                <MyTextField name="data.ip1" placeholder="승차장소" label="승차장소" />
                &nbsp;
                <MyTextField name="data.it1t" placeholder="오늘만 승차시간" label="오늘만 승차시간" />
                &nbsp;
                <MyTextField name="data.ip1t" placeholder="오늘만 승차장소" label="오늘만 승차장소" />
                <br />
                &nbsp;
                <div className="ml-4 mr-2 badge badge-warning">하원</div>
                무시<Field name="data.o1ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.ot1" placeholder="하차시간" label="하차시간" />
                &nbsp;
                <MyTextField name="data.op1" placeholder="하차장소" label="하차장소" />
                &nbsp;
                <MyTextField name="data.ot1t" placeholder="오늘만 하차시간" label="오늘만 하차시간" />
                &nbsp;
                <MyTextField name="data.op1t" placeholder="오늘만 하차장소" label="오늘만 하차장소" />
                <br />
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
                <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <div>
                  <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저장</Button>
                </div><br />
                <div className="mr-2 badge badge-info">화</div>
                <div className="mr-2 badge badge-warning">등원</div>
                무시<Field name="data.i2ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.it2" placeholder="승차시간" label="승차시간" />
                &nbsp;
                <MyTextField name="data.ip2" placeholder="승차장소" label="승차장소" />
                &nbsp;
                <MyTextField name="data.it2t" placeholder="오늘만 승차시간" label="오늘만 승차시간" />
                &nbsp;
                <MyTextField name="data.ip2t" placeholder="오늘만 승차장소" label="오늘만 승차장소" />
                <br />
                &nbsp;
                <div className="ml-4 mr-2 badge badge-warning">하원</div>
                무시<Field name="data.o2ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.ot2" placeholder="하차시간" label="하차시간" />
                &nbsp;
                <MyTextField name="data.op2" placeholder="하차장소" label="하차장소" />
                &nbsp;
                <MyTextField name="data.ot2t" placeholder="오늘만 하차시간" label="오늘만 하차시간" />
                &nbsp;
                <MyTextField name="data.op2t" placeholder="오늘만 하차장소" label="오늘만 하차장소" />
                <br />
                <div>
                  <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저장</Button>
                </div><br />
                <div className="mr-2 badge badge-info">수</div>
                <div className="mr-2 badge badge-warning">등원</div>
                무시<Field name="data.i3ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.it3" placeholder="승차시간" label="승차시간" />
                &nbsp;
                <MyTextField name="data.ip3" placeholder="승차장소" label="승차장소" />
                &nbsp;
                <MyTextField name="data.it3t" placeholder="오늘만 승차시간" label="오늘만 승차시간" />
                &nbsp;
                <MyTextField name="data.ip3t" placeholder="오늘만 승차장소" label="오늘만 승차장소" />
                <br />
                &nbsp;
                <div className="ml-4 mr-2 badge badge-warning">하원</div>
                무시<Field name="data.o3ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.ot3" placeholder="하차시간" label="하차시간" />
                &nbsp;
                <MyTextField name="data.op3" placeholder="하차장소" label="하차장소" />
                &nbsp;
                <MyTextField name="data.ot3t" placeholder="오늘만 하차시간" label="오늘만 하차시간" />
                &nbsp;
                <MyTextField name="data.op3t" placeholder="오늘만 하차장소" label="오늘만 하차장소" />
                <br />
                <div>
                  <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저장</Button>
                </div><br />
                <div className="mr-2 badge badge-info">목</div>
                <div className="mr-2 badge badge-warning">등원</div>
                무시<Field name="data.i4ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.it4" placeholder="승차시간" label="승차시간" />
                &nbsp;
                <MyTextField name="data.ip4" placeholder="승차장소" label="승차장소" />
                &nbsp;
                <MyTextField name="data.it4t" placeholder="오늘만 승차시간" label="오늘만 승차시간" />
                &nbsp;
                <MyTextField name="data.ip4t" placeholder="오늘만 승차장소" label="오늘만 승차장소" />
                <br />
                &nbsp;
                <div className="ml-4 mr-2 badge badge-warning">하원</div>
                무시<Field name="data.o4ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.ot4" placeholder="하차시간" label="하차시간" />
                &nbsp;
                <MyTextField name="data.op4" placeholder="하차장소" label="하차장소" />
                &nbsp;
                <MyTextField name="data.ot4t" placeholder="오늘만 하차시간" label="오늘만 하차시간" />
                &nbsp;
                <MyTextField name="data.op4t" placeholder="오늘만 하차장소" label="오늘만 하차장소" />
                <br />
                <div>
                  <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저장</Button>
                </div>
                <br />
                <div className="mr-2 badge badge-info">금</div>
                <div className="mr-2 badge badge-warning">등원</div>
                무시<Field name="data.i5ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.it5" placeholder="승차시간" label="승차시간" />
                &nbsp;
                <MyTextField name="data.ip5" placeholder="승차장소" label="승차장소" />
                &nbsp;
                <MyTextField name="data.it5t" placeholder="오늘만 승차시간" label="오늘만 승차시간" />
                &nbsp;
                <MyTextField name="data.ip5t" placeholder="오늘만 승차장소" label="오늘만 승차장소" />
                <br />
                &nbsp;
                <div className="ml-4 mr-2 badge badge-warning">하원</div>
                무시<Field name="data.o5ignore" type="checkbox" as={Checkbox} />
                <MyTextField name="data.ot5" placeholder="하차시간" label="하차시간" />
                &nbsp;
                <MyTextField name="data.op5" placeholder="하차장소" label="하차장소" />
                &nbsp;
                <MyTextField name="data.ot5t" placeholder="오늘만 하차시간" label="오늘만 하차시간" />
                &nbsp;
                <MyTextField name="data.op5t" placeholder="오늘만 하차장소" label="오늘만 하차장소" />
              </div>

              <div>
                <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저장</Button>
              </div>

            </Form>
          )}
        </Formik>
      </>
    );

  }
}


export default BusOnOff;
