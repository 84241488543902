import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Loading from "../../common/loading";
import fileName from "../../common/func2";
import { btnStyle1 } from "../../common/setting";
import { withRouter } from "react-router-dom";
import DocusTable from "./docusTable";
import { MusicLib } from "../music/selectMusic";
import {
  getStudentConcert,
  saveStudent,
  saveDocusAsExcel,
  readExcelAsDocus
} from "../../../services/studentService";
import BanSelector from "../../common/banSelector";
import Pagination from "../../common/pagination";
import { paginate } from "../../../utils/paginate";
import _ from "lodash";
import authService from "../../../services/authService";
// import "./docu.css";
// 20200701 import "../../../my.css";
class Docu extends Component {
  state = {
    students: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: {},
    ban: "",
    fontSize: 20,
    win1open: false,
    sortColumnMusic: { path: "melodizer", order: "asc" },
    selectedMusic: null,
    studentId: null,
    cb: null,
    small: false
  };
  user = authService.getCurrentUser();
  async componentDidMount() {
    const { data: student1 } = await getStudentConcert();
    let students = student1;
    students = students.filter(s => s.hag < 15);
    students = students.map(s => {
      if (s.age !== null) {
        return { ...s, hag1: s.schoolShort + s.age };
      } else {
        return { ...s, hag1: s.schoolShort + s.hag };
      }
    });
    this.setState({ students });
  }
  onOpenModal = () => { };
  onCloseModal = win => {
    if (win === "win1") this.setState({ win1open: false });
  };
  handleWinOpen = (item, cb) => {
    this.setState({ win1open: true, studentId: item._id, cb });
  };
  handleSelectBan = ban => {
    this.setState({ ban });
  };
  handleChange = e => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value
    });
  };
  handleChanged = (id, path, value) => {
    const students = [...this.state.students];
    const ids = students.map(b => b._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    students[index][path] = value;
    if (path === "melodizer" && this.state.selectedMusic !== undefined)
      this.setState({
        selectedMusic: null,
        studentId: null,
        students,
        lastEdit: id
      });
    else this.setState({ students, lastEdit: id });
  };
  toDB = m => {
    const t = "[object Object]"; //don't know what. have just deleted
    delete m[t];
    delete m.edit;
    const hag1 = m.hag1;
    delete m.hag1;
    saveStudent(m);
    m.hag1 = hag1;
  };
  handleEditMode = (item, path, value) => {
    let students = [...this.state.students];
    const index = _.findIndex(this.state.students, item);
    students.map(m => {
      if (m._id === this.state.lastEdit) {
        this.toDB(m);
      }
      if (m.edit) {
        this.toDB(students[index]);
        delete m.edit;
      }
      return null;
    });
    students[index].edit = true;
    this.setState({ students });
  };
  handleChanged1 = (id, value) => {
    const students = [...this.state.students];
    const ids = students.map(b => b._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    students[index]["musicBook"] = value.musicBook;
    students[index]["musicTitle"] = value.musicTitle;
    students[index]["melodizer"] = value.melodizer;
    this.toDB(students[index]);
    if (this.state.selectedMusic !== undefined)
      this.setState({ selectedMusic: null, studentId: null, students });
  };
  handleLike = book => {
    const students = [...this.state.students];
    const index = students.indexOf(book);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allstudents
    } = this.state;
    let filtered = allstudents;
    if (searchQuery)
      filtered = allstudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const students = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: students };
  };
  handleMusicSelect = music => {
    this.setState({ selectedMusic: music, win1open: false });
  };

  saveExcel = async () => {
    await saveDocusAsExcel();
  };
  readExcel = async () => {
    await readExcelAsDocus();
  };
  render() {
    const {
      pageSize,
      currentPage,
      sortColumn,
      sortColumnMusic,
    } = this.state;
    const { totalCount, data: students } = this.getPagedData();
    if (students.length === 0) {
      return <Loading></Loading>;
    } else
      return (
        <>
          <Modal
            open={this.state.win1open}
            onClose={() => this.onCloseModal("win1")}
            center
            closeOnEsc
            closeOnOverlayClick
            styles={{
              modal: {
                position: "absolute",
                marginTop: 50
              },
              closeIcon: {
                position: "fixed",
                top: 110,
                left: 80
              }
            }}
          >
            <MusicLib
              user={this.props.user}
              sortColumn={sortColumnMusic}
              onMusicSelect={this.handleMusicSelect}
            ></MusicLib>
          </Modal>
          {this.props.user && this.props.user.isAdmin && (
            <div className="noPrint">
              {fileName("concert/concert.jsx", this.user)}
            </div>
          )}
          {this.user && this.user.isAdmin && (
            <>
              <div className="noPrint">
                <button
                  className="btn btn-primary mr-2"
                  onClick={() => this.saveExcel()}
                >
                  엑셀저장
                </button>
                <button
                  className="btn btn-primary mr-2"
                  onClick={() => this.readExcel()}
                >
                  엑셀읽기
                </button>
                <input
                  name="fontSize"
                  placeholder="input font-size"
                  value={this.state.fontSize}
                  onChange={this.handleChange}
                  style={{ width: 40, textAlign: "center" }}
                />
              </div>
            </>
          )}
          <div className="noPrint">
            <i
              class="fas fa-chalkboard-teacher fa-lg"
              onClick={() => {
                this.props.history.push("/Chuls5");
              }}
              style={btnStyle1}
            ></i>
            {!this.state.small && (
              <i
                className="fas fa-praying-hands"
                onClick={() => {
                  this.setState({ small: !this.state.small });
                }}
                style={btnStyle1}
              ></i>
            )}
            {this.state.small && (
              <i
                class="fas fa-hands"
                onClick={() => {
                  this.setState({ small: !this.state.small });
                }}
                style={btnStyle1}
              ></i>
            )}

            <span className="bg-success" style={{ marginLeft: 15 }}>
              <BanSelector
                ban={this.state.ban}
                onSelectBan={this.handleSelectBan}
              />
              <br />
            </span>
          </div>
          <div className="noPrint mt-3">
            <h6>
              {`* 작곡자/곡명을 입력해주세요.^^ 눌러서 칸이 나타나면 입력하고 다른
            학생 줄을 누르면 자동으로 저장됩니다. 다른 학생줄을 누르지 않으면
            입력내용이 저장되지 않습니다.`}
            </h6>
            <p>{<i class="fas fa-search" style={{ color: 'magenta' }}></i>}:곡목 선택창&nbsp;&nbsp;&nbsp; {<i class="far fa-hand-point-right" style={{ color: 'magenta' }}></i>}:곡 선택&nbsp;&nbsp;&nbsp; <span style={{ color: 'magenta' }}>X</span>:닫기</p>
          </div>
          <section className="page" style={{ width: "320mm" }}>
            <div id="container1">
              <h3 id="subject"> 동 영 상 연 주 곡</h3>
              <h3 id="date">( 2021년 3월 )</h3>
            </div>
            <DocusTable
              small={this.state.small}
              user={this.user}
              students={students.filter(m =>
                this.state.ban === "" ? m : m.pianoBan === this.state.ban + "반"
              )}
              sortColumn={sortColumn}
              fontSize={this.state.fontSize}
              onLike={this.handleLike}
              onSave={this.handleSave}
              onSort={this.handleSort}
              onEditMode={(item, path, value) =>
                this.handleEditMode(item, path, value)
              }
              onChanged1={(id, value) => this.handleChanged1(id, value)}
              onChanged={(id, path, value) =>
                this.handleChanged(id, path, value)
              }
              onWinOpen={this.handleWinOpen}
              selectedMusic={{
                studentId: this.state.studentId,
                music: this.state.selectedMusic,
                clear: () => this.setState({ studentId: "", music: "" })
              }}
            />
          </section>
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </>
      );
  }
}
export default withRouter(Docu);
