import React, { Component } from "react";
import Students from "../common/students1";

class NameButtons extends Component {
  render() {
    let students = new Students(this.props.students);
    return <div>{students.showAllStudents(this.props.car)}</div>;
  }
}

export default NameButtons;
