import React, { Component } from "react";
import fileName from "../common/func2";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import FeesTable from "./feesTable1";
import Pagination from "../common/pagination";
import { getStudents, deleteStudent } from "../../services/feeService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import authService from "../../services/authService";

class Pays extends Component {
  state = {
    students: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };
  user = authService.getCurrentUser();

  async componentDidMount() {
    const { data: students } = await getStudents();
    this.setState({ students });
  }

  handleDelete = async student => {
    const originalStudents = this.state.students;
    const students = originalStudents.filter(m => m._id !== student._id);
    this.setState({ students });

    try {
      await deleteStudent(student._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This student has already been deleted.");

      this.setState({ students: originalStudents });
    }
  };

  handleLike = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allStudents
    } = this.state;

    let filtered = allStudents;
    if (searchQuery)
      filtered = allStudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
        // m.이름 !== null &&
        // ((searchQuery.length === 1 &&
        //   m.이름.substring(0, 1) === searchQuery) ||
        //   (searchQuery.length === 2 &&
        //     m.이름.substring(0, 2) === searchQuery) ||
        //   (searchQuery.length === 3 &&
        //     m.이름.substring(0, 3) === searchQuery))
      );
    // else if (selectedGenre && selectedGenre._id)
    //   filtered = allMovies.filter(m => m.genre._id === selectedGenre._id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const students = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: students };
  };
  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: students } = this.getPagedData();
    return (
      <>
        {fileName("pay/fees1.jsx", this.user)}
        <>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <FeesTable
            user={this.user}
            students={students}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </>
      </>
    );
  }
}

export default withRouter(Pays);
