import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import "react-toastify/dist/ReactToastify.css";
//+++++++++++++++++++++++++++++++++++++++
import Home from "./home";
import { socket } from "./services/socket";
import { auth, getVersion } from "./services/index";
import { Chuls5, Chuls6, Etc } from "./components/chuls/index";
import { Kids, Kids1, Rides, RidesWeek, RidesWeek1, BusOnOff, StudentForm1, Bus } from "./components/bus/index";
import { Test, SavePic, ProtectedRoute } from "./components/common/index";
import { BookChart, ChulBu, Dev, JindoChart, Lessons, Times2, WeekCB } from "./components/lesson/index";
import { StudentForm, StudentAllCs, StudentsPhone, HbLetters, HbLetter, Iot, Ontime, TeacherDid } from "./components/students/index";
import { Docu, Docu2, Docu3 } from "./components/concert/index";
import { LoginForm, Logout, NavBar, NotFound, RegisterForm } from "./components/index";
import { MyDropzone, Books, Fees, Fees1, Pays, RecordBook } from "./components/pay/index";
import { Evals, EvalForm, EvalJindo, EvalJindo1, EvalOnlyForm } from "./components/eval/index";
import { StudTimes } from "./components/times/index";
import { CreateTuition, RecordPay, TuitionMsg, Unpaid } from "./components/tuition/index";
import SendText from './components/tuition/sendText'
//+++++++++++++++++++++++++++++++++++++++
import "./App.css";

const user = auth.getCurrentUser()
const UserContext = React.createContext(user);
class App extends Component {
  constructor() {
    super()
    this.state = { user: auth.getCurrentUser() };
  }
  static contextType = UserContext;
  onUserChange = () => { this.setState({ user: auth.getCurrentUser() }) }
  async componentDidMount() {
    const { data } = await getVersion();
    this.setState({ version: data.version, stopUse: data.stopUse });
  }

  render() {
    const { user, version, stopUse } = this.state;
    const current_version = 2
    if (stopUse === true) {
      return <div className="alert alert-primary" role="alert">
        업그레이드 필요:[브라우저]의 [세팅]의 [방문기록]에서 그랜드음악학원(gma.forlang.kr) 방문기록을 삭제하세요.
    </div>
    }
    return (
      <UserContext.Provider value={auth.getCurrentUser()}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
            <ToastContainer />
            <NavBar user1={user} UserContext={UserContext} onUserChange={this.onUserChange} version={current_version} />
            {(version > current_version) && <div className="alert alert-primary" role="alert">
              업그레이드 필요:[브라우저]의 [세팅]의 [방문기록]에서 그랜드음악학원(gma.forlang.kr) 방문기록을 삭제하세요.
  </div>}
            <main className="container">
              <Switch>
                <Route path="/register" component={RegisterForm} />
                <Route path="/login" component={LoginForm} />
                <Route path="/logout" component={Logout} />
                <ProtectedRoute path="/students/:id" component={StudentForm} />
                <ProtectedRoute
                  path="/evals/:id"
                  render={props => <EvalForm {...props} user={user} socket={socket} />}
                />
                <ProtectedRoute
                  path="/eval/:id"
                  render={props => <EvalJindo {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/evalnew/:id"
                  render={props => <EvalJindo1 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/evalsOnly/:id"
                  render={props => <EvalOnlyForm {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/etc"
                  render={props => <Etc {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/testbus1"
                  render={props => <Kids1 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/ridesTest"
                  render={props => <Test {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/savePic"
                  render={props => <SavePic {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/bus1"
                  render={props => <Kids {...props} user={user} />}
                />
                <ProtectedRoute path="/bus/:id" component={StudentForm1} />
                <ProtectedRoute
                  path="/busOnOff/:id"
                  render={props => <BusOnOff {...props} user={user} socket={socket} />}
                />
                <ProtectedRoute path="/times/:id" component={StudTimes} />
                <ProtectedRoute
                  path="/chuls5"
                  render={props => <Chuls5 {...props} user={user} socket={socket} />}
                />
                <ProtectedRoute
                  path="/chuls6"
                  render={props => <Chuls6 {...props} user={user} socket={socket} />}
                />
                <ProtectedRoute
                  path="/weekCB"
                  render={props => <WeekCB {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/chulBu"
                  render={props => <ChulBu {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/times2"
                  render={props => <Times2 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/lessons"
                  render={props => <Lessons {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/jindoChart"
                  render={props => <JindoChart {...props} user={user} />}
                />

                <ProtectedRoute
                  path="/bookChart"
                  render={props => <BookChart {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/dev"
                  render={props => <Dev {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/ct"
                  render={props => <CreateTuition {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/rides"
                  render={props => <Rides {...props} user={user} socket={socket} />}
                />

                <ProtectedRoute
                  path="/ridesWeek"
                  render={props => <RidesWeek {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/st"
                  render={props => <SendText {...props} user={user}  socket={socket}/>}
                />
                <ProtectedRoute
                  path="/dropzone"
                  render={props => <MyDropzone {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/ridesWeek1"
                  render={props => <RidesWeek1 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/evals"
                  render={props => <Evals {...props} user={user} />}
                />

                <ProtectedRoute
                  path="/pays"
                  render={props => <Pays {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/fees"
                  render={props => <Fees {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/fees1"
                  render={props => <Fees1 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/books"
                  render={props => <Books {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/recordBook"
                  render={props => <RecordBook {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/recordPay/:id"
                  render={props => <RecordPay {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/nyp"
                  render={props => <Unpaid {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/tm"
                  render={props => <TuitionMsg {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/docu"
                  render={props => <Docu {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/concert"
                  render={props => <Docu2 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/concert1"
                  render={props => <Docu3 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/bus"
                  render={props => <Bus {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/studentAllCs"
                  render={props => <StudentAllCs {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/studentsPhone"
                  render={props => <StudentsPhone {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/iotime"
                  render={props => <Iot {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/ontime"
                  render={props => <Ontime {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/td"
                  render={props => <TeacherDid {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/hbLetter"
                  render={props => <HbLetter {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/hbLetters"
                  render={props => <HbLetters {...props} user={user} />}
                />
                <Route path="/not-found" component={NotFound} />
                <Route path="/" component={Home} />
                <Redirect from="/" exact to="/" />
                <Redirect to="/not-found" />
              </Switch>
            </main>
          </>
        </MuiPickersUtilsProvider>
      </UserContext.Provider>
    );
  }
}

export default App;
