export const getPlace = ({ student, day, onoff, today }) => {
  return getTimeOrPlace({ student, day, onoff, today, which: "p" })
};
export const getTime = ({ student, day, onoff, today }) => {
  return getTimeOrPlace({ student, day, onoff, today, which: "t" })
};
const getTimeOrPlace = ({ student, day, onoff, today, which }) => {
  let field = onoff === "on" ? "i" : "o"
  field = field + which
  field = field + day
  field = today ? field + "t" : field
  return student[field]
};
