import React from "react";
import { HandlerConsumer } from '../new/handler'

const TodayInRideB = props => {
  if (!props.student.edit) {
    if (props.onoff === 'on') {
      return (
        <HandlerConsumer>
          {handler =>
            <button
              className="btn btn-info ml-1 mr-1"
              key={`X`}
              onClick={() =>
                handler.onTodayInRideButton(
                  props.student,
                  props.onoff,
                  props.time,
                  props.day
                )
              }
            >
              {props.student.todayInRide === undefined ? '등원안탐' : props.student.todayInRide === true ? '등원안탐' : '등원탐'}
            </button>}
        </HandlerConsumer>
      );
    }
  }
  return null;
};

export default TodayInRideB;
