import React, { Component } from "react";
import { toast } from "react-toastify";
import StudentAllCsTable from "./hbLettersTable";
import Pagination from "../common/pagination";
import {
  getStudentsBig as getStudents,
  deleteStudent,
  saveExcelForLetter
} from "../../services/studentService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import authService from "../../services/authService";

class StudentAllCs extends Component {
  state = {
    students: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };
  user = authService.getCurrentUser();
  async componentDidMount() {
    const { data: students } = await getStudents();
    this.setState({ students });
  }
  saveExcel = async () => {
    await saveExcelForLetter();
  };
  handleDelete = async student => {
    const originalStudents = this.state.students;
    const students = originalStudents.filter(m => m._id !== student._id);
    this.setState({ students });

    try {
      await deleteStudent(student._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This student has already been deleted.");
    }
  };

  handleLike = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allStudents
    } = this.state;

    let filtered = allStudents;
    if (searchQuery)
      filtered = allStudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const students = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: students };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: students1 } = this.getPagedData();
    let students = students1.filter(s => s.hbLetter === true);
    return (
      <>
        <div>
          {this.props.user && this.props.user.isAdmin && (
            <>
              <div>
                <span className="badge badge-info">hbLetters.jsx</span>
              </div>
              <button
                className="btn btn-primary m-2"
                style={{ marginBottom: 20 }}
                onClick={() => this.saveExcel()}
              >
                엑셀저장
              </button>
            </>
          )}
          <>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <StudentAllCsTable
              user={this.user}
              students={students}
              sortColumn={sortColumn}
              onLike={this.handleLike}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
            />
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </>
        </div>
      </>
    );
  }
}

export default StudentAllCs;
