import React from "react";
import Filename from './filename';

// 레슨 화면과 탑승정보 화면에서 동시에 사용중.
const Day = ({ scope, onSetDay }) => {
  const a = scope
  const day = a.state.day ? a.state.day : a.state.dow;
  const { user } = scope;
  if (user && user.isPrincipal) {
    return (
      <>
        {Filename('common/day.jsx')}
        <span>
          {[1, 2, 3, 4, 5].map(n => <Button day={day} n={n} key={Math.floor(Math.random() * 100000)} onSetDay={onSetDay} scope={a} />)}
        </span>
      </>
    );
  }
  else return null
}

const handleClick = (e) => (onSetDay, scope) => {
  onSetDay(e, scope);
};

const Button = ({ n, day, onSetDay, scope }) => {
  const a = ["월", "화", "수", "목", "금"]
  let badge = "badge badge-pill m-1 badge-";

  return (
    < button
      type="button"
      className={day === parseInt(n) ? badge + "warning" : badge + "light"}
      style={day === parseInt(n) ? { backgroundColor: "#f7dc6f" } : {}}
      onClick={(e) => handleClick(e)(onSetDay, scope)}
      value={n}
    >
      {a[n - 1]}
    </button >
  )
}
export default Day;