import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody1";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  className,
  onChanged,
  onClickItem,
  prevMonth,
  curMonth
}) => {
  return (
    <table className={className}>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody
        prevMonth={prevMonth}
        curMonth={curMonth}
        columns={columns}
        data={data}
        onClickItem={(_id, path) => onClickItem(_id, path)}
        onChanged={(id, path, value) => onChanged(id, path, value)}
      />
    </table>
  );
};

export default Table;
