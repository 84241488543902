// export const color = [
//   0xffffff,
//   0xffe6ff,
//   0xffccff,
//   0xffb3ff,
//   0xff99ff,
//   0xff80ff,
//   0xff66ff,
//   0xff4dff,
//   0xff33ff,
//   0xff1aff,
//   0xff00ff,
//   0xe600e6,
//   0xcc00cc,
//   0xb300b3,
//   0x990099
// ];
export const color = [
  "#ffffff",
  "#ffe6ff",
  "#ffccff",
  "#ffb3ff",
  "#ff99ff",
  "#ff80ff",
  "#ff66ff",
  "#ff4dff",
  "#ff33ff",
  "#ff1aff",
  "#ff00ff",
  "#e600e6",
  "#cc00cc",
  "#b300b3",
  "#990099"
];
export function hexToRgb(hex, type = 0) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? type === 0
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
      : "rgb(" +
      parseInt(result[1], 16) +
      "," +
      parseInt(result[2], 16) +
      "," +
      parseInt(result[3], 16) +
      ")"
    : null;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export const dates = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const bookColor = [
  { book: "음악이론1권", color: "lightyellow" },
  { book: "음악이론2권", color: "khaki" },
  { book: "음악이론3권", color: "greenyellow" },
  { book: "음악이론4권", color: "lavender" },
  { book: "음악이론5권", color: "moccasin" },
  { book: "음악이론6권", color: "powderblue" },
  { book: "음악이론7권", color: "lightcyan" },
  { book: "음악이론8권", color: "sandybrown" },
  { book: "음악이론9권", color: "wheat" },
  { book: "음악이론10권", color: "mintcream" },
  { book: "음악이론11권", color: "tomato" },
  { book: "음악이론12권", color: "thistle" },
  { book: "계이름공부1권", color: "lightyellow" },
  { book: "계이름공부2권", color: "khaki" },
  { book: "계이름공부3권", color: "greenyellow" },
  { book: "계이름공부4권", color: "lavender" },
  { book: "계이름공부5권", color: "moccasin" },
  { book: "계이름공부6권", color: "powderblue" },
  { book: "계이름공부7권", color: "lightcyan" },
  { book: "계이름공부8권", color: "sandybrown" },
  { book: "계이름공부9권", color: "wheat" },
  { book: "계이름공부10권", color: "mintcream" },
  { book: "계이름공부11권", color: "tomato" },
  { book: "계이름공부12권", color: "thistle" },
  { book: "초코렛문제은행1권", color: "lightyellow" },
  { book: "초코렛문제은행2권", color: "khaki" },
  { book: "초코렛문제은행3권", color: "greenyellow" },
  { book: "초코렛문제은행4권", color: "lavender" },
  { book: "초코렛문제은행5권", color: "moccasin" },
  { book: "초코렛문제은행6권", color: "powderblue" },
  { book: "초코렛문제은행7권", color: "lightcyan" },
  { book: "초코렛문제은행8권", color: "sandybrown" },
  { book: "체르니40", color: "lightyellow" },
  { book: "체르니30", color: "khaki" },
  { book: "체르니100", color: "greenyellow" },
  { book: "바이엘4권", color: "lavender" },
  { book: "바이엘3권", color: "moccasin" },
  { book: "바이엘2권", color: "powderblue" },
  { book: "바이엘1권", color: "lightcyan" }
];
export const getBookColorHex = bookName => {
  return getColorHex(getBookColor(bookName));
};
export const getBookColor = bookName => {
  for (let m of bookColor) {
    if (m.book === bookName) return m.color;
  }
  return "white";
};
export const getColorHex = colorName => {
  for (let m of colorCode) {
    if (m.color === colorName) return m.hex;
  }
};
export const colorCode = [
  { color: "lightsalmon", hex: "#FFA07A", rgb: "rgb(255, 160, 122)" },
  { color: "salmon", hex: "#FA8072", rgb: "rgb(250, 128, 114)" },
  { color: "darksalmon", hex: "#E9967A", rgb: "rgb(233, 150, 122)" },
  { color: "lightcoral", hex: "#F08080", rgb: "rgb(240, 128, 128)" },
  { color: "indianred", hex: "#CD5C5C", rgb: "rgb(205, 92, 92)" },
  { color: "crimson", hex: "#DC143C", rgb: "rgb(220, 20, 60)" },
  { color: "firebrick", hex: "#B22222", rgb: "rgb(178, 34, 34)" },
  { color: "red", hex: "#FF0000", rgb: "rgb(255, 0, 0)" },
  { color: "darkred", hex: "#8B0000", rgb: "rgb(139, 0, 0)" },
  { color: "coral", hex: "#FF7F50", rgb: "rgb(255, 127, 80)" },
  { color: "tomato", hex: "#FF6347", rgb: "rgb(255, 99, 71)" },
  { color: "orangered", hex: "#FF4500", rgb: "rgb(255, 69, 0)" },
  { color: "gold", hex: "#FFD700", rgb: "rgb(255, 215, 0)" },
  { color: "orange", hex: "#FFA500", rgb: "rgb(255, 165, 0)" },
  { color: "darkorange", hex: "#FF8C00", rgb: "rgb(255, 140, 0)" },
  { color: "lightyellow", hex: "#FFFFE0", rgb: "rgb(255, 255, 224)" },
  { color: "lemonchiffon", hex: "#FFFACD", rgb: "rgb(255, 250, 205)" },
  { color: "lightgoldenrodyellow", hex: "#FAFAD2", rgb: "rgb(250, 250, 210)" },
  { color: "papayawhip", hex: "#FFEFD5", rgb: "rgb(255, 239, 213)" },
  { color: "moccasin", hex: "#FFE4B5", rgb: "rgb(255, 228, 181)" },
  { color: "peachpuff", hex: "#FFDAB9", rgb: "rgb(255, 218, 185)" },
  { color: "palegoldenrod", hex: "#EEE8AA", rgb: "rgb(238, 232, 170)" },
  { color: "khaki", hex: "#F0E68C", rgb: "rgb(240, 230, 140)" },
  { color: "darkkhaki", hex: "#BDB76B", rgb: "rgb(189, 183, 107)" },
  { color: "yellow", hex: "#FFFF00", rgb: "rgb(255, 255, 0)" },
  { color: "lawngreen", hex: "#7CFC00", rgb: "rgb(124, 252, 0)" },
  { color: "chartreuse", hex: "#7FFF00", rgb: "rgb(127, 255, 0)" },
  { color: "limegreen", hex: "#32CD32", rgb: "rgb(50, 205, 50)" },
  { color: "lime", hex: "#00FF00", rgb: "rgb(0, 255, 0)" },
  { color: "forestgreen", hex: "#228B22", rgb: "rgb(34, 139, 34)" },
  { color: "green", hex: "#008000", rgb: "rgb(0, 128, 0)" },
  { color: "darkgreen", hex: "#006400", rgb: "rgb(0, 100, 0)" },
  { color: "greenyellow", hex: "#ADFF2F", rgb: "rgb(173, 255, 47)" },
  { color: "yellowgreen", hex: "#9ACD32", rgb: "rgb(154, 205, 50)" },
  { color: "springgreen", hex: "#00FF7F", rgb: "rgb(0, 255, 127)" },
  { color: "mediumspringgreen", hex: "#00FA9A", rgb: "rgb(0, 250, 154)" },
  { color: "lightgreen", hex: "#90EE90", rgb: "rgb(144, 238, 144)" },
  { color: "palegreen", hex: "#98FB98", rgb: "rgb(152, 251, 152)" },
  { color: "darkseagreen", hex: "#8FBC8F", rgb: "rgb(143, 188, 143)" },
  { color: "mediumseagreen", hex: "#3CB371", rgb: "rgb(60, 179, 113)" },
  { color: "seagreen", hex: "#2E8B57", rgb: "rgb(46, 139, 87)" },
  { color: "olive", hex: "#808000", rgb: "rgb(128, 128, 0)" },
  { color: "darkolivegreen", hex: "#556B2F", rgb: "rgb(85, 107, 47)" },
  { color: "olivedrab", hex: "#6B8E23", rgb: "rgb(107, 142, 35)" },
  { color: "lightcyan", hex: "#E0FFFF", rgb: "rgb(224, 255, 255)" },
  { color: "cyan", hex: "#00FFFF", rgb: "rgb(0, 255, 255)" },
  { color: "aqua", hex: "#00FFFF", rgb: "rgb(0, 255, 255)" },
  { color: "aquamarine", hex: "#7FFFD4", rgb: "rgb(127, 255, 212)" },
  { color: "mediumaquamarine", hex: "#66CDAA", rgb: "rgb(102, 205, 170)" },
  { color: "paleturquoise", hex: "#AFEEEE", rgb: "rgb(175, 238, 238)" },
  { color: "turquoise", hex: "#40E0D0", rgb: "rgb(64, 224, 208)" },
  { color: "mediumturquoise", hex: "#48D1CC", rgb: "rgb(72, 209, 204)" },
  { color: "darkturquoise", hex: "#00CED1", rgb: "rgb(0, 206, 209)" },
  { color: "lightseagreen", hex: "#20B2AA", rgb: "rgb(32, 178, 170)" },
  { color: "cadetblue", hex: "#5F9EA0", rgb: "rgb(95, 158, 160)" },
  { color: "darkcyan", hex: "#008B8B", rgb: "rgb(0, 139, 139)" },
  { color: "teal", hex: "#008080", rgb: "rgb(0, 128, 128)" },
  { color: "powderblue", hex: "#B0E0E6", rgb: "rgb(176, 224, 230)" },
  { color: "lightblue", hex: "#ADD8E6", rgb: "rgb(173, 216, 230)" },
  { color: "lightskyblue", hex: "#87CEFA", rgb: "rgb(135, 206, 250)" },
  { color: "skyblue", hex: "#87CEEB", rgb: "rgb(135, 206, 235)" },
  { color: "deepskyblue", hex: "#00BFFF", rgb: "rgb(0, 191, 255)" },
  { color: "lightsteelblue", hex: "#B0C4DE", rgb: "rgb(176, 196, 222)" },
  { color: "dodgerblue", hex: "#1E90FF", rgb: "rgb(30, 144, 255)" },
  { color: "cornflowerblue", hex: "#6495ED", rgb: "rgb(100, 149, 237)" },
  { color: "steelblue", hex: "#4682B4", rgb: "rgb(70, 130, 180)" },
  { color: "royalblue", hex: "#4169E1", rgb: "rgb(65, 105, 225)" },
  { color: "blue", hex: "#0000FF", rgb: "rgb(0, 0, 255)" },
  { color: "mediumblue", hex: "#0000CD", rgb: "rgb(0, 0, 205)" },
  { color: "darkblue", hex: "#00008B", rgb: "rgb(0, 0, 139)" },
  { color: "navy", hex: "#000080", rgb: "rgb(0, 0, 128)" },
  { color: "midnightblue", hex: "#191970", rgb: "rgb(25, 25, 112)" },
  { color: "mediumslateblue", hex: "#7B68EE", rgb: "rgb(123, 104, 238)" },
  { color: "slateblue", hex: "#6A5ACD", rgb: "rgb(106, 90, 205)" },
  { color: "darkslateblue", hex: "#483D8B", rgb: "rgb(72, 61, 139)" },
  { color: "lavender", hex: "#E6E6FA", rgb: "rgb(230, 230, 250)" },
  { color: "thistle", hex: "#D8BFD8", rgb: "rgb(216, 191, 216)" },
  { color: "plum", hex: "#DDA0DD", rgb: "rgb(221, 160, 221)" },
  { color: "violet", hex: "#EE82EE", rgb: "rgb(238, 130, 238)" },
  { color: "orchid", hex: "#DA70D6", rgb: "rgb(218, 112, 214)" },
  { color: "fuchsia", hex: "#FF00FF", rgb: "rgb(255, 0, 255)" },
  { color: "magenta", hex: "#FF00FF", rgb: "rgb(255, 0, 255)" },
  { color: "mediumorchid", hex: "#BA55D3", rgb: "rgb(186, 85, 211)" },
  { color: "mediumpurple", hex: "#9370DB", rgb: "rgb(147, 112, 219)" },
  { color: "blueviolet", hex: "#8A2BE2", rgb: "rgb(138, 43, 226)" },
  { color: "darkviolet", hex: "#9400D3", rgb: "rgb(148, 0, 211)" },
  { color: "darkorchid", hex: "#9932CC", rgb: "rgb(153, 50, 204)" },
  { color: "darkmagenta", hex: "#8B008B", rgb: "rgb(139, 0, 139)" },
  { color: "purple", hex: "#800080", rgb: "rgb(128, 0, 128)" },
  { color: "indigo", hex: "#4B0082", rgb: "rgb(75, 0, 130)" },
  { color: "pink", hex: "#FFC0CB", rgb: "rgb(255, 192, 203)" },
  { color: "lightpink", hex: "#FFB6C1", rgb: "rgb(255, 182, 193)" },
  { color: "hotpink", hex: "#FF69B4", rgb: "rgb(255, 105, 180)" },
  { color: "deeppink", hex: "#FF1493", rgb: "rgb(255, 20, 147)" },
  { color: "palevioletred", hex: "#DB7093", rgb: "rgb(219, 112, 147)" },
  { color: "mediumvioletred", hex: "#C71585", rgb: "rgb(199, 21, 133)" },
  { color: "white", hex: "#FFFFFF", rgb: "rgb(255, 255, 255)" },
  { color: "snow", hex: "#FFFAFA", rgb: "rgb(255, 250, 250)" },
  { color: "honeydew", hex: "#F0FFF0", rgb: "rgb(240, 255, 240)" },
  { color: "mintcream", hex: "#F5FFFA", rgb: "rgb(245, 255, 250)" },
  { color: "azure", hex: "#F0FFFF", rgb: "rgb(240, 255, 255)" },
  { color: "aliceblue", hex: "#F0F8FF", rgb: "rgb(240, 248, 255)" },
  { color: "ghostwhite", hex: "#F8F8FF", rgb: "rgb(248, 248, 255)" },
  { color: "whitesmoke", hex: "#F5F5F5", rgb: "rgb(245, 245, 245)" },
  { color: "seashell", hex: "#FFF5EE", rgb: "rgb(255, 245, 238)" },
  { color: "beige", hex: "#F5F5DC", rgb: "rgb(245, 245, 220)" },
  { color: "oldlace", hex: "#FDF5E6", rgb: "rgb(253, 245, 230)" },
  { color: "floralwhite", hex: "#FFFAF0", rgb: "rgb(255, 250, 240)" },
  { color: "ivory", hex: "#FFFFF0", rgb: "rgb(255, 255, 240)" },
  { color: "antiquewhite", hex: "#FAEBD7", rgb: "rgb(250, 235, 215)" },
  { color: "linen", hex: "#FAF0E6", rgb: "rgb(250, 240, 230)" },
  { color: "lavenderblush", hex: "#FFF0F5", rgb: "rgb(255, 240, 245)" },
  { color: "mistyrose", hex: "#FFE4E1", rgb: "rgb(255, 228, 225)" },
  { color: "gainsboro", hex: "#DCDCDC", rgb: "rgb(220, 220, 220)" },
  { color: "lightgray", hex: "#D3D3D3", rgb: "rgb(211, 211, 211)" },
  { color: "silver", hex: "#C0C0C0", rgb: "rgb(192, 192, 192)" },
  { color: "darkgray", hex: "#A9A9A9", rgb: "rgb(169, 169, 169)" },
  { color: "gray", hex: "#808080", rgb: "rgb(128, 128, 128)" },
  { color: "dimgray", hex: "#696969", rgb: "rgb(105, 105, 105)" },
  { color: "lightslategray", hex: "#778899", rgb: "rgb(119, 136, 153)" },
  { color: "slategray", hex: "#708090", rgb: "rgb(112, 128, 144)" },
  { color: "darkslategray", hex: "#2F4F4F", rgb: "rgb(47, 79, 79)" },
  { color: "black", hex: "#000000", rgb: "rgb(0, 0, 0)" },
  { color: "cornsilk", hex: "#FFF8DC", rgb: "rgb(255, 248, 220)" },
  { color: "blanchedalmond", hex: "#FFEBCD", rgb: "rgb(255, 235, 205)" },
  { color: "bisque", hex: "#FFE4C4", rgb: "rgb(255, 228, 196)" },
  { color: "navajowhite", hex: "#FFDEAD", rgb: "rgb(255, 222, 173)" },
  { color: "wheat", hex: "#F5DEB3", rgb: "rgb(245, 222, 179)" },
  { color: "burlywood", hex: "#DEB887", rgb: "rgb(222, 184, 135)" },
  { color: "tan", hex: "#D2B48C", rgb: "rgb(210, 180, 140)" },
  { color: "rosybrown", hex: "#BC8F8F", rgb: "rgb(188, 143, 143)" },
  { color: "sandybrown", hex: "#F4A460", rgb: "rgb(244, 164, 96)" },
  { color: "goldenrod", hex: "#DAA520", rgb: "rgb(218, 165, 32)" },
  { color: "peru", hex: "#CD853F", rgb: "rgb(205, 133, 63)" },
  { color: "chocolate", hex: "#D2691E", rgb: "rgb(210, 105, 30)" },
  { color: "saddlebrown", hex: "#8B4513", rgb: "rgb(139, 69, 19)" },
  { color: "sienna", hex: "#A0522D", rgb: "rgb(160, 82, 45)" },
  { color: "brown", hex: "#A52A2A", rgb: "rgb(165, 42, 42)" },
  { color: "maroon", hex: "#800000", rgb: "rgb(128, 0, 0)" }
];
