import React from "react";
import { showName, showTime } from "../common/chulsFunc";
import Filename from '../common/filename';

const Board = ({ alarmBoard }) => {
  return (
    <>
      {Filename('board.jsx')}
      <div style={{ lineHeight: "4.0rem" }}>
        {alarmBoard.map(b => (
          <button
            className="btn mr-1 mb-1 p-1 btn-outline-danger"
            key={b._id + b.time}
            disabled
            style={{ border: "3px solid red" }}
          >
            {showTime(b.time)}
            {b.place}
            {showName(b)}
          </button>
        ))}
      </div>
    </>
  );
}

export default Board;