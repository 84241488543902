import React, { Component } from "react";
import { getStudents } from "../../services/studentService";
import NameButtons from "./nameButtons2";
import auth from "../../services/authService";

class Kids extends Component {
  state = {
    kids: [],
    car: [],
    noCar: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };
  car = [];
  async componentDidMount() {
    const { data: kids } = await getStudents();
    this.car = kids.filter(
      m =>
        (m.it1 !== undefined && m.it1 !== null && m.it1 !== "") ||
        (m.ot1 !== undefined && m.ot1 !== null && m.ot1 !== "") ||
        (m.it2 !== undefined && m.it2 !== null && m.it2 !== "") ||
        (m.ot2 !== undefined && m.ot2 !== null && m.ot2 !== "") ||
        (m.it3 !== undefined && m.it3 !== null && m.it3 !== "") ||
        (m.ot3 !== undefined && m.ot3 !== null && m.ot3 !== "") ||
        (m.it4 !== undefined && m.it4 !== null && m.it4 !== "") ||
        (m.ot4 !== undefined && m.ot4 !== null && m.ot4 !== "") ||
        (m.it5 !== undefined && m.it5 !== null && m.it5 !== "") ||
        (m.ot5 !== undefined && m.ot5 !== null && m.ot5 !== "")
    );
    this.car = this.car.map(m => m._id);
    // this.noCar = kids.filter(m => !this.car.includes(m));
    // this.noCar = this.noCar.map(m => m._id);
    this.setState({ kids });
  }

  user = auth.getCurrentUser();
  render() {
    return (
      <>
        {this.user && this.user.isAdmin && (
          <div>
            <div className="badge badge-info">
              useBus1.jsx 차 메뉴 클래스화 성공... 옮겨서 사용할것...
              필요할때...
            </div>
          </div>
        )}
        <button className="badge badge-pill badge-warning mx-2">
          {`학원차 이용자: ${this.car.length} 명`}
        </button>
        <button className="badge badge-pill badge-secondary  mx-2">
          {`비이용자: ${this.state.kids.length - this.car.length} 명`}
        </button>
        <NameButtons
          color="btn-warning"
          textColor="text-white"
          students={this.state.kids}
          car={this.car}
        />
      </>
    );
  }
}

export default Kids;
