import _ from "lodash";
//import { socket } from "./socket";
import { getPianos } from "./pianoService";

export default function socketOnFuncs(scopeThis, socket) {
  return function (err) {

    socket.on("downAll", () => {
      scopeThis.dataDown();
    });
    socket.on("new board", async params => {
      scopeThis.setState({ board1: params.alarmBoard });
    });

    socket.on("piano", params => {
      let pianos = [...scopeThis.state.pianos];
      let index = _.findIndex(pianos, { name: params.pianoName });
      pianos[index].users = params.users;
      scopeThis.setState({ pianos });
    });

    socket.on("newStatus", async params => {
      // await saveLog({  // 하는법
      //   who: "console.log",
      //   content: "newStatus1:",
      //   file: "chuls5.jsx",
      //   more: params
      // });
      let chuls = [...scopeThis.state.chuls];
      let index = _.findIndex(chuls, { _id: params._id });
      // console.log('newState params', params)
      // newStatus 가 없어서 에러 발생 <-- otherBan:"바만"인 학생을 새로 만들었을때
      // 처치법: db.students.updateOne({"name":"유준재"},{$set:{newStatus:{}}})
      chuls[index].status = params.status;
      if (params.onTime) {
        chuls[index].onTime = params.onTime;
      }
      let { data: pianos } = await getPianos();
      //console.log('socket params', params)
      scopeThis.setState({ chuls, pianos, counter: params.sumBoard, classify: params.classify });
    });
    socket.on("new reset", async params => {
      scopeThis.setState({ classify: params.classify });
    });
    socket.on("new sumBoard", async params => {
      scopeThis.setState({ counter: params.sumBoard });
    });
  }
}