import React from "react";
import { createKey } from "../../common/func7";
import { Td } from './td'

export const TableBody = (props) => {
  const { data, columns, fontSize, onMusicSelect } = props;
  return (
    <tbody>
      {data.map(item => (
        <tr key={item._id}>
          {columns.map(column =>
            <Td
              key={createKey(item, column)}
              item={item}
              column={column}
              fontSize={fontSize}
              onMusicSelect={onMusicSelect}
            ></Td>
          )}
        </tr>
      ))}
    </tbody>
  );
}
