import React, { Component } from "react";
import Loading from "../common/loading";
import fileName from "../common/func2";
import StudentAllCsTable from "./studentsPhoneTable";
import Pagination from "../common/pagination";
import {
  getStudents,
  saveStudent
} from "../../services/studentService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import authService from "../../services/authService";
// 20200701 import "../../my.css";
import { fieldSorter } from "../common/func1";
class StudentAllCs extends Component {
  state = {
    students: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
    widthArray: [70, 70, 70, 130, 130, 70, 70],
    fontSize: 20,
    subTitle: ''
  };
  user = authService.getCurrentUser();
  async componentDidMount() {
    const { data: students } = await getStudents();
    students.forEach(s => {
      s.pianoBan === "성인반"
        ? (s.schoolHag = "")
        : (s.schoolHag = s.schoolShort
          ? s.schoolShort + (s.hag ? s.hag : s.age ? s.age : "")
          : "");
    });
    this.setState({ students });
  }
  PrintNameOrder = () => {
    let students = this.state.students
    students = students.sort(fieldSorter(["name"]));
    this.setState({
      students: students, sortColumn: { path: "name", order: "asc" }
    })

    this.setState({ subTitle: '이름순' })
    // window.print();
  };
  PrintPayOrder = () => {
    let students = this.state.students
    students = students.sort(fieldSorter(["payday", "name"]));
    this.setState({
      students: students, sortColumn: { path: "payday", order: "asc" }
    })
    this.setState({ subTitle: '결제일순' })
    // window.print();
  };
  sortByHagAge = () => {
    let students = this.state.students
    students.forEach((s) => {
      s.hagAge = 0
      if (s.age !== undefined && s.age !== null)
        s.hagAge = parseInt(s.age)
      if (String(s.schoolLong).includes('초등학교'))
        s.hagAge = s.hagAge + parseInt(s.hag) + 7
      if (String(s.schoolLong).includes('중학교'))
        s.hagAge = s.hagAge + parseInt(s.hag) + 7 + 6
      if (String(s.schoolLong).includes('고등학교'))
        s.hagAge = s.hagAge + parseInt(s.hag) + 7 + 9
      if (s.hagAge === 0) s.hagAge = 40
    })
    students = students.sort(fieldSorter(["hagAge", "name"]));
    this.setState({
      students: students, sortColumn: {}
    })
  }
  sortByBirthMonth = () => {
    let students = this.state.students
    students.forEach((s) => {
      s.mob = String(s.dob).substring(5, 10) //month of birth
    })
    students = students.sort(fieldSorter(['mob', "name"]))
    this.setState({
      students: students, sortColumn: {}
    })
  }
  PrintHagOrder = () => {
    this.sortByHagAge()
    this.setState({ subTitle: '학년순' })
  };
  PrintMonthOrder = () => {
    this.sortByBirthMonth()
    this.setState({ subTitle: '생월순' })
  };
  handleLike = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };
  handleChangeWithArray = e => {
    let v = e.target.value;
    v = v.split(",");
    this.setState({
      [e.target.name]: [...v]
    });
  };
  handleChange = e => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value
    });
  };
  handleChange2 = e => {
    this.setState({
      [e.target.name]: JSON.parse(e.target.value)
    });
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };
  handleEditMode = (item, changedItem) => {
    let students = [...this.state.students];
    const index = _.findIndex(this.state.students, item);
    students.map(m => {
      if (m.edit) {
        delete m.edit;
        delete m.evalOld;
        delete m.pJinOld;
        delete m.theoryOld;
        delete m.schoolHag;
        // const temp = m.hag1;
        // delete m.hag1;
        saveStudent(m);
        // m.hag1 = temp;
      }
      return null
    });
    students[index].edit = true;
    this.setState({ students });
  };
  handleChanged = (id, path, value) => {
    const students = [...this.state.students];
    const ids = students.map(b => b._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    students[index][path] = value;
    this.setState({ students });
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allStudents
    } = this.state;
    let filtered = allStudents;
    if (searchQuery)
      filtered = allStudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const students = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: students };
  };
  columns = [
    {
      path: "name",
      label: "이름",
      minWidth: 70,
    },
    { path: "studPhone", label: "학생폰", minWidth: 70 }
  ];
  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;
    const { totalCount, data: students } = this.getPagedData();
    if (students.length === 0) {
      return <Loading></Loading>
    }
    else
      return (
        <>
          <div className="noPrint">{fileName("studentsPhone.jsx", this.user)}</div>
          {user && (
            <>
              <div className="noPrint">
                {/* todo: Change to <Ibtn/> */}
                <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
                  this.props.history.push("/Chuls5");
                }} style={{ backgroundColor: "#17A2B8", padding: 11, color: "#FFFFFF", borderRadius: "0.25rem" }}
                ></i>
              </div>
            </>
          )}
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <StudentAllCsTable
            className="noPrint"
            user={this.user}
            students={students}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onSort={this.handleSort}
            columns={this.columns}
            classString="table table-striped table-borderless noPrint"
            onEditMode={(item, changedItem) =>
              this.handleEditMode(item, changedItem)
            }
            onChanged={(id, path, value) => this.handleChanged(id, path, value)}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </>
      );
  }
}
export default StudentAllCs;
