import { withRouter } from "react-router-dom";
import { Docu1 } from './docu'
import DocusTable from "./docusTable";
// 20200701 import './concert.css';

class Docu extends Docu1 {
  fileName = 'concert1/concert.jsx'
  DocusTable = DocusTable
}
export default withRouter(Docu);
