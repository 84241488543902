import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

/////////new class //////////
export default class Student extends React.Component {
  constructor(props) {
    super(props);
    let student = _.toPlainObject(props);
    this._id = student._id;
    this.name = student.name;
    this.hag = student.hag;
    this.age = student.age;
  }

  showHagOrAge() {
    return this.hag ? this.hag : this.age ? this.age : null;
  }
  showButton(car) {
    return (
      <button
        className={
          car.includes(this._id)
            ? "btn m-1 btn-warning"
            : "btn m-1 btn-secondary"
        }
        key={this._id}
      >
        <Link className="nav-link p-0 text-white" to={`/busOnOff/${this._id}`}>
          {this.name}

          <span className="ml-2 badge badge-light text-muted">
            {this.showHagOrAge()}
          </span>
        </Link>
      </button>
    );
  }
}
