import React from "react";
import HSBar from "./hsBar";

const BooksBar = props => {
  return props.books.map((b, idx) => {
    return (
      <div key={idx}>
        <div
          style={{
            marginBottom: "5px"
          }}
        >
          <HSBar
            height={20}
            showTextIn
            id="new_id"
            fontColor="rgb(50,20,100)"
            data={b}
          />
        </div>
      </div>
    );
  });
};

export default BooksBar




