import React, { Component } from "react";
class SaveTitle extends Component {
  state = {
    title: ""
  };
  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.setState(state);
  };
  handleSave = ({ title, chuls, user, toast }) => {
    this.props.onSave({ title, chuls, user, toast });
  };
  handleRecall = ({ title, chuls, user, dataDown, toast }) => {
    this.props.onRecall({ title, chuls, user, dataDown, toast });
  };
  handleDelAll = ({ fields, chuls, user, toast }) => {
    // title에 fields를 적으면 flelds를 삭제함.
    this.props.onDelAll({ fields, chuls, user, toast });
  };

  handleCancel = () => {
    this.props.onCancel();
  };
  render() {
    const { chuls, user, dataDown, toast } = this.props;
    console.log("saveTitle", this.props);
    return (
      <>
        <form>
          <div className="form-row align-items-center">
            <div className="col-auto">
              <label className="sr-only" htmlFor="inlineFormInput">
                Name
              </label>
              <input
                value={this.state.title}
                name="title"
                type="text"
                className="form-control mb-2"
                id="inlineFormInput"
                placeholder=""
                onChange={this.handleChange}
              />
            </div>
            <div className="col-auto">
              <button
                onClick={() =>
                  this.handleSave({
                    title: this.state.title,
                    chuls,
                    user,
                    toast
                  })
                }
                className="btn btn-primary mb-2"
              >
                저장
              </button>
            </div>
            <div className="col-auto">
              <button
                onClick={() => this.handleCancel()}
                className="btn btn-primary mb-2"
              >
                취소
              </button>
            </div>
            <div className="col-auto">
              <button
                onClick={() =>
                  this.handleRecall({
                    title: this.state.title,
                    chuls,
                    user,
                    dataDown,
                    toast
                  })
                }
                className="btn btn-primary mb-2"
              >
                소환
              </button>
            </div>
            <div className="col-auto">
              <button
                onClick={() =>
                  this.handleDelAll({
                    fields: this.state.title,
                    chuls,
                    user,
                    toast
                  })
                }
                className="btn btn-primary mb-2"
              >
                1.값없으면 원본모두삭제 2.콤마구분 필드넣으면 필드전체 삭제
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default SaveTitle;
