import React, { Component } from "react";
import { raiseSort, renderSortIcon } from "../common/func_sort"

class TableHeader extends Component {

  render() {
    let num = 0;
    return (
      <thead>
        <tr>
          {this.props.columns.map(column => {
            num = num + 1;

            if (
              column.path !== "dob" &&
              column.path !== "sex" &&
              column.path !== "eval" &&
              column.path !== "pJin" &&
              column.path !== "theory"
            )
              return (
                <th
                  className="clickable"
                  key={num}
                  onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                >
                  {column.label} {renderSortIcon(column, this.props.sortColumn)}
                </th>
              );
            if (column.path === "dob")
              return (
                <>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    Birthyear
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    Birthmonth
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    Birthday
                  </th>
                </>
              );
            if (column.path === "pJin")
              return (
                <th
                  className="clickable"
                  key={num}
                  onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                >
                  Pjin
                </th>
              );
            if (column.path === "theory")
              return (
                <th
                  className="clickable"
                  key={num}
                  onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                >
                  Theory
                </th>
              );
            if (column.path === "sex")
              return (
                <>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    male
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    female
                  </th>
                </>
              );
            if (column.path === "eval")
              return (
                <>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    aa1
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    aa2
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    aa3
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    aa4
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    bb1
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    bb2
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    bb3
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    bb4
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    cc1
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    cc2
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    cc3
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    cc4
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    dd1
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    dd2
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    dd3
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    dd4
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ee1
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ee2
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ee3
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ee4
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ff1
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ff2
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ff3
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    ff4
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    gg1
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    gg2
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    gg3
                  </th>
                  <th
                    className="clickable"
                    key={num}
                    onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                  >
                    gg4
                  </th>
                </>
              );
            return null
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
