import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "./table";
// import "./docu.css";
// 20200701 import "../../../my.css";

class DocusTable extends Component {
  columns = []
  render() {
    const {
      students,
      onSort,
      sortColumn,
      onChanged,
      onChanged1,
      onEditMode,
      fontSize,
      onWinOpen,
      selectedMusic,
      small
    } = this.props;
    if (small)
      this.columns = [
        { path: "name", label: "이름", minWidth: 70 },
        { path: "button", label: '' },
        { path: "melodizer", label: "작곡자" },
        { path: "musicTitle", label: "곡명" },
        { path: "musicBook", label: "교재명" },
      ];
    else
      this.columns = [
        { path: "name", label: "이름", minWidth: 70 },
        { path: "nothing", label: "출석" },
        { path: "hag1", label: "학년" },
        { path: "button", label: '' },
        { path: "melodizer", label: "작곡자" },
        { path: "musicTitle", label: "곡명" },
        { path: "musicBook", label: "교재명" },
      ];
    let students1 = students.filter(
      b => b.pianoBan === "베토벤반" || b.pianoBan === "바하반"
    );
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div className="noPrint">
            <code>pay/docusTable.jsx</code>
          </div>
        )}
        <Table
          columns={this.columns}
          fontSize={fontSize}
          data={students1}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table table-bordered"
          onEditMode={onEditMode}
          onChanged={onChanged}
          onChanged1={onChanged1}
          style={{ pageBreakInside: "auto" }}
          onWinOpen={onWinOpen}
          selectedMusic={selectedMusic}
        />
      </>
    );
  }
}

export default withRouter(DocusTable);
