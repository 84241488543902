import React, { useState } from 'react';
import { useParams, NavLink } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox } from "@material-ui/core";
import { toast } from "react-toastify";
import { MyTextField, MyRadio } from "../common/myField";
import DatePickers from "../common/datepicker";
import fileName from '../common/func2';

const RECORD = gql`
mutation RecordTuitionPayment($InputRecordTuitionPayment: InputRecordTuitionPayment!) {
  recordTuitionPayment(input: $InputRecordTuitionPayment) {
    studentName
  }
}
`;
const validationSchema = Yup.object().shape({
  paidBoolean: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
  by: Yup.string()
    .max(3, 'Too Long!')
    .required('Required')
});
const RecordPay = (props) => {
  const [recordTuitionPayment, { data, loading, error }] = useMutation(RECORD);
  const [paidDate1, setPaidDate1] = useState(new Date());
  const [paidDate, setPaidDate] = useState(JSON.stringify(new Date()).substr(1, 10));
  const user = props.user;
  let { id } = useParams();
  if (id !== localStorage.getItem("id")) return null;
  const userName = user.name;
  const userId = user._id;


  const handlePaidDateChange = e => {
    setPaidDate(e.target.value);
    setPaidDate1(e.target.value);
  };
  const disabledDatePickers = () => {
    setPaidDate(null)
    return <DatePickers disabled={true} handleDateChange={handlePaidDateChange} date={paidDate} />
  }

  return (

    <div className="container">
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-sm">
          <h3>납부내역 장부기록</h3>
        </div>
        <div className="col-sm">
          <NavLink className="badge badge-success m-2" to="/nyp" >
            미납자 보기
          </NavLink>
        </div>
        <div className="col-sm">
          {fileName("recordPay.jsx", user)}
        </div>
      </div>
      <Formik
        validateOnChange={true}
        initialValues={{
          "studentName": localStorage.getItem("studentName"),
          "userName": userName,
          amount: localStorage.getItem("amount"),
          paidBoolean: false,
          paidDate: new Date(),
          by: null,
        }}

        validationSchema={validationSchema}

        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // same shape as initial values
          setSubmitting(true);
          // values.given = givenDate1
          if (!values.paidBoolean)
            // values.paidDate = paidDate1
            // else 
            values.paidDate = null;
          else
            values.paidDate = paidDate1;
          const a = {
            "InputRecordTuitionPayment": {
              "id": id,
              "userName": userName,
              "userId": userId,
              "paidDate": values.paidDate,
              "by": values.by,
            }
          }


          console.log('before', values)
          await recordTuitionPayment({
            variables: a
          });

          toast.success('🦄 저장되었습니다!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSubmitting(false);
          resetForm();
          props.history.goBack();
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            학생 이름:<MyTextField name="studentName" placeholder="학생이름" />
            <br />
            기록자 이름:<MyTextField name="userName" disabled />
            <br />

            교육비<MyTextField name="amount" disabled />

            <br />
            납부했음:
            <Field name="paidBoolean" type="checkbox" as={Checkbox} />
            <br />
            납부일:{values.paidBoolean ?
              <DatePickers handleDateChange={handlePaidDateChange} date={paidDate} /> :
              disabledDatePickers()}

            <div>납부방법:</div>
            <MyRadio name="by" type="radio" value="현금" label="현금" />
            <MyRadio name="by" type="radio" value="입금" label="입금" />
            <MyRadio name="by" type="radio" value="국민" label="국민" />
            <MyRadio name="by" type="radio" value="롯데" label="롯데" />
            <MyRadio name="by" type="radio" value="삼성" label="삼성" />
            <MyRadio name="by" type="radio" value="신한" label="신한" />
            <MyRadio name="by" type="radio" value="우리" label="우리" />
            <MyRadio name="by" type="radio" value="하나" label="하나" />
            <MyRadio name="by" type="radio" value="현대" label="현대" />
            <MyRadio name="by" type="radio" value="카카오" label="카카오" />
            <MyRadio name="by" type="radio" value="IBK" label="IBK" />
            <MyRadio name="by" type="radio" value="KB" label="KB" />
            <br />

            <div>
              <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저 장</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
};

export default RecordPay