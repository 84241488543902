import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody3";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  className,
  onChanged,
  onEditMode,
  onDelete
}) => {
  return (
    <table className={className}>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody
        columns={columns}
        data={data}
        onEditMode={(_id, path) => onEditMode(_id, path)}
        onChanged={(id, path, value) => onChanged(id, path, value)}
        onDelete={book => onDelete(book)}
      />
    </table>
  );
};

export default Table;
