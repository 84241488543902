import React, { Component } from "react";
import { renderCell, createKey } from "./func7";
import _ from "lodash";
import Eval7 from "../eval/eval7one";
import TextareaAutosize from "react-textarea-autosize";

class TableBody extends Component {
  state = { changedItem: {} };




  handleClickItem = (item, changedItem) => {
    this.props.onClickItem(item, changedItem);
  };

  render() {
    const { data, columns, curMonth, prevMonth } = this.props;
    return (
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            {columns.map(column => {
              if (!item.edit) {
                return (
                  (column.path === "name" && (
                    <td key={createKey(item, column)} className="col">
                      {renderCell(item, column)}
                    </td>
                  )) ||
                  (column.path === "pJin" && (
                    <td key={createKey(item, column)} className="col">
                      <>
                        <div className="badge badge-warning">현재 진도</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "pJin")}
                          disabled
                          className="border border-warning"
                        />
                        <div className="badge badge-info">전월 진도</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "pJinOld")}
                          disabled
                        />
                        <div className="badge badge-warning">현재 이론</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "theory")}
                          disabled
                          className="border border-warning"
                        />
                        <div className="badge badge-info">전월 이론</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "theoryOld")}
                          disabled
                        />
                      </>
                    </td>
                  )) ||
                  (column.path === "letterOld" && (
                    <td key={createKey(item, column)} className="col-2">
                      <div className="badge badge-info">{prevMonth}월 편지</div>

                      <TextareaAutosize
                        type="text"
                        value={renderCell(item, column)}
                        disabled
                      />
                    </td>
                  )) ||
                  (column.path === "letter" && (
                    <td
                      key={createKey(item, column)}
                      onClick={() => this.handleClickItem(item, column.path)}
                      className="col-2"
                    >
                      <div className="badge badge-warning">{curMonth}월 학습상황</div>
                      <TextareaAutosize
                        type="text"
                        value={renderCell(item, column)}
                        className="border border-warning"
                      />
                    </td>
                  )) ||
                  (column.path === "eval" && (
                    <td key={createKey(item, column)} className="col-2">
                      <div className="badge badge-warning">{curMonth}월 평가</div>

                      <Eval7
                        eval={_.get(item, column.path)}
                        onChange={() => { }}
                      />
                    </td>
                  )) ||
                  (column.path === "evalOld" && (
                    <td key={createKey(item, column)} className="col-2">
                      <div className="badge badge-info">{prevMonth}월 평가</div>

                      <Eval7
                        eval={_.get(item, column.path)}
                        onChange={() => { }}
                      />
                    </td>
                  ))
                );
              }
              if (item.edit) {
                return (
                  (column.path === "name" && (
                    <td key={createKey(item, column)}>
                      {renderCell(item, column)}
                    </td>
                  )) ||
                  (column.path === "pJin" && (
                    <td key={createKey(item, column)} className="col">
                      <>
                        <div className="badge badge-warning">{curMonth}월 진도</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "pJin")}
                          disabled
                          className="border border-warning"
                        />
                        <div className="badge badge-info">{prevMonth}월 진도</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "pJinOld")}
                          disabled
                        />
                        <div className="badge badge-warning">{curMonth} 이론</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "theory")}
                          disabled
                          className="border border-warning"
                        />
                        <div className="badge badge-info">{prevMonth}월 이론</div>

                        <TextareaAutosize
                          type="text"
                          value={_.get(item, "theoryOld")}
                          disabled
                        />
                      </>
                    </td>
                  )) ||
                  (column.path === "letterOld" && (
                    <td key={createKey(item, column)}>
                      <div className="badge badge-info">{prevMonth}월 편지</div>

                      <TextareaAutosize
                        type="text"
                        value={renderCell(item, column)}
                        disabled
                      />
                    </td>
                  )) ||
                  (column.path === "letter" && (
                    <td key={createKey(item, column)}>
                      {/* <TextareaAutosize
                        type="text"
                        value={renderCell(item, column)}
                      /> */}
                      <div className="badge badge-warning">{curMonth}월 학습상황</div>

                      <EditTd
                        path={column.path}
                        itemId={item._id}
                        item={renderCell(item, column)}
                        onChanged={(id, path, value) =>
                          this.props.onChanged(id, path, value)
                        }
                      />
                    </td>
                  )) ||
                  (column.path === "eval" && (
                    <td key={createKey(item, column)}>
                      <div className="badge badge-warning">{curMonth} 평가</div>

                      <Eval7
                        eval={_.get(item, column.path)}
                        onChange={() => { }}
                      />
                    </td>
                  )) ||
                  (column.path === "evalOld" && (
                    <td key={createKey(item, column)}>
                      <div className="badge badge-info">{curMonth}월 평가</div>

                      <Eval7
                        eval={_.get(item, column.path)}
                        onChange={() => { }}
                      />
                    </td>
                  ))
                );
              }
              return true
            })}
          </tr>
        ))}
      </tbody>
    );
  }
}
class EditTd extends Component {
  state = { item: this.props.item, path: this.props.path };
  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.props.onChanged(this.props.itemId, this.props.path, input.value);
    this.setState(state);
  };
  render() {
    const { item } = this.state;
    return (
      <div>
        <TextareaAutosize
          value={item}
          name="item"
          type="text"
          className="form-control mb-2 border border-warning"
          id="inlineFormInput"
          placeholder=""
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
export default TableBody;
