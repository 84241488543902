import React from "react";
import NextCar from "./nextCar";
import OtherBs from "./otherBs";

const OtherButtons = props => {
  const { onoff, time, day, student } = props;
  const { show, temp } = student;
  if (show && temp.onoff === onoff && temp.time === time && temp.day === day) {
    return (
      <>
        <NextCar {...props} />
        <OtherBs {...props} />
      </>
    );
  }
  if (!show) return null;
  return null;
};

export default OtherButtons;
