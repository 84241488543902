import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getStudent, saveStudent } from "../../services/studentService";
// import { getGenres } from "../services/genreService";

class StudentForm extends Form {
  state = {
    data: {
      이름: "",
      it1: "",
      ip1: "",
      ot1: "",
      op1: "",
      it2: "",
      ip2: "",
      ot2: "",
      op2: "",
      it3: "",
      ip3: "",
      ot3: "",
      op3: "",
      it4: "",
      ip4: "",
      ot4: "",
      op4: "",
      it5: "",
      ip5: "",
      ot5: "",
      op5: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    이름: Joi.string()
      .required()
      .label("이름"),
    it1: Joi.string().allow(null, ""),
    ip1: Joi.string().allow(null, ""),
    ot1: Joi.string().allow(null, ""),
    op1: Joi.string().allow(null, ""),
    it2: Joi.string().allow(null, ""),
    ip2: Joi.string().allow(null, ""),
    ot2: Joi.string().allow(null, ""),
    op2: Joi.string().allow(null, ""),
    it3: Joi.string().allow(null, ""),
    ip3: Joi.string().allow(null, ""),
    ot3: Joi.string().allow(null, ""),
    op3: Joi.string().allow(null, ""),
    it4: Joi.string().allow(null, ""),
    ip4: Joi.string().allow(null, ""),
    ot4: Joi.string().allow(null, ""),
    op4: Joi.string().allow(null, ""),
    it5: Joi.string().allow(null, ""),
    ip5: Joi.string().allow(null, ""),
    ot5: Joi.string().allow(null, ""),
    op5: Joi.string().allow(null, "")
  };

  async populateStudent() {
    try {
      const studentId = this.props.match.params.id;
      if (studentId === "new") return;

      const { data: student } = await getStudent(studentId);
      this.setState({ data: this.mapToViewModel(student) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateStudent();
  }

  mapToViewModel(student) {
    return {
      _id: student._id,
      이름: student.이름,
      it1: student.it1,
      ip1: student.ip1,
      ot1: student.ot1,
      op1: student.op1,
      it2: student.it2,
      ip2: student.ip2,
      ot2: student.ot2,
      op2: student.op2,
      it3: student.it3,
      ip3: student.ip3,
      ot3: student.ot3,
      op3: student.op3,
      it4: student.it4,
      ip4: student.ip4,
      ot4: student.ot4,
      op4: student.op4,
      it5: student.it5,
      ip5: student.ip5,
      ot5: student.ot5,
      op5: student.op5
    };
  }

  doSubmit = async () => {
    let data = { ...this.state.data };
    let data1 = { ...this.state.data };
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === "undefined") {
        data1[key] = "";
      }
    }
    await saveStudent(this.state.data);

    this.props.history.push("/bus1");
  };

  render() {
    return (
      <div>
        <div>
          <div className="badge badge-info">bus/studentForm.jsx</div>
        </div>

        <h3>
          <span className="col-12 badge badge-secondary">
            학 생 정 보 입 력
          </span>
        </h3>
        <form onSubmit={this.handleSubmit}>
          {this.renderButton("저장")}

          <div className="form-row">
            <div className="col-1">{this.renderInput("이름", "이름")}</div>
          </div>

          <div className="form-row">
            <div className="col-1">{this.renderInput("it1", "it1")}</div>
            <div className="col-1">{this.renderInput("ip1", "ip1")}</div>
            <div className="col-1">{this.renderInput("ot1", "ot1")}</div>
            <div className="col-1">{this.renderInput("op1", "op1")}</div>
          </div>

          <div className="form-row">
            <div className="col-1">{this.renderInput("it2", "it2")}</div>
            <div className="col-1">{this.renderInput("ip2", "ip2")}</div>
            <div className="col-1">{this.renderInput("ot2", "ot2")}</div>
            <div className="col-1">{this.renderInput("op2", "op2")}</div>
          </div>
          <div className="form-row">
            <div className="col-1">{this.renderInput("it3", "it3")}</div>
            <div className="col-1">{this.renderInput("ip3", "ip3")}</div>
            <div className="col-1">{this.renderInput("ot3", "ot3")}</div>
            <div className="col-1">{this.renderInput("op3", "op3")}</div>
          </div>
          <div className="form-row">
            <div className="col-1">{this.renderInput("it4", "it4")}</div>
            <div className="col-1">{this.renderInput("ip4", "ip4")}</div>
            <div className="col-1">{this.renderInput("ot4", "ot4")}</div>
            <div className="col-1">{this.renderInput("op4", "op4")}</div>
          </div>
          <div className="form-row">
            <div className="col-1">{this.renderInput("it5", "it5")}</div>
            <div className="col-1">{this.renderInput("ip5", "ip5")}</div>
            <div className="col-1">{this.renderInput("ot5", "ot5")}</div>
            <div className="col-1">{this.renderInput("op5", "op5")}</div>
          </div>
        </form>
      </div>
    );
  }
}

export default StudentForm;
