import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table1 from "../common/table1";

class BusTable1 extends Component {
  columns = [
    {
      path: "연령",
      label: "나이"
    },
    {
      path: "학년",
      label: "학년"
    },
    {
      path: "이름",
      label: "이름",
      content: student => <Link to={`/bus/${student._id}`}>{student.이름}</Link>
    }
  ];

  deleteColumn = {
    key: "delete",
    content: student => (
      <button
        onClick={() => this.props.onDelete(student)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  render() {
    const { students, onSort, sortColumn } = this.props;
    this.columns.length = 3;

    for (let i = 0; i < this.props.컬럼.length; i++) {
      this.columns.push({
        path: "it" + this.props.컬럼[i].toString(),
        label: "it" + this.props.컬럼[i].toString()
      });
      this.columns.push({
        path: "ip" + this.props.컬럼[i].toString(),
        label: "ip" + this.props.컬럼[i].toString()
      });
      this.columns.push({
        path: "ot" + this.props.컬럼[i].toString(),
        label: "ot" + this.props.컬럼[i].toString()
      });
      this.columns.push({
        path: "op" + this.props.컬럼[i].toString(),
        label: "op" + this.props.컬럼[i].toString()
      });
    }
    return (
      <Table1
        columns={this.columns}
        data={students}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default BusTable1;
