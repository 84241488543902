import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Filename from '../common/filename';
import { btnStyle1 } from "../common/setting";

const GET_STUDENT_ONTIME = gql`
query GetStudentOntime{
  getStudentOntime{
    _id
    name
    onTime
  }
}
`;

const Ontime = (props) => {
  const { loading, error, data } = useQuery(GET_STUDENT_ONTIME);
  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;

  return (
    <>
      {Filename('showOntime.jsx')}
      <div className="container text-center">
        <div className='row'>
          <h4 className='col-6'>등원시각</h4>
          {props.user && props.user.isTeacher && (
            < i className="fas fa-chalkboard-teacher fa-lg col-2" onClick={() => {
              props.history.push("/Chuls5");
            }} style={btnStyle1}
            ></i>
          )
          }
        </div>
        {data.getStudentOntime.map(m => {
          return (
            <div className="row text-center" style={{ fontSize: "24px" }}>
              <p className="col-4 m-2"> {m.name}  </p>
              <p className="col-4 m-2">{m.onTime}</p>
            </div>
          )
        })}
      </div>
    </>
  );
}
export default Ontime