import React from "react";
import { btnStyle1 } from "./setting";

const Ibtn = ({ icon, onClick, style }) => {
  return (<i
    className={icon}
    onClick={onClick}
    style={style ? style : btnStyle1}
  />)
}
export default Ibtn