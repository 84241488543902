import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody10";

const Table = ({ columns, sortColumn, onSort, data, lessons, className }) => {
  return (
    <table className={className}>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} lessons={lessons} />
    </table>
  );
};

export default Table;
