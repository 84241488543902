import React from "react";
import Modal from "react-responsive-modal";
import _ from "lodash";

import { onlyUnique } from "../common/func1";
import SelectName from "../common/selectName";
// import SelectLastName from "../common/selectLastName";

export const selectNameModal = ({
  win2open,
  win1param,
  win2params,
  rh,
  chuls,
  setState,
  socket
}) => {
  return (
    <Modal
      open={win2open}
      onClose={() => onCloseModal({ win: "win2", setState })}
      center
      className="btn btn-warning"
    >
      <SelectName
        student={win1param.student}
        onoff={win1param.onoff}
        time={win1param.time}
        dow={win1param.day}
        students={win1param.students}
        allStudents={win1param.allStudents}
        onAddSelect={win1param.onAddSelect}
        lastName={win2params}
        socket={socket}
      />
      {/* <pre>
        {JSON.stringify(
          {
            where: "selectNameModal333",
            onoff: win1param.onoff,
            time: win1param.time,
            dow: win1param.dow,
            day: win1param.day,
            dow: "here"
          },
          null,
          2
        )}
      </pre> */}
      <button
        className="btn btn-warning m-2"
        onClick={() => onCloseModal({ win: "win2", setState })}
      >
        다시 선택
      </button>
      <button
        className="btn btn-warning"
        onClick={() => {
          rh.handleShowButtons({
            student: win1param.student,
            onoff: win1param.onoff,
            time: win1param.time,
            dow: win1param.dow,
            setState,
            chuls
          });
          onCloseModal({ win: "winAll", setState });
        }}
      >
        취소
      </button>
    </Modal>
  );
};

export const selectLastNameModal = ({
  win1open,
  win1param,
  rh,
  chuls,
  setState,
  socket
}) => {
  return (
    <Modal
      open={win1open}
      onClose={() => onCloseModal({ win: "win1", setState })}
      center
      className="btn btn-warning"
    >
      <SelectLastName
        //  {...props}
        student={win1param.student}
        onoff={win1param.onoff}
        time={win1param.time}
        dow={win1param.dow}
        students={win1param.students}
        allStudents={win1param.allStudents}
        onAddSelect={win1param.onAddSelect}
        setState={setState}
        socket={socket}
      />
      {/* <pre>
        {JSON.stringify(
          {
            where: "selectLastNameModal",
            onoff: win1param.onoff,
            time: win1param.time,
            dow: win1param.dow,
            day: win1param.day,
            dow: "here"
          },
          null,
          2
        )}
      </pre> */}
      <button
        className="btn btn-warning"
        onClick={() => {
          rh.handleShowButtons({
            student: win1param.student,
            onoff: win1param.onoff,
            time: win1param.time,
            dow: win1param.dow,
            setState,
            chuls
          });
          onCloseModal({ win: "win1", setState });
        }}
      >
        취소
      </button>
    </Modal>
  );
};

const onCloseModal = ({ win, setState }) => {
  if (win === "win1") setState({ win1open: false });
  if (win === "win2") setState({ win2open: false });
  if (win === "winAll") setState({ win1open: false, win2open: false });
};

const handleSelectByLastName = ({ lastName, setState }) => {
  setState({ win2open: true, win2params: { lastName: lastName } });
};

const SelectLastName = props => {
  const { student, onoff, time, dow: day } = props;
  if (
    student.choose &&
    student.temp.onoff === onoff &&
    student.temp.time === time &&
    student.temp.day === day
  ) {
    return <Names {...props} />;
  }
  return null;
};

const Names = props => {
  const { allStudents, students } = props;
  let otherStudents = _.difference(allStudents, students);
  let lastNames = otherStudents.map(m => m.name.substring(0, 1));
  lastNames = lastNames.filter(onlyUnique).sort();
  let num = 0;
  return (
    <div>
      {lastNames.map(m => {
        num = num + 1;
        return <Name lastName={m} {...props} key={num} />;
      })}
    </div>
  );
};

const Name = ({ lastName, setState }) => {
  return (
    <button
      className="btn m-1 btn-info"
      key={lastName}
      onClick={() => handleSelectByLastName({ lastName, setState })}
    >
      {lastName}
    </button>
  );
};

export default SelectLastName;
