import React from "react";
import { HandlerConsumer } from '../new/handler'

const TodayOutRideB = props => {
  if (!props.student.edit) {
    if (props.onoff === 'off') {
      return (
        <HandlerConsumer>
          {
            handler =>

              <button
                className="btn btn-info ml-1 mr-1"
                key={`X`}
                onClick={() =>
                  handler.onTodayOutRideButton(
                    props.student,
                    props.onoff,
                    props.time,
                    props.day
                  )
                }
              >
                {props.student.todayOutRide === undefined ? '하원안탐' : props.student.todayOutRide === true ? '하원안탐' : '하원탐'}
              </button>}
        </HandlerConsumer>
      );
    }
  }
  return null;
};

export default TodayOutRideB;
