import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import auth from "../../services/authService";
import BusTable1 from "./busTable1";
import Pagination from "../common/pagination";
import { getStudents, deleteStudent } from "../../services/carsService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import fileName from "../common/func2";
class Bus extends Component {
  state = {
    students: [],
    등원차: [1, 2],
    차시간: {},
    요일: [1, 2, 3, 4, 5],
    연령: [6, 7],
    학년: [1, 2, 3, 4, 5, 6],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "이름", order: "asc" }
  };

  async componentDidMount() {
    const { data: students } = await getStudents();
    this.setState({ students });
  }

  handleDelete = async student => {
    const originalStudents = this.state.students;
    const students = originalStudents.filter(m => m._id !== student._id);
    this.setState({ students });

    try {
      await deleteStudent(student._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This student has already been deleted.");

      this.setState({ students: originalStudents });
    }
  };

  handleLike = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };
  handleChange = 등원차 => {
    this.setState({ 등원차 });
  };
  handleDayChange = 요일 => {
    요일.sort();
    this.setState({ 요일 });
  };
  handleHakChange = 학년 => {
    학년.sort();
    this.setState({ 학년 });
  };
  handleSeChange = 연령 => {
    연령.sort();
    this.setState({ 연령 });
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      등원차,
      학년,
      연령,
      students: allStudents
    } = this.state;

    let filtered = allStudents;

    if (등원차 === 1) {
      filtered = filtered.filter(
        m => m.it1 || m.it2 || m.it3 || m.it4 || m.it5
      );
    } else {
      filtered = filtered.filter(
        m => m.ot1 || m.ot2 || m.ot3 || m.ot4 || m.ot5
      );
    }

    function 학년1(filtered) {
      return (
        (연령[0] && filtered.연령 === 연령[0]) ||
        (연령[1] && filtered.연령 === 연령[1]) ||
        (학년[0] && filtered.학년 === 학년[0]) ||
        (학년[1] && filtered.학년 === 학년[1]) ||
        (학년[2] && filtered.학년 === 학년[2]) ||
        (학년[3] && filtered.학년 === 학년[3]) ||
        (학년[4] && filtered.학년 === 학년[4]) ||
        (학년[5] && filtered.학년 === 학년[5])
      );
    }
    filtered = filtered.filter(학년1);

    if (searchQuery)
      filtered = filtered.filter(
        m =>
          m.이름 !== null &&
          m.이름.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const students = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: students };
  };

  user = auth.getCurrentUser();

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: students } = this.getPagedData();
    return (
      <>
        {fileName("bus.jsx", this.user)}
        <div className="row">
          <div className="col-3 bg-info">
            <div className="row">
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="checkbox"
                  value={this.state.연령}
                  onChange={this.handleSeChange}
                >
                  <ToggleButton value={6}>6세</ToggleButton>
                  <ToggleButton value={7}>7세</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  type="checkbox"
                  value={this.state.학년}
                  onChange={this.handleHakChange}
                >
                  <ToggleButton value={1}>1학년</ToggleButton>
                  <ToggleButton value={2}>2학년</ToggleButton>
                  <ToggleButton value={3}>3학년</ToggleButton>
                  <ToggleButton value={4}>4학년</ToggleButton>{" "}
                  <ToggleButton value={5}>5학년</ToggleButton>
                  <ToggleButton value={6}>6학년</ToggleButton>{" "}
                </ToggleButtonGroup>
              </ButtonToolbar>
            </div>
            <div className="row">
              <ToggleButtonGroup
                type="checkbox"
                // name="요일"
                value={this.state.요일}
                onChange={this.handleDayChange}
              >
                <ToggleButton value={1}>월</ToggleButton>
                <ToggleButton value={2}>화</ToggleButton>
                <ToggleButton value={3}>수</ToggleButton>
                <ToggleButton value={4}>목</ToggleButton>
                <ToggleButton value={5}>금</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="row">
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.등원차}
                onChange={this.handleChange}
              >
                <ToggleButton value={1}>등원차</ToggleButton>
                <ToggleButton value={2}>하원차</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <p>전체: {totalCount} 명</p>
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />

            {this.state.요일 && (
              <BusTable1
                students={students}
                sortColumn={sortColumn}
                onLike={this.handleLike}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
                컬럼={this.state.요일}
              />
            )}
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
            {/* </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default Bus;
