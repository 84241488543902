import React, { Component } from "react";
// 20200701 import "../students/studentAllCs.css";
import { raiseSort, renderSortIcon } from "../common/func_sort"
// columns: array
// sortColumn: object
// onSort: function

class TableHeader extends Component {

  render() {
    const { columns, print } = this.props;
    let num = 0;
    return (
      <thead>
        <tr>
          {columns.map(column => {
            num = num + 1;
            if (!print)
              return (
                <th
                  className="clickable"
                  style={{ minWidth: column.minWidth }}
                  key={num}
                  onClick={() => raiseSort(column.path, this.props.sortColumn, this.props.onSort)}
                >
                  {column.label} {renderSortIcon(column, this.props.sortColumn)}
                </th>
              );
            return null
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
