import React from "react";
import SaveTitle from "./saveTitle";

class SaveB extends React.Component {
  state = { edit: false };
  render() {
    return (
      <>
        {!this.state.edit && (
          <button
            className="btn btn-info mr-2 mb-1"
            style={{
              color: 'rgb(0,0,0)',
              backgroundColor: 'rgba(255, 237, 199, .3)',
            }}

            key={this.props.children}
            onClick={() => this.setState({ edit: true })}
          >
            {this.props.children}
          </button>
        )}
        {this.state.edit && <SaveTitle {...this.props} />}
      </>
    );
  }
}
export default SaveB;
