import React from "react";
import { Link } from "react-router-dom";

const NameButtons = props => {
  let students = [...props.students];

  return (
    <div>
      {students.map(student => (
        <Name student={student} {...props} />
      ))}
    </div>
  );
};

const Name = props => {
  const 학년_연령 = student => {
    return student.hag ? student.hag : student.age ? student.age : null;
  };

  return (
    <button
      className={
        props.car.includes(props.student._id)
          ? "btn m-1 btn-warning"
          : "btn m-1 btn-secondary"
      }
      key={props.student._id}
    >
      <Link
        className="nav-link p-0 text-white"
        to={`/busOnOff/${props.student._id}`}
      >
        {props.student.name}

        <span className="m-0 badge badge-light text-muted">
          {학년_연령(props.student)}
        </span>
      </Link>
    </button>
  );
};

export default NameButtons;
