import React, { useState } from "react";
import { renderCell } from "../../common/func7";
export const Td = ({ item, column, fontSize, handleClick, onChanged, onChanged1, onWinOpen, selectedMusic }) => {
  const returnTd = (field) => {
    if (column.path === field &&
      selectedMusic.studentId === item._id &&
      selectedMusic.music !== null) {
      onChanged1(item._id, selectedMusic.music);
      if (field === 'docu') selectedMusic.clear();
      return (
        <td
          style={{ fontSize: parseInt(fontSize) }}
        >
          {selectedMusic.music !== undefined ? selectedMusic.music[field] : null}
        </td>
      );
    }
  }
  if (column.path === 'button')
    return (
      <td>
        {/* {/* //todo: Change to <Ibtn/> */}
        <i onClick={() => onWinOpen(item, (selectedMusic) => {
          onChanged(item._id, 'musicBook', selectedMusic.musicBook);
          onChanged(item._id, 'musicTitle', selectedMusic.musicTitle);
          onChanged(item._id, 'melodizer', selectedMusic.melodizer);
        })} class="fas fa-search" style={{ color: 'magenta' }}></i>
      </td>
    );
  if (column.path === "name" || column.path === "hag1") {
    return (
      <td
        style={{ fontSize: parseInt(fontSize) }}
      >
        {renderCell(item, column)}
      </td>
    );
  }
  if (column.path === "nothing")// path:출석
    return <td></td>


  if (column.path === "musicBook" || column.path === "musicTitle" || column.path === "melodizer") {
    if (item.edit) {
      return (
        <td>
          <EditTd
            path={column.path}
            itemId={item._id}
            item={renderCell(item, column)}
            onChanged={onChanged}
          />
        </td>
      );
    }
    else {
      if (column.path === undefined) return null;
      returnTd('musicTitle')//곡명
      returnTd('melodizer')//작곡자
      returnTd('musicBook')//교재
      return (
        <td
          style={{ fontSize: parseInt(fontSize) }}
          onClick={() => handleClick(item, column.path)}
        >
          {renderCell(item, column)}
        </td>
      );
    }
  }
  return <td></td>

}
const EditTd = ({ item, itemId, path, onChanged }) => {
  const [item1, setItem] = useState(item)
  const handleChange = ({ currentTarget }) => {
    setItem(currentTarget.value)
    onChanged(itemId, path, currentTarget.value);
  };
  return (
    <input
      value={item1}
      name="item"
      type="text"
      className="form-control mb-2"
      id="inlineFormInput"
      placeholder=""
      onChange={handleChange}
    />
  );
}