import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, Select, MenuItem } from "@material-ui/core";
import { toast } from "react-toastify";
import { MyTextField, MyRadio } from "../common/myField";
import DatePickers from "../common/datepicker";
import fileName from '../common/func2';

const CREATE_TUITION = gql`
mutation CreateTuition($InputTuition: InputTuition!) {
  createTuition(input: $InputTuition) {
    amount
    studentName
    studentId
    userName
    userId
    dueDate
    amount
    paidDate
    by
  }
}
`;

// const validationSchema = Yup.object().shape({
//   name: Yup.string()
//     .min(1, 'Too Short!')
//     .max(10, 'Too Long!')
//     .required('Required'),
//   book: Yup.string()
//     .min(1, 'Too Short!')
//     .max(20, 'Too Long!')
//     .required('Required'),
//   price: Yup.string()
//     .max(6, 'Too Long!'),
//   by: Yup.string()
//     .max(3, 'Too Long!'),

// });

const CreateTuition = ({ user }) => {
  const [createTuition, { data, loading, error }] = useMutation(CREATE_TUITION);
  const [dueDate1, setGivenDate1] = useState(new Date());
  const [dueDate, setGivenDate] = useState(JSON.stringify(new Date()).substr(1, 10));
  const [paidDate1, setPaidDate1] = useState(new Date());
  const [paidDate, setPaidDate] = useState(JSON.stringify(new Date()).substr(1, 10));
  // useEffect(() => {
  //   async function downBook() {
  //     const { data } = await getBooks();
  //     setBooks(data)
  //   }
  //   downBook();
  // }, []);

  const handleGivenDateChange = e => {
    setGivenDate(e.target.value);
    setGivenDate1(e.target.value);
  };
  const handlePaidDateChange = e => {
    setPaidDate(e.target.value);
    setPaidDate1(e.target.value);
  };
  const disabledDatePickers = () => {
    setPaidDate(null)
    return <DatePickers disabled={true} handleDateChange={handlePaidDateChange} date={paidDate} />
  }

  return (

    <div className="container">
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-sm">
          <h3>교육비장부 입력</h3>
        </div>
        <div className="col-sm">
          <NavLink className="badge badge-success m-2" to="/books" >
            교육비장부
          </NavLink>
        </div>
        <div className="col-sm">
          {fileName("createTuition.jsx", user)}
        </div>
      </div>
      <Formik
        validateOnChange={true}
        initialValues={{
          "studentName": "권도윤",
          "studentId": "5c002670ae3746539fe7516c",
          "userName": "원장쌤",
          "userId": "5bf75cfbadbb32205fdb20f8",
          // studentName: "",
          // studentId: "",
          // userName: "",
          // userId: "",
          dueDate: new Date(),
          amount: "11000000",
          paidBoolean: false,
          paidDate: null,
          by: null,
          // name: '', given: JSON.stringify(givenDate).substr(1, 10), book: '', price: '', paid: JSON.stringify(paidDate).substr(1, 10), by: '', paidBoolean: false
        }}
        // validationSchema={validationSchema}

        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // same shape as initial values
          setSubmitting(true);
          // values.given = givenDate1
          if (!values.paidBoolean)
            // values.paidDate = paidDate1
            // else 
            values.paidDate = null;
          else
            values.paidDate = paidDate1;
          values.dueDate = JSON.stringify(dueDate1).substr(1, 10);
          const a = {
            "InputTuition": {
              "studentName": values.studentName,
              "studentId": values.studentId,
              "userName": values.userName,
              "userId": values.userId,
              "dueDate": values.dueDate,
              "amount": values.amount,
              "paidDate": values.paidDate,
              "by": values.by,
            }
          }


          console.log('before', values)
          await createTuition({
            variables: a
          });

          toast.success('🦄 저장되었습니다!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            <MyTextField name="studentName" placeholder="학생이름" />
            <br />
            <MyTextField name="userName" placeholder="작성자" />
            <br />

           예정일:<DatePickers handleDateChange={handleGivenDateChange} date={dueDate} />
            <br />
            {/* <br />
            교재명:<Field
              name='book'
              type="select"
              as={Select}
            >
              {
                books.map((book, index) => {
                  return (
                    <MenuItem key={index} value={book.title}>{book.title}</MenuItem>
                  )
                })
              }
            </Field>
            <br /> */}
            <MyTextField name="amount" placeholder="교육비" />

            <br />
            납부했음:
            <Field name="paidBoolean" type="checkbox" as={Checkbox} />
            <br />
            납부일:{values.paidBoolean ?
              <DatePickers handleDateChange={handlePaidDateChange} date={paidDate} /> :
              disabledDatePickers()}

            <div>납부방법:</div>
            <MyRadio name="by" type="radio" value="카드" label="카드" />
            <MyRadio name="by" type="radio" value="현금" label="현금" />
            <MyRadio name="by" type="radio" value="입금" label="입금" />
            <br />

            <div>
              <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저 장</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
};

export default CreateTuition