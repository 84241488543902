export const today = function () {
  return (
    this.getFullYear() +
    "/" +
    (this.getMonth() + 1 < 10 ? "0" : "") +
    (this.getMonth() + 1) +
    "/" +
    (this.getDate() < 10 ? "0" : "") +
    this.getDate()
  );
};
// For the time now
export const timeNow = function () {
  let hours = this.getHours(); // - 3; // 뺄셈이 음수가 나오면 0-2:3 이런식으로 나오게된다. 수정할것.
  return (
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (this.getMinutes() < 10 ? "0" : "") +
    this.getMinutes() +
    ":" +
    (this.getSeconds() < 10 ? "0" : "") +
    this.getSeconds()
  );
};


