import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, Select, MenuItem } from "@material-ui/core";
import { toast } from "react-toastify";
import { saveBook1 } from "../../services/bookService";
import { getBooks } from "../../services/sellBookService";
import { MyTextField, MyRadio } from "../common/myField";
import DatePickers from "../common/datepicker";
import fileName from '../common/func2';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Too Short!')
    .max(10, 'Too Long!')
    .required('Required'),
  book: Yup.string()
    .min(1, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  price: Yup.string()
    .max(6, 'Too Long!'),
  by: Yup.string()
    .max(3, 'Too Long!'),

});

export const RecordBook = ({ user }) => {
  const [books, setBooks] = useState([])
  const [givenDate1, setGivenDate1] = useState(new Date());
  const [givenDate, setGivenDate] = useState(JSON.stringify(new Date()).substr(1, 10));
  const [paidDate1, setPaidDate1] = useState(new Date());
  const [paidDate, setPaidDate] = useState(JSON.stringify(new Date()).substr(1, 10));
  useEffect(() => {
    async function downBook() {
      const { data } = await getBooks();
      setBooks(data)
    }
    downBook();
  }, []);

  const handleGivenDateChange = e => {
    setGivenDate(e.target.value);
    setGivenDate1(e.target.value);
  };
  const handlePaidDateChange = e => {
    setPaidDate(e.target.value);
    setPaidDate1(e.target.value);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <h3>교재장부 입력</h3>
        </div>
        <div className="col-sm">
          <NavLink className="badge badge-success m-2" to="/books" >
            교재장부
          </NavLink>
        </div>
        <div className="col-sm">
          {fileName("recordBook.jsx", user)}
        </div>
      </div>
      <Formik
        validateOnChange={true}
        initialValues={{
          name: '', given: JSON.stringify(givenDate).substr(1, 10), book: '', price: '', paid: JSON.stringify(paidDate).substr(1, 10), by: '', paidBoolean: false
        }}
        //todo: Let textbook select automatically display the amount. Show the amount and let it be selected at the same time
        validationSchema={validationSchema}

        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // same shape as initial values
          setSubmitting(true);
          values.given = givenDate1
          if (values.paidBoolean)
            values.paid = paidDate1
          else values.paid = '';

          await saveBook1(values);
          toast.success('🦄 저장되었습니다!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            <MyTextField name="name" placeholder="이름" />
            <br />

            지급일:<DatePickers handleDateChange={handleGivenDateChange} date={givenDate} />

            <br />
            교재명:<Field
              name='book'
              type="select"
              as={Select}
            >
              {
                books.map((book, index) => {
                  return (
                    <MenuItem key={index} value={book.title}>{book.title}</MenuItem>
                  )
                })
              }
            </Field>
            <br />
            <MyTextField name="price" placeholder="금액" />

            <br />
            납부했음:
            <Field name="paidBoolean" type="checkbox" as={Checkbox} />
            <br />
            납부일:{values.paidBoolean ?
              <DatePickers handleDateChange={handlePaidDateChange} date={paidDate} /> : null}

            <div>납부방법:</div>
            <MyRadio name="by" type="radio" value="카드" label="카드" />
            <MyRadio name="by" type="radio" value="현금" label="현금" />
            <MyRadio name="by" type="radio" value="입금" label="입금" />
            <br />

            <div>
              <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary" >저 장</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
};