import React, { Component } from "react";
import { renderCell, createKey } from "./func7";

class TableBody extends Component {
  state = { changedItem: {} };




  handleClick = (item, changedItem) => {
    this.props.onEditMode(item, changedItem);
  };

  handleChanged = changedItem => {
    this.props.onChanged(changedItem);
  };

  render() {
    const { data, columns, onDelete } = this.props;

    return (
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            {columns.map(column => {
              if (!item.edit) {
                if (column.path === undefined) return null;
                else
                  return (
                    <td
                      key={createKey(item, column)}
                      onClick={() => this.handleClick(item, column.path)}
                    >
                      {/* {`item:${JSON.stringify(item)}`}
                {`column:${JSON.stringify(column)}`} */}
                      {renderCell(item, column)}
                    </td>
                  );
              }
              if (item.edit) {
                return (
                  <td
                    key={createKey(item, column)}
                  // onClick={() => this.handleClick(item, column.path)}
                  >
                    <EditTd
                      path={column.path}
                      itemId={item._id}
                      item={renderCell(item, column)}
                      onChanged={(id, path, value) =>
                        this.props.onChanged(id, path, value)
                      }
                      onDelete={book => onDelete(book)}
                    />
                  </td>
                );
              }
              return null
            })}
          </tr>
        ))}
      </tbody>
    );
  }
}

class EditTd extends Component {
  state = { item: this.props.item, path: this.props.path };
  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.props.onChanged(this.props.itemId, this.props.path, input.value);
    this.setState(state);
  };
  render() {
    const { item, path } = this.state;
    if (path === undefined)
      return (
        <button
          onClick={() => this.props.onDelete(this.props.itemId)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      );
    else
      return (
        <input
          value={item}
          name="item"
          type="text"
          className="form-control mb-2"
          id="inlineFormInput"
          placeholder=""
          onChange={this.handleChange}
        />
      );
  }
}

export default TableBody;
