import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import auth from "../../services/authService";
import Table from "../common/table3";

class BooksTable extends Component {
  columns = [
    { path: "given", label: "지급일" },
    { path: "name", label: "이름" },
    { path: "book", label: "교재" },
    { path: "price", label: "가격" },
    { path: "paid", label: "납부일" },
    { path: "by", label: "납부방법" }
  ];

  deleteColumn = {
    key: "delete",
    content: book => (
      <button
        onClick={() => this.props.onDelete(book)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const {
      books,
      onSort,
      sortColumn,
      onChanged,
      onDelete,
      onEditMode
    } = this.props;
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div>
            <div className="badge badge-info">pay/booksTable.jsx</div>
          </div>
        )}
        <Table
          columns={this.columns}
          data={books}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table"
          onEditMode={(item, path) => onEditMode(item, path)}
          onChanged={(id, path, value) => onChanged(id, path, value)}
          onDelete={book => onDelete(book)}
        />
      </>
    );
  }
}

export default withRouter(BooksTable);
