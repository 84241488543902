import React, { Component } from "react";
import fileName from "../common/func2";
import { btnStyle1 } from "../common/setting";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import BooksTable from "./booksTable";
import Pagination from "../common/pagination";
import {
  getBooks,
  deleteBook,
  saveBook,
  saveBooksAsExcel,
  readExcelAsBooks
} from "../../services/bookService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import authService from "../../services/authService";
import Ibtn from '../common/i'

class Books extends Component {
  state = {
    books: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };
  user = authService.getCurrentUser();

  async componentDidMount() {
    const { data: books } = await getBooks();
    this.setState({ books });
  }

  handleDelete = async id => {
    const originalBooks = this.state.books;
    const books = originalBooks.filter(m => m._id !== id);
    this.setState({ books });

    try {
      await deleteBook(id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This book has already been deleted.");
    }
  };

  // handleSave = async book => {
  //   const originalBooks = this.state.books;
  //   const books = originalBooks.filter(m => m._id !== book._id);
  //   this.setState({ books });

  //   try {
  //     await deleteBook(book._id);
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404)
  //       toast.error("This book has already been deleted.");

  //     this.setState({ books: originalBooks });
  //   }
  // };

  handleChanged = (id, path, value) => {
    const books = [...this.state.books];
    const ids = books.map(b => b._id);
    const index = ids.indexOf(id);
    books[index] = { ...books[index] };
    books[index][path] = value;
    this.setState({ books });
  };

  handleLike = book => {
    const books = [...this.state.books];
    const index = books.indexOf(book);
    books[index] = { ...books[index] };
    books[index].liked = !books[index].liked;
    this.setState({ books });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      books: allBooks
    } = this.state;
    let filtered = allBooks;
    if (searchQuery)
      filtered = allBooks.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
        // m.이름 !== null &&
        // ((searchQuery.length === 1 &&
        //   m.이름.substring(0, 1) === searchQuery) ||
        //   (searchQuery.length === 2 &&
        //     m.이름.substring(0, 2) === searchQuery) ||
        //   (searchQuery.length === 3 &&
        //     m.이름.substring(0, 3) === searchQuery))
      );
    // else if (selectedGenre && selectedGenre._id)
    //   filtered = allMovies.filter(m => m.genre._id === selectedGenre._id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const books = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: books };
  };
  handleEditMode = (item, changedItem) => {
    let books = [...this.state.books];
    const index = _.findIndex(this.state.books, item);
    books.map(m => {
      if (m.edit) {
        delete m.edit;
        saveBook(m);
      }
      return null
    });
    books[index].edit = true;
    this.setState({ books });
  };
  // handleNewClick = async () => {
  //   await saveBook({ name: "" });
  //   this.componentDidMount();
  // };
  saveExcel = async () => {
    await saveBooksAsExcel();
  };
  readExcel = async () => {
    await readExcelAsBooks();
  };
  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: books } = this.getPagedData();
    return (
      <>
        {fileName("books.jsx", this.user)}
        {this.user && this.user.isAdmin && (
          <>
            <button
              className="btn btn-primary mr-2"
              onClick={() => this.saveExcel()}
            >
              엑셀저장
            </button>
            <button
              className="btn btn-primary mr-2"
              onClick={() => this.readExcel()}
            >
              엑셀읽기
            </button>
          </>
        )}
        <button
          className="btn btn-primary mr-2"
          onClick={() => { this.props.history.push("/recordBook"); }}

        >
          새교재 배부
        </button>
        <Ibtn icon="fas fa-chalkboard-teacher fa-lg" onClick={() => {
          this.props.history.push("/Chuls5");
        }} style={btnStyle1}
        />
        <SearchBox value={searchQuery} onChange={this.handleSearch} />
        <BooksTable
          user={this.user}
          books={books}
          sortColumn={sortColumn}
          onLike={this.handleLike}
          onDelete={book => this.handleDelete(book)}
          onSave={this.handleSave}
          onSort={this.handleSort}
          onEditMode={(item, changedItem) =>
            this.handleEditMode(item, changedItem)
          }
          onChanged={(id, path, value) => this.handleChanged(id, path, value)}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </>
    );
  }
}

export default withRouter(Books);
