//import { socket } from "./socket";
import moment from "moment";
import _ from "lodash";
import { setAllFields } from "../components/common/ridesFunc";

export default function socketOnFuncs(scopeThis, socket) {
  return function (err) {
    socket.on("downAll", () => scopeThis.dataDown());
    socket.on("updateUserList", function (users) {
      scopeThis.setState({
        users
      });
    });
    socket.on("newStatus", params => {
      let chuls = [...scopeThis.state.chuls];
      let index = _.findIndex(chuls, { _id: params._id });
      chuls[index].status = params.status;
      scopeThis.setState({ chuls });
    });
    socket.on("rides", params => {
      let chuls = [...scopeThis.state.chuls];
      let index = _.findIndex(chuls, { _id: params._id });
      setAllFields(chuls[index], params);
      scopeThis.setState({ chuls });
    });
    socket.on("newMessage", message => {
      var formattedTime = moment(message.createdDate).format("h:mm a");
      let newMsg = {
        text: message.text,
        from: message.from,
        room: message.room,
        createdDate: formattedTime
      };
      let results = scopeThis.state.messages;
      results.push(newMsg);
      scopeThis.setState({
        messages: results
      });

      var msgArr = scopeThis.state.messages.filter(
        message => message.room === scopeThis.props.match.params.room
      );
      if (msgArr.length > 3) {
        scopeThis.scrollToBottom();
      }
    });
    socket.on("createLocationMsg", message => {
      var formattedTime = moment(message.createdDate).format("h:mm a");
      let newMsg = {
        url: message.url,
        from: message.from,
        room: message.room,
        createdDate: formattedTime
      };
      let results = scopeThis.state.messages;
      results.push(newMsg);
      scopeThis.setState({
        messages: results,
        fetchingLocation: false
      });
    });
    socket.on("disconnect", function () {
      console.log("Connection lost from server.");
    });
  }
}