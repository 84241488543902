import React from "react";
import { HandlerConsumer } from "../new/handler";
const RemoveB = props => {
  if (!props.student.edit) {
    return (
      <HandlerConsumer>
        {handler => (
          <button
            className="btn btn-info ml-1 mr-1"
            key={`X`}
            onClick={() => {
              handler.onRemoveButton({
                student: props.student,
                onoff: props.onoff,
                time: props.time,
                dow: props.day
              });
            }}
          >
            {`제거`}
          </button>
        )}
      </HandlerConsumer>
    );
  }
  return null;
};

export default RemoveB;
