import _ from 'lodash'
import { isOn } from "../../utils/stat";
const { 결석 } = require("../../gma/Stats");

export const setArrayField = (student, value, fieldArray) => {
  fieldArray.forEach(field => student[field] = value[field])
};
export const setField = (student, field, value, plan = null) => {
  if (plan === null) student[field] = value;
  else student.plan[field] = value;
};
export const getField = (student, field, plan = null) => {
  if (plan === null) return student[field];
  else return student[plan][field];
};
export const makePlanObj = m => {
  return _.pick(m, ["it1", "it2", "it3", "it4", "it5", "ip1", "ip2", "ip3", "ip4", "ip5", "ot1", "ot2", "ot3", "ot4", "ot5", "op1", "op2", "op3", "op4", "op5"])
};
export const setAllFields = (student, value) => {
  setArrayField(student, value,
    ["ot1", "ot2", "ot3", "ot4", "ot5", "op1", "op2", "op3", "op4", "op5",
      "it1", "it2", "it3", "it4", "it5", "ip1", "ip2", "ip3", "ip4", "ip5",
      "ot1t", "ot2t", "ot3t", "ot4t", "ot5t", "op1t", "op2t", "op3t", "op4t", "op5t",
      "it1t", "it2t", "it3t", "it4t", "it5t", "ip1t", "ip2t", "ip3t", "ip4t", "ip5t",
      "todayInRide", "todayOutRide"]);
};

export const getAllFields = student => {
  return _.pick(student, [
    'ot1', 'ot2', 'ot3', 'ot4', 'ot5', 'op1', 'op2', 'op3', 'op4', 'op5',
    'it1', 'it2', 'it3', 'it4', 'it5', 'ip1', 'ip2', 'ip3', 'ip4', 'ip5',
    'ot1t', 'ot2t', 'ot3t', 'ot4t', 'ot5t', 'op1t', 'op2t', 'op3t', 'op4t', 'op5t',
    'it1t', 'it2t', 'it3t', 'it4t', 'it5t', 'ip1t', 'ip2t', 'ip3t', 'ip4t', 'ip5t',
    'todayInRide', 'todayOutRide'
  ]);
};
export const getParams = (user, student) => {
  return {
    user: user.name,
    name: student.name,
    _id: student._id,
    ...getAllFields(student)
  };
};
export const setTime = ({ day, onoff, time, student }) => {
  if (onoff === null) return null
  let field = onoff === 'on' ? 'i' : onoff === 'off' ? 'o' : null
  field = field + 't'
  field = field + day
  student[field] = time
};
export const howMany = ({ chuls, day, m }) => {
  // let total = chuls.filter(s => {
  //   let field = m.onoff === 'in' ? 'i' : m.onoff === 'out' ? 'o' : null
  //   field = field + 't'
  //   field = field + day
  //   if (s[field] === m.time) return m
  //   return null
  // }).length
  let ride = chuls.filter(s => {
    let field = m.onoff === 'in' ? 'i' : m.onoff === 'out' ? 'o' : null
    field = field + 't'
    field = field + day
    let todayRide = true
    if (m.onoff === 'in' && s.todayInRide !== undefined && s.todayInRide !== null) { todayRide = s.todayInRide }
    if (m.onoff === 'out' && s.todayOutRide !== undefined && s.todayOutRide !== null) { todayRide = s.todayOutRide }
    // (등/하원차오늘탐/안탐)==> todayInRide,todayOutRide
    if (s[field] === m.time && todayRide && !isOn(s, 결석)) return m
    return null
  }).length;
  return `${ride}`
  // return `${ride}/${total}`
};
