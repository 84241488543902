import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class PaysTable extends Component {
  columns = [
    {
      path: "name",
      label: "이름",
      content: student => (
        <Link to={`/pays/${student._id}`}>{student.name}</Link>
      )
    },
    { path: "pianoBan", label: "피아노반" },
    { path: "hag", label: "학년" },
    { path: "payday", label: "결제일" },
    { path: "fee", label: "교육비" }
  ];

  deleteColumn = {
    key: "delete",
    content: student => (
      <button
        onClick={() => this.props.onDelete(student)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { students, onSort, sortColumn } = this.props;

    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div>
            <div className="badge badge-info">pay/paysTable111.jsx</div>
          </div>
        )}
        <Table
          columns={this.columns}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table"
        />
      </>
    );
  }
}

export default withRouter(PaysTable);
