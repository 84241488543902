import React from "react";
import Student from "./student";

export default class Students extends React.Component {
  constructor(students, props) {
    super(props);
    this.s = students;
    this.students = [];
    for (let s of students) {
      this.students.push(new Student(s));
    }
  }

  showAllStudents(car) {
    let a = [];
    for (let s of this.students) {
      a.push(s.showButton(car));
    }
    return a;
  }
}
