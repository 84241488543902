import React from "react";
import NameButton from "./nameButton";
import OtherButtons from "./otherButtons";

const ExButton = props => {
  return (
    <>
      <NameButton {...props} />
      <OtherButtons {...props} />
    </>
  );
};

export default ExButton;
