import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { 버튼표시 } from "./chulsFunc";

export const AnimatedButton = ({ b, handleClick, children }) => {
  const [state, toggle] = useState(true);
  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 1000 }
  });
  return (
    <div
      className={String(버튼표시(b)).replace('mr-2 mb-2 p-1', ' ')}
      key={b._id}
      onClick={() => {
        toggle(!state);
        //handleClick(b);
      }}
      style={
        버튼표시(b).includes("secondary") // 오늘결석
          ? {
            color: '#000000',
            backgroundColor: "#cfcfcf", borderRadius: '25px',
            border: '2px solid #969696',
            padding: '2px',
            paddingRight: '12px',
            paddingLeft: '12px',
            margin: '2px'
          }
          : 버튼표시(b).includes("success") // 레슨종료
            ? {
              color: '#000000',
              backgroundColor: "#8cffc2", borderRadius: '25px',
              border: '2px solid #63ab00',
              padding: '2px',
              margin: '2px'
            }
            : 버튼표시(b).includes("danger") // 레슨대기
              ? {
                color: '#000000',
                backgroundColor: "#ffcece", borderRadius: '25px',
                border: '2px solid #ff3ca3',
                padding: '2px',
                margin: '2px'
              }
              : 버튼표시(b).includes("primary") // 레슨중
                ? {
                  color: '#000000',
                  backgroundColor: "#bfbdff", borderRadius: '25px',
                  border: '2px solid #874FFF',
                  padding: '2px',
                  margin: '2px'
                }
                : 버튼표시(b).includes("light")  // 귀가
                  ? {
                    color: '#000000',
                    backgroundColor: "#fffbd4", borderRadius: '25px',
                    border: '2px solid #ffde00',
                    padding: '2px',
                    paddingRight: '12px',
                    paddingLeft: '12px',
                    margin: '2px'
                  }
                  : 버튼표시(b).includes("warning") // 등원예정
                    ? {
                      color: '#000000',
                      backgroundColor: "#f7dc6f",
                      borderRadius: '25px',
                      border: '2px solid #d2b700',
                      padding: '2px',
                      paddingRight: '12px',
                      paddingLeft: '12px',
                      margin: '2px'
                    }
                    : null
      }
    >
      <animated.div
        style={{
          transform: x
            .interpolate({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
            })
            .interpolate(x => `scale(${x})`)
        }}
      >
        {children}
      </animated.div>
    </div>
  );
};


