import React, { Component } from "react";
import TBody from './bookChartBody'
import MonthBar from './monthBar'

export default class Table extends Component {
  render() {
    // todo: 교재 색표시 안됨. 데이타는 제대로 오고 있는데 새해가 되어서 끝나는 날짜가 문제인거 같음.
    return (
      <table className="table table-bordered">
        {/* It becomes fixed but does not move horizontally. */}
        {/* <thead style={{
          position: 'fixed',
          zIndex: 1
        }}> */}
        <thead >
          <tr>
            <th style={{ minWidth: 70, paddingTop: 0, paddingBottom: 0 }}>이름</th>
            <th style={{ minWidth: 183, paddingTop: 0, paddingBottom: 0 }}>교재</th>
            <td style={{ minWidth: 1500, paddingTop: 0, paddingBottom: 0 }}>
              <MonthBar
                dates={this.props.dates}
                bookColor={this.props.bookColor}
              />
            </td>
            <td style={{ minWidth: 500, paddingTop: 0, paddingBottom: 0 }} />
          </tr>
        </thead>
        <tbody>
          {/* <tr style={{ height: 74 }}>
            <th style={{ minWidth: 70 }}></th>
            <td style={{ minWidth: 183 }}></td>
            <td style={{ minWidth: 1500 }}></td>
            <td />
          </tr> */}
          <TBody
            students={this.props.students}
            studentBooks={this.props.studentBooks}
            studentBooks1={this.props.studentBooks}
          />
        </tbody>
      </table>
    );
  }
}