import React from "react";
import Badge from "../../story_comps/atoms/badge/badge";
import Filename from '../common/filename';

const Counter = ({ sumBoard }) => {
  return (<>
    {Filename('counter.jsx')}
    {/* 출석: */}
    <Badge size={'1'} counter style={{ backgroundColor: "#ffcece" }}>
      {(sumBoard.attend[0] + ":" + sumBoard.attend[1])} {/*+ (count ? ', ' : '. ')} */}
    </Badge>
    {/* 미등원 학생: */}
    <Badge size={'1'} counter style={{ backgroundColor: "#f7dc6f" }} >
      {sumBoard.unattend[0] + ":" + sumBoard.unattend[1]}
    </Badge>
    {/* 현재 학원에 있는 학생: */}
    <Badge size={'1'} counter style={{ backgroundColor: "#8cffc2" }} >
      {sumBoard.in[0] + ":" + sumBoard.in[1]}
    </Badge>
    {/* 하원: */}
    <Badge size={'1'} counter style={{ backgroundColor: "#fffbd4" }}>
      {sumBoard.goHome[0] + ":" + sumBoard.goHome[1]}
    </Badge>
    {/* 결석: */}
    <Badge size={'1'} counter style={{ backgroundColor: "#cfcfcf" }} >
      {sumBoard.absent[0] + ":" + sumBoard.absent[1]}
    </Badge>
  </>)
}
export default Counter

