import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody5";

const Table = ({ columns, sortColumn, onSort, data, className }) => {
  return (
    <table className={className}>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} />
    </table>
  );
};

// const TableBody1 = ({ columns, data }) => {
//   return <TableBody columns={columns} data={data} />;
//    <TableBody columns={columns} data={data} />;
// };

export default Table;
