import React, { Component } from "react";
import { RadioGroup, RadioButton } from "react-radio-buttons";

class EvalRadioGroup extends Component {
  handleChange = e => {
    this.props.onChange(this.props.id, e);
  };

  render() {
    return (
      <tr>
        <th>
          <span className="badge badge-warning">{this.props.name}</span>
        </th>
        <td>
          <RadioGroup
            id={this.props.id}
            value={this.props.value}
            onChange={this.handleChange}
            horizontal
          >
            <RadioButton padding={5} id="1" value="1">
              매우좋음
            </RadioButton>
            <RadioButton padding={5} id="2" value="2">
              좋음
            </RadioButton>
            <RadioButton padding={5} id="3" value="3">
              보통
            </RadioButton>
            <RadioButton padding={5} id="4" value="4">
              노력요함
            </RadioButton>
          </RadioGroup>
        </td>
      </tr>
    );
  }
}

export default EvalRadioGroup;
