import React, { Component } from "react";
import Table from "../common/table10";

class StudentAllCsTable extends Component {
  columns = [
    {
      path: "name",
      label: "이름",
      minWidth: 40
    },
    { path: "schoolShort", label: "학교", minWidth: 40 },
    { path: "hag", label: "학년", minWidth: 10 },
    { path: "ban", label: "반", minWidth: 10 },
    { path: "otherBan", label: "", minWidth: 10 },
    { path: "attendNum", label: "참고", minWidth: 10 },
    { path: "it1", label: "월", minWidth: 20 },
    { path: "it2", label: "화", minWidth: 20 },
    { path: "it3", label: "수", minWidth: 20 },
    { path: "it4", label: "목", minWidth: 20 },
    { path: "it5", label: "금", minWidth: 20 },
    { path: "ot1", label: "월", minWidth: 20 },
    { path: "ot2", label: "화", minWidth: 20 },
    { path: "ot3", label: "수", minWidth: 20 },
    { path: "ot4", label: "목", minWidth: 20 },
    { path: "ot5", label: "금", minWidth: 20 }
  ];

  deleteColumn = {
    key: "delete",
    content: student => (
      <button
        onClick={() => this.props.onDelete(student)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };
  render() {
    const { students, onSort, sortColumn, lessons } = this.props;
    return (
      <>
        {/* {this.props.user && this.props.user.isAdmin && (
          <div>
              <code>students/studentAllCsTable.jsx</code>
          </div>
        )} */}
        <Table
          columns={this.columns}
          lessons={lessons}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table table-bordered table-striped mx-0 p-0 "
          style={{ marginTop: "-3rem" }}
        />
      </>
    );
  }
}

export default StudentAllCsTable;
