import React from "react";
import Badge from "../../story_comps/atoms/badge/badge";
import Filename from '../common/filename';
import { gql, useQuery } from '@apollo/client';

const SUM_BOARD = gql`
query SumBoard{
  sumBoard{
    attend
    unattend
    in
    goHome
    absent
  }
}
`;

const Counter = ({ user, badgeSize, counter }) => {
  const { loading, error, data } = useQuery(SUM_BOARD, { fetchPolicy: 'network-only' });
  if (user && user.isPrincipal) {
    if (loading) return <p>읽는중...</p>;
    if (error) return <p>에러 :(</p>;
    if (counter !== null) {
      // console.log('counter', counter)
      return (<>
        {Filename('counter.jsx')}
        {/* 출석: */}
        <Badge size={badgeSize} counter style={{ backgroundColor: "#ffcece" }}>
          {(counter.attend[0] + ":" + counter.attend[1])} {/*+ (count ? ', ' : '. ')} */}
        </Badge>
        {/* 미등원 학생: */}
        <Badge size={badgeSize} counter style={{ backgroundColor: "#f7dc6f" }} >
          {counter.unattend[0] + ":" + counter.unattend[1]}
        </Badge>
        {/* 현재 학원에 있는 학생: */}
        <Badge size={badgeSize} counter style={{ backgroundColor: "#8cffc2" }} >
          {counter.in[0] + ":" + counter.in[1]}
        </Badge>
        {/* 하원: */}
        <Badge size={badgeSize} counter style={{ backgroundColor: "#fffbd4" }}>
          {counter.goHome[0] + ":" + counter.goHome[1]}
        </Badge>
        {/* 결석: */}
        <Badge size={badgeSize} counter style={{ backgroundColor: "#cfcfcf" }} >
          {counter.absent[0] + ":" + counter.absent[1]}
        </Badge>
      </>)
    }
    return (<>
      {Filename('counter.jsx')}
      {/* 출석: */}
      <Badge size={badgeSize} counter style={{ backgroundColor: "#ffcece" }}>
        {(data.sumBoard.attend[0] + ":" + data.sumBoard.attend[1])} {/*+ (count ? ', ' : '. ')} */}
      </Badge>
      {/* 미등원 학생: */}
      <Badge size={badgeSize} counter style={{ backgroundColor: "#f7dc6f" }} >
        {data.sumBoard.unattend[0] + ":" + data.sumBoard.unattend[1]}
      </Badge>
      {/* 현재 학원에 있는 학생: */}
      <Badge size={badgeSize} counter style={{ backgroundColor: "#8cffc2" }} >
        {data.sumBoard.in[0] + ":" + data.sumBoard.in[1]}
      </Badge>
      {/* 하원: */}
      <Badge size={badgeSize} counter style={{ backgroundColor: "#fffbd4" }}>
        {data.sumBoard.goHome[0] + ":" + data.sumBoard.goHome[1]}
      </Badge>
      {/* 결석: */}
      <Badge size={badgeSize} counter style={{ backgroundColor: "#cfcfcf" }} >
        {data.sumBoard.absent[0] + ":" + data.sumBoard.absent[1]}
      </Badge>
    </>)
  }
  return null
}
export default Counter

