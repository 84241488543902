import React, { Component } from "react";
import { fieldSorter, chunkArray } from "../common/func1";
import { filter } from "./chulsFunc";
export class NameList extends Component {
  render() {
    let students = [...this.props.students];
    students = filter(
      students,
      this.props.day,
      this.props.onoff,
      this.props.time
    );
    students = students.sort(fieldSorter(["name"]));
    let searchStudent1 = this.props.searchQuery.split(" ");
    return (
      <>
        {students.map(student => (
          <>
            {searchStudent1.includes(student.name) && (
              <button
                className="btn btn-warning p-0 m-0 mr-1"
                onClick={() => this.props.onConfirmRemove(student)}
              >
                {student.name}
              </button>
            )}
            {!searchStudent1.includes(student.name) && (
              <button
                className="btn btn-light p-0 m-0 mr-1"
                onClick={() => this.props.onConfirmRemove(student)}
              >
                {student.name}
              </button>
            )}
          </>
        ))}
      </>
    );
  }
}

export class NameList1 extends Component {
  render() {
    let students = [...this.props.students];
    students = filter(
      students,
      this.props.day,
      this.props.onoff,
      this.props.time
    );
    students = students.sort(fieldSorter(["name"]));
    let students1학년 = [...students];
    students1학년 = students1학년.filter(m => m.hag === 1);
    students1학년 = students1학년.sort(fieldSorter(["ban", "name"]));
    let students그밖에 = [...students];
    students그밖에 = students그밖에.filter(m => m.hag !== 1);
    let students1 = [...students1학년, ...students그밖에];
    students =
      (this.props.day === 1 && this.props.time === "1:40") ||
        (this.props.day === 2 && this.props.time === "1:40") ||
        (this.props.day === 3 && this.props.time === "12:40") ||
        (this.props.day === 4 && this.props.time === "1:40") ||
        (this.props.day === 5 && this.props.time === "12:40")
        ? students1
        : students;

    students = chunkArray(students, 3);

    return students.map(s => (
      <div>
        <span>{s[0].name + " "}</span>
        <span>{s[1] && s[1].name + " "}</span>
        <span>{s[2] && s[2].name + " "}</span>
      </div>
    ));
  }
}
