import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import unregisterServiceWorker from "./registerServiceWorker";
import logger from "./services/logService";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    // uri: 'http://localhost:3900/graphql',
    uri: process.env.REACT_APP_GRAPHQL_URL,
  })
});

logger.init();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router >
  </ApolloProvider>,
  document.getElementById("root")
);
unregisterServiceWorker();
