import React from "react";
import TableHeader from "../../common/tableHeader";
import { TableBody } from "./tableBody";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  className,
  onChanged,
  onChanged1,
  onFindMusic,
  onEditMode,
  fontSize,
  onWinOpen,
  selectedMusic
}) => {
  return (
    <table className={className}>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody
        columns={columns}
        fontSize={fontSize}
        data={data}
        onFindMusic={(_id, path) => onFindMusic(_id, path)}
        onEditMode={onEditMode}
        onChanged={onChanged}
        onChanged1={onChanged1}
        onWinOpen={onWinOpen}
        selectedMusic={selectedMusic}
      />
    </table>
  );
};

export default Table;
