import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import { socketOnFuncs1 as socketOnFuncs, getStudents, getPianos, getLessonsWithDate } from "../../services/index1";
import { getDayOfWeek } from "../../utils/func";
import { cutClass, initStat, evaluate, getWeekAgo, getToday, Filename, MakeButtons, Loading } from "../common/index1";
import { handleClick1 as handleClick, ch, handleSetDay, menuModal, showPianoModal, handleOpenWin, Monitor1, SwitchBoard } from "./index1";
import { ShowBan1, Counter1, BanName } from "../common/index1";
import { Board1 } from "./index1";
import { gql, useQuery } from '@apollo/client';

const LESSON_SCR = gql`
query LessonScr($LessonScrInput: LessonScrInput) {
  lessonScr(input: $LessonScrInput) {
    alarmBoard {
      time
      place
      name
    }
    classify {
      lessoning {
        _id
        name
        status
        onTime
        pianoBan
      }
      waitLesson {
        _id
        name
        status
        onTime
        pianoBan
      }
      lessonEnd {
        _id
        name
        status
        onTime
        pianoBan
      }
      willCome {
        _id
        name
        status
        onTime
        pianoBan
      }
      wentHome {
        _id
        name
        status
        onTime
        pianoBan
      }
      absent {
        _id
        name
        status
        onTime
        pianoBan
      }
    }
    sumBoard{
      attend
      unattend
      in
      goHome
      absent
    }
  }
}
`;

const Chuls = ({ socket, user }) => {
  const [showPic, setShowPic] = useState(false)
  const [showAjNum, setShowAjNum] = useState(false)
  const [showTel, setShowTel] = useState(false)

  const { loading, error, data } = useQuery(LESSON_SCR, {
    variables: { LessonScrInput: { day: '1', order: '' } }, fetchPolicy: 'network-only'
  });
  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;
  const { alarmBoard, classify, sumBoard } = data.lessonScr

  const ban = {
    showPic: showPic,
    showAjNum: showAjNum,
    onOpenWin: b => handleOpenWin({ student: b, thisScope: this }),
    showTel: showTel,
  };
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      {Filename('chuls/chuls6.jsx')}
      <Board1 alarmBoard={alarmBoard} />
      {/* <BanName /> */}
      <ShowBan1 ban={ban} classify={classify} socket={socket} user={user} />
      <Counter1 sumBoard={sumBoard} />
    </>
  );
}

export default withRouter(Chuls);
