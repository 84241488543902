import React, { Component } from "react";
import Filename from './filename';

class Options extends Component {
  render() {
    const { ch, thisScope } = this.props;
    const {
      showHak: class_showHak,
      showTime: class_showTime,
      order
    } = thisScope.state;
    const user = thisScope.user;
    let badge = "badge badge-pill m-2 badge-";
    let class_nameOrder =
      order === "name" ? badge + "warning" : badge + "light";
    let class_timeOrder =
      order === "time" ? badge + "warning" : badge + "light";
    let class_hakOrder = order === "hak" ? badge + "warning" : badge + "light";
    let class_showTime1 = class_showTime ? badge + "warning" : badge + "light";
    let class_showHak1 = class_showHak ? badge + "warning" : badge + "light";
    let style_nameOrder =
      order === "name" ? { backgroundColor: "#f7dc6f" } : {};
    let style_timeOrder =
      order === "time" ? { backgroundColor: "#f7dc6f" } : {};
    let style_hakOrder = order === "hak" ? { backgroundColor: "#f7dc6f" } : {};
    let style_showTime1 = class_showTime ? { backgroundColor: "#f7dc6f" } : {};
    let style_showHak1 = class_showHak ? { backgroundColor: "#f7dc6f" } : {};
    return (
      <>
        {Filename('common/options.jsx')}

        <div>
          <span className="col-5 bg-success">
            <button
              type="button"
              className={class_nameOrder}
              style={style_nameOrder}
              onClick={() => ch.onNameOrder(thisScope)}
            >
              이름순
            </button>
            <button
              type="button"
              className={class_timeOrder}
              style={style_timeOrder}
              onClick={() => ch.onTimeOrder(thisScope)}
            >
              시간순
            </button>
            <button
              type="button"
              className={class_hakOrder}
              style={style_hakOrder}
              onClick={() => ch.onHakOrder(thisScope)}
            >
              학년순
            </button>
          </span>
          <span className="bg-light"> </span>
          <span className="bg-secondary">
            <button
              type="button"
              className={class_showTime1}
              style={style_showTime1}
              onClick={() => ch.onShowTime(thisScope)}
            >
              시간
            </button>
            <button
              type="button"
              className={class_showHak1}
              style={style_showHak1}
              onClick={() => ch.onShowHak(thisScope)}
            >
              학년
            </button>
          </span>
        </div>
      </>
    );
  }
}

export default Options;
