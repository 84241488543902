import http from "./httpService";

const apiEndpoint = "/sellbooks";
//const apiEndpoint = "/dev";

export function getBooks() {
  console.log('getBooks');
  return http.get(apiEndpoint);
}

