import React from "react";
import Joi from "joi-browser";
import Form from "../common/form2";
import SavePic from '../common/savePic'
import { getStudent, saveStudent } from "../../services/studentService";

class StudentForm extends Form {
  state = {
    data: {
      _id: "",
      name: "",
      otherBan: "",
      pianoBan: "",
      apt: "",
      dob: "",
      ajNum: "",
      payday: "",
      fee: "",
      hag: "",
      ban: "",
      age: "",
      address: "",
      sex: "",
      schoolLong: "",
      schoolShort: "",
      attend: "",
      attendNum: "",
      eduDetail: "",
      busDetail: "",
      offDetail: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string().allow(null, ""),
    name: Joi.string()
      .required()
      .label("이름"),
    pianoBan: Joi.string().allow(null, ""),
    otherBan: Joi.string().allow(null, ""),
    apt: Joi.string()
      .allow(null, "")
      .label("아파트"),
    dob: Joi.string().allow(null, ""),
    ajNum: Joi.string().allow(null, ""),
    payday: Joi.number().allow(null, ""),
    fee: Joi.number().allow(null, ""),
    // skip line
    hag: Joi.number().allow(null, ""),
    ban: Joi.number().allow(null, ""),
    age: Joi.number().allow(null, ""),
    address: Joi.string().allow(null, ""),
    sex: Joi.string().allow(null, ""),
    schoolLong: Joi.string().allow(null, ""),
    schoolShort: Joi.string().allow(null, ""),
    attend: Joi.string().allow(null, ""),
    attendNum: Joi.string().allow(null, ""),
    eduDetail: Joi.string().allow(null, ""),
    busDetail: Joi.string().allow(null, ""),
    offDetail: Joi.string().allow(null, ""),
    profileImage: Joi.string().allow(null, ""),
  };

  async populateStudent() {
    try {
      const studentId = this.props.match.params.id;
      if (studentId === "new") return;

      const { data: student } = await getStudent(studentId);
      this.setState({ data: this.mapToViewModel(student) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateStudent();
  }

  mapToViewModel(student) {
    return {
      _id: student._id,
      name: student.name,
      otherBan: student.otherBan,
      pianoBan: student.pianoBan,
      apt: student.apt,
      dob: student.dob,
      ajNum: student.ajNum,
      payday: student.payday,
      fee: student.fee,
      hag: student.hag,
      ban: student.ban,
      age: student.age,
      address: student.address,
      sex: student.sex,
      schoolLong: student.schoolLong,
      schoolShort: student.schoolShort,
      attend: student.attend,
      attendNum: student.attendNum,
      eduDetail: student.eduDetail,
      busDetail: student.busDetail,
      offDetail: student.offDetail,
      profileImage: student.profileImage
    };
  }
  doSubmit = async () => {
    await saveStudent(this.state.data);

    this.props.history.goBack();
    // this.props.history.push("/studentAllCs");
  };
  render() {
    return (
      <>
        <div>
          <div className="badge badge-info">students/studentForm.jsx</div>
        </div>

        <h3>
          <span className="col-12 badge badge-secondary">
            학 생 정 보 입 력
          </span>
        </h3>
        <SavePic name={this.renderInput("name", "이름", "", 80)}></SavePic>
        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            {this.renderInput("name", "이름", "", 80)}
            {this.renderInput("sex", "성별", "", 40)}
            {this.renderInput("pianoBan", "피아노반", "", 80)}
            {this.renderInput("attend", "수업요일", "", 100)}
            {this.renderInput("attendNum", "일/주", "", 40)}
            {this.renderInput("otherBan", "다른악기", "", 80)}
          </div>
          <div className="form-row">
            {this.renderInput("payday", "결제일", "number", 0, "col col-2")}
            {this.renderInput("fee", "교육비", "number", 0, "col col-3")}
            {this.renderInput("ajNum", "알자번호", "", 80)}
          </div>
          <div className="form-row">
            {this.renderInput("dob", "생년월일", "", 120)}
            {this.renderInput("age", "나이", "", 50)}
          </div>
          <div className="form-row">
            {this.renderInput("schoolLong", "학교", "", 200)}
            {this.renderInput("schoolShort", "학교", "", 100)}
            {this.renderInput("hag", "학년", "", 40)}
            {this.renderInput("ban", "반", "", 40)}
          </div>
          <div className="form-row">
            {this.renderInput("apt", "아파트", "", 120)}
            {this.renderInput("address", "주소", "", 400)}
          </div>
          <div className="form-row" />
          <div className="form-row" />
          <div className="form-row">
            {this.renderInput("eduDetail", "교육 관련사항", "", 400)}
          </div>
          <div className="form-row">
            {this.renderInput("busDetail", "차량 관련사항", "", 400)}
          </div>
          <div className="form-row">
            {this.renderInput("offDetail", "출석 관련사항", "", 400)}
          </div>

          {this.renderButton("저장", "btn btn-primary")}
        </form>
      </>
    );
  }
}

export default StudentForm;
