import React from "react";
import { Day, Options } from "../common/index1";
import { MenuButtons } from "./index1";

const SwitchBoard = ({ param, scope, onSetDay, ch }) =>
  <>
    <Options thisScope={scope} ch={ch} />
    <MenuButtons param={param} />
    <Day scope={scope} onSetDay={onSetDay} />
  </>

export default SwitchBoard;