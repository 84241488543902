import http from "./httpService";

const apiEndpoint = "/students";

function studentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getStudents() {
  return http.get(apiEndpoint);
}
export function getStudentsAndOldStudents() {
  return http.get(apiEndpoint + "/witholdstudents");
}
export function getStudentsJindo() {
  return http.get(apiEndpoint + "/jindo");
}
export function getStudentsBig() {
  return http.get(apiEndpoint + "/big");
}
export function getStudentsLetter() {
  return http.get(apiEndpoint + "/letter");
}
export function getStudentsSmall() {
  return http.get(apiEndpoint + "/small");
}
export function getStudentConcert1() {
  return http.get(apiEndpoint + "/concert");
}
export function getStudentConcert() {
  return http.get(apiEndpoint + "/docu");
}
export function getDocu1() {
  return http.get(apiEndpoint + "/docu1");
}
export function getStudentsBus() {
  return http.get(apiEndpoint + "/bus");
}
export function getStudentsIot() {
  return http.get(apiEndpoint + "/iot");
}

export function getPlanTitles() {
  return http.get(apiEndpoint + "/planTitles");
}
export function saveExcelForLetter() {
  return http.get(apiEndpoint + "/toexcel");
}
export function getStudent(studentId) {
  return http.get(studentUrl(studentId));
}
export function deletePlan(plan) {
  return http.delete(apiEndpoint + "/deplan/" + plan);
}
export function saveStudent1(student) {
  if (student._id) {
    const body = { ...student };
    delete body._id;
    return http.put(studentUrl(student._id), body);
  }

  //return http.post(apiEndpoint, student); // post는 사용안하고 있음.
}
export function saveStudent(student) {
  if (student._id) {
    const body = { ...student };
    delete body._id;
    return http.put(studentUrl(student._id), body);
  }

  return http.post(apiEndpoint, student);
}

export function deleteStudent(studentId) {
  return http.delete(studentUrl(studentId));
}

export function saveDocusAsExcel() {
  return http.get(apiEndpoint + "/toexcel");
}

// export function saveDocu(student) {
//   if (student._id) {
//     const body = { ...student };
//     return http.put(studentUrl(student._id), body);
//   }
//   return http.post(apiEndpoint, book);
// }
export function readExcelAsDocus(filename) {
  // if (book._id) {
  //   const body = { ...book };
  //   return http.put(bookUrl(book._id), body);
  // }
  // return http.post(apiEndpoint, book);
}
