import React, { Component } from "react";
import MonthBar from './monthBar'
import Books from './showBooks'
import booksBarPart from './booksBarPart'
import BooksBar from './booksBar'

export default class TBody extends Component {
  render() {
    return this.props.students.map((s, idx) => {
      const studentBook = this.props.studentBooks.filter(function (element) {
        return element.studentId === s._id;
      });
      const studentBook1 = this.props.studentBooks1.filter(function (element) {
        return element.studentId === s._id;
      });
      const a = studentBook[0];
      const b = studentBook1[0];
      return (
        <tr key={idx}>
          <th style={{ minWidth: 70 }}>{a.name}</th>
          <Books
            key={this.props.key + "a" + a.name}
            Books={a}
            bookColor={this.props.bookColor}
          />
          <td style={{ minWidth: 1500 }}>
            <MonthBar
              dates={this.props.dates}
              bookColor={this.props.bookColor}
            />

            <BooksBar
              key={this.props.key + "b" + b.name}
              books={booksBarPart(b.books, this.props.dates)}
              dates={this.props.dates}
            />
          </td>
          <td />
        </tr>
      );
    });
  }
}