import React from "react";
import MyImage from "./myImage";
import { getButtonClasses, getBadges } from "./class_badge";

const StudBtn = ({
  students,
  student,
  onoff,
  todayDay,
  day,
  time,
  onNameClick,
  showAjNum,
  showTel,
  showPic,
  showNameOnly
}) => (
  <button
    className={getButtonClasses({
      student,
      onoff,
      todayDay,
      day: day
    })}
    key={student.key}
    onClick={() => {
      onNameClick({
        student,
        onoff,
        todayDay,
        day: day
      });
    }}
  >
    {(showPic || student.showPic) && <MyImage img={`${student.name}`} />}
    {student.name}
    {showAjNum && student.ajNum && ` ${student.ajNum}`}
    {showTel && student.studPhone && ` ${student.studPhone}`}

    {getBadges({
      student,
      onoff,
      students,
      time,
      day,
      showNameOnly
    })}
  </button>
);

export default StudBtn;
