import React, { Component } from "react";
import Modal from "react-responsive-modal";
import ReactToPrint from "react-to-print";
import fileName from "../common/func2";
import Loading from "../common/loading";
import auth from "../../services/authService";
import {
  getStudentsBig as getStudents,
  saveStudent,
  getPlanTitles,
  deletePlan,
} from "../../services/studentService";
//todo: 피아노레슨실 이용시간을 기록에 남길것
//todo: 탑승명단에 담당운전기사 나오게 하자
import { NameList, NameList1 } from "../common/nameListWeek1";
import SearchBox from "../searchBox";
import { getDayOfWeek } from "../../utils/func";
import { onlyUnique, fieldSorter } from "../common/func1";
import { btnStyle1 } from "../common/setting";
// 메뉴:등하원 화면
const getPlan = (s, title) => {
  let s1 = {};
  s.plans.forEach((p) => {
    if (p.title === title) {
      Object.assign(s1, p);
      delete s1.title;
    }
  });
  // return s1 위치가 여기가 맞음. 옮기면 리턴값 없음
  return s1;
};
class RidesWeek extends Component {
  //#region
  state = {
    chuls: [],
    chuls1: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "이름", order: "asc" },
    day: 0, // 일 0, 월 1, 화 2, 수 3, 목 4, 금 5, 토 6
    plans: [],
    win1open: false,
    win2open: false,
    td: {},
    winStudents: [],
    title: "",
    clickedStudent: "",
    print: false,
  };
  constructor(props) {
    super(props);
    this.state.day = getDayOfWeek();
    this.state.day = this.state.day === (6 || 0) ? 1 : this.state.day;
  }

  async componentDidMount() {
    let { data: chuls1 } = await getStudents();
    let { data: plans } = await getPlanTitles();
    this.setState({ chuls1, plans });
  }
  onOpenModal = () => {
    // if ((win = "win1")) this.setState({ win1open: true });
    // if ((win = "win2")) this.setState({ win2open: true });
  };

  onCloseModal = (win) => {
    if (win === "win1") this.setState({ win1open: false });
    if (win === "win2") {
      this.setState({ win2open: false, win1open: false });
    }
  };
  handleConfirm = () => {
    this.setState({ win2open: false, win1open: false });
    this.setState({
      win1open: false,
      chuls: this.deleteCar(
        this.state.clickedStudent._id,
        this.state.td.onoff,
        this.state.td.day,
        this.state.td.time
      ),
    });
    this.savePlan(this.state.title, this.state.clickedStudent);
    this.setState({ clickedStudent: "" });
  };
  handleConfirmDeletePlan = () => {
    this.setState({ win3open: false });
    deletePlan(this.state.title);
    let chuls = [];
    let plans = [...this.state.plans];
    plans = plans.filter((p) => p !== this.state.title);
    this.setState({ title: "", chuls, plans });
  };

  handleCancel = () => {
    this.setState({ win1open: false, win2open: false, win3open: false });
  };
  deleteCar = (id, onoff, day, time) => {
    let chuls = [...this.state.chuls];
    const index = chuls.findIndex((s) => s._id === id);
    if (day in [1, 2, 3, 4, 5]) {
      if (onoff === "in") {
        if (chuls[index]["it" + day] === time) chuls[index]["it" + day] = "";
      }
      if (onoff === "out") {
        if (chuls[index]["ot" + day] === time) chuls[index]["ot" + day] = "";
      }
    }
    return chuls;
  };
  changeCar = (id, onoff, day, time) => {
    let chuls = [...this.state.chuls];
    const index = chuls.findIndex((s) => s._id === id);
    if (day in [1, 2, 3, 4, 5]) {
      if (onoff === "in") {
        chuls[index]["it" + day] = time;
      }
      if (onoff === "out") {
        chuls[index]["ot" + day] = time;
      }
    }
    return chuls;
  };
  handleSelectStudent = (s) => {
    this.setState({
      win1open: false,
      chuls: this.changeCar(
        s._id,
        this.state.td.onoff,
        this.state.td.day,
        this.state.td.time
      ),
    });
    this.savePlan(this.state.title, s);
  };
  handleConfirmRemove = (student) => {
    this.setState({
      win2open: true,
      clickedStudent: student,
    });
  };
  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };
  handleClickTd = (day, onoff, time) => {
    let chuls = this.state.chuls;
    this.setState({
      win1open: true,
      td: { day: day, onoff: onoff, time: time },
      winStudents: chuls,
    });
  };
  savePlan = (title, s) => {
    let chuls1 = [...this.state.chuls1];
    const index = chuls1.findIndex((student) => student._id === s._id);
    chuls1[index].plans.map((p) => {
      if (p.title === title) {
        Object.assign(p, s);
        delete p._id;
        delete p.name;
        delete p.hag;
        delete p.ban;
      }
      return null;
    });
    let new1 = {
      _id: chuls1[index]._id,
      name: chuls1[index].name,
      plans: chuls1[index].plans,
    };
    saveStudent(new1);
  };
  handleChangePlan = (title) => {
    if (title === this.state.title) {
      this.setState({ win3open: true });
    } else {
      let chuls = [];
      let s1 = {};
      for (let s of this.state.chuls1) {
        s1 = { ...getPlan(s, title) };
        s1._id = s._id;
        s1.name = s.name;
        s1.hag = s.hag;
        s1.ban = s.ban;
        chuls.push(s1);
        s1 = {};
      }
      this.setState({ chuls, title: title });
    }
  };
  user = auth.getCurrentUser();
  //#endregion
  render() {
    //=============================================//
    // get the time table

    // onChuls: 등원차 시간 추출
    const onChuls = this.state.chuls;
    const onTimes1 = onChuls.map((m) => {
      return "it1" in m && m.it1 !== null && m.it1;
    });
    const onTimes2 = onChuls.map((m) => {
      return "it2" in m && m.it2 !== null && m.it2;
    });
    const onTimes3 = onChuls.map((m) => {
      return "it3" in m && m.it3 !== null && m.it3;
    });
    const onTimes4 = onChuls.map((m) => {
      return "it4" in m && m.it4 !== null && m.it4;
    });
    const onTimes5 = onChuls.map((m) => {
      return "it5" in m && m.it5 !== null && m.it5;
    });
    let onTimes = [
      ...onTimes1,
      ...onTimes2,
      ...onTimes3,
      ...onTimes4,
      ...onTimes5,
    ];
    onTimes = onTimes
      .filter((m) => m !== false)
      .filter((m) => m !== "")
      .filter(onlyUnique)
      .sort();

    // offChuls: 하원차 시간 추출
    const offChuls = this.state.chuls;
    const offTimes1 = offChuls.map((m) => {
      return "ot1" in m && m.ot1 !== null && m.ot1;
    });
    const offTimes2 = offChuls.map((m) => {
      return "ot2" in m && m.ot2 !== null && m.ot2;
    });
    const offTimes3 = offChuls.map((m) => {
      return "ot3" in m && m.ot3 !== null && m.ot3;
    });
    const offTimes4 = offChuls.map((m) => {
      return "ot4" in m && m.ot4 !== null && m.ot4;
    });
    const offTimes5 = offChuls.map((m) => {
      return "ot5" in m && m.ot5 !== null && m.ot5;
    });
    let offTimes = [
      ...offTimes1,
      ...offTimes2,
      ...offTimes3,
      ...offTimes4,
      ...offTimes5,
    ];
    offTimes = offTimes
      .filter((m) => m !== false)
      .filter((m) => m !== "")
      .filter(onlyUnique)
      .sort();
    let onOffTimes1 = [];
    for (let i = 0; i < onTimes.length - 1; i++) {
      onOffTimes1.push({ time: onTimes[i], onoff: "in" });
    }
    for (let i = 0; i < offTimes.length - 1; i++) {
      onOffTimes1.push({ time: offTimes[i], onoff: "out" });
    }
    onOffTimes1 = onOffTimes1.sort(fieldSorter(["time", "onoff"]));
    if (this.state.plans.length === 0) {
      return <Loading></Loading>;
    } else
      return (
        <>
          <Modal
            open={!this.state.win2open && this.state.win1open}
            onClose={() => this.onCloseModal("win1")}
            center
            className="btn btn-warning"
          >
            {this.state.winStudents.map((s) => (
              <button
                key={s._id}
                className="btn btn-warning m-1"
                onClick={() => this.handleSelectStudent(s)}
              >
                {s.name}
              </button>
            ))}
          </Modal>
          <Modal
            open={this.state.win2open}
            onClose={() => this.onCloseModal("win2")}
            center
          >
            <>
              <h3>지울까요?</h3>
              <button
                className="btn btn-info m-3"
                onClick={() => this.handleConfirm()}
              >
                확인
              </button>
              <button
                className="btn btn-info m-3"
                onClick={() => this.handleCancel()}
              >
                취소
              </button>
            </>
          </Modal>
          <Modal
            open={this.state.win3open}
            onClose={() => this.onCloseModal("win3")}
            center
          >
            <>
              <h3>{"<" + this.state.title + "> 원본을 지울까요?"}</h3>
              <button
                className="btn btn-info m-3"
                onClick={() => this.handleConfirmDeletePlan()}
              >
                네
              </button>
              <button
                className="btn btn-info m-3"
                onClick={() => this.handleCancel()}
              >
                아니오
              </button>
            </>
          </Modal>
          {fileName("ridesWeek1.jsx", this.user)}
          <br />
          <code>
            등하원 프린트 할때 ipad 세워서 캡처한후 macbook으로 보내서
            프린트하고 출력물을 잘라서 붙여서 사용했음. 직접 프린트할수있도록
            수정할것. 등하원 표는 시간순으로 보여져야함
          </code>
          <br />
          <span>프린트</span>
          <input
            type="checkbox"
            aria-label="Checkbox for following text input"
            className=" col-1 m-2"
            onClick={() => {
              this.setState({ print: true });
            }}
          />
          {this.user && this.user.isPrincipal && (
            <>
              <div style={{ minWidth: 1000 }}>
                <PlanButtons
                  plans={this.state.plans}
                  onChangePlan={(title) => this.handleChangePlan(title)}
                  title={this.state.title}
                />
                <i
                  class="fas fa-bus-alt fa-lg"
                  onClick={() => {
                    this.props.history.push("/rides");
                  }}
                  style={btnStyle1}
                ></i>
              </div>
            </>
          )}
          <SearchBox
            value={this.state.searchQuery}
            onChange={this.handleSearch}
          />

          <div className="container" style={{ minWidth: 500 }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="col-sm-6">
                    #
                  </th>
                  <th scope="col" style={{ minWidth: 200 }}>
                    월
                  </th>
                  <th scope="col" style={{ minWidth: 200 }}>
                    화
                  </th>
                  <th scope="col" style={{ minWidth: 200 }}>
                    수
                  </th>
                  <th scope="col" style={{ minWidth: 200 }}>
                    목
                  </th>
                  <th scope="col" style={{ minWidth: 200 }}>
                    금
                  </th>
                </tr>
              </thead>
              <tbody>
                {onOffTimes1.map((m) => (
                  <tr>
                    <td>
                      <div
                        className={
                          m.onoff === "in"
                            ? "badge badge-success"
                            : "badge badge-secondary"
                        }
                      >
                        {m.onoff === "in" ? "등" : "하"}
                      </div>
                      <div>{m.time}</div>
                    </td>
                    {[1, 2, 3, 4, 5].map((
                      num // numbers mean 월,화,수,목,금
                    ) =>
                      Td(
                        num,
                        m.onoff,
                        m.time,
                        this.state.chuls,
                        this.state.searchQuery,
                        this.cancelAction,
                        this.handleConfirmRemove,
                        this.handleClickTd
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <br />
            <br />
            <br />
            <ReactToPrint
              trigger={() => {
                return (
                  <div>
                    <a href="gma.forlang.kr">왼쪽 마진 프린트!</a>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                );
              }}
              content={() => this.componentRef1}
            />
            <PrintLeftMargin
              ref={(el) => (this.componentRef1 = el)}
              onOffTimes1={onOffTimes1}
              chuls={this.state.chuls}
              width={"10%"}
            />
          </div>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <div>
                    <a href="gma.forlang.kr">오른쪽 마진 프린트!</a>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                );
              }}
              content={() => this.componentRef}
            />
            <PrintRightMargin
              ref={(el) => (this.componentRef = el)}
              onOffTimes1={onOffTimes1}
              chuls={this.state.chuls}
              width={"10%"}
            />
          </div>
        </>
      );
  }
}

class PrintRightMargin extends React.Component {
  render() {
    return (
      <div className="container col-12">
        <div className="row">
          <div className="col-11">
            <ComponentToPrint
              onOffTimes1={this.props.onOffTimes1}
              chuls={this.props.chuls}
              width={this.props.width}
            />
          </div>
          <div className="col-1" />
        </div>
      </div>
    );
  }
}
class PrintLeftMargin extends React.Component {
  render() {
    return (
      <div className="container col-12">
        <div className="row">
          <div className="col-1" />
          <div className="col-11">
            <ComponentToPrint
              onOffTimes1={this.props.onOffTimes1}
              chuls={this.props.chuls}
              width={this.props.width}
            />
          </div>
        </div>
      </div>
    );
  }
}
class ComponentToPrint extends React.Component {
  render() {
    return (
      <table
        className="table table-bordered  mx-auto col-12"
        style={{ marginTop: "-3rem" }}
      >
        <thead>
          <tr>
            <td style={{ Width: "5%", padding: "1px" }} />
            <td style={{ Width: this.props.width, padding: "1px" }}>월</td>
            <td style={{ Width: this.props.width, padding: "1px" }}>화</td>
            <td style={{ Width: this.props.width, padding: "1px" }}>수</td>
            <td style={{ Width: this.props.width, padding: "1px" }}>목</td>
            <td style={{ Width: this.props.width, padding: "1px" }}>금</td>
          </tr>
        </thead>
        <tbody>
          {this.props.onOffTimes1.map((m) => (
            <tr>
              <td
                style={{
                  padding: "1px",
                  color: m.onoff === "in" ? "red" : "blue",
                }}
              >
                {m.onoff === "in" ? "(등)" : "(하)"}
                <span> {m.time}</span>
              </td>
              {[1, 2, 3, 4, 5].map((
                num // numbers mean 월,화,수,목,금
              ) =>
                Td1(num, m.onoff, m.time, this.props.chuls, this.props.width)
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

const Td = (
  num,
  onoff,
  time,
  chuls,
  searchQuery,
  cancelAction,
  confirmRemove,
  handleClickTd
) => (
    <td className="p-0 m-0 col-4" onClick={() => handleClickTd(num, onoff, time)}>
      <NameList
        day={num}
        onoff={onoff === "in" ? "on" : "off"}
        time={time}
        students={chuls}
        searchQuery={searchQuery}
        cancelAction={cancelAction}
        onConfirmRemove={(student) => confirmRemove(student)}
      />
    </td>
  );
const Td1 = (num, onoff, time, chuls, width) => (
  <td style={{ Width: width, padding: "1px" }}>
    <NameList1
      day={num}
      onoff={onoff === "in" ? "on" : "off"}
      time={time}
      students={chuls}
    />
  </td>
);

const PlanButtons = (props) => {
  return props.plans.map((p) => (
    <button
      key={p}
      className={
        p === props.title ? "btn m-2 btn-warning" : "btn m-2 btn-light"
      }
      onClick={() => props.onChangePlan(p)}
    >
      {p}
    </button>
  ));
};
export default RidesWeek;
