import React from "react";
import { fieldSorter } from "./func1";
import OnOffStudents from "./onOffStudents";
import { filter } from "./chulsFunc";
export const NameList = props => {
  let students = [...props.students];
  students = filter(
    students,
    props.day,
    props.onoff,
    props.time
  );

  //todo: 작은 배열로 테스트하고 싶을때 _.sampleSize
  let students1학년 = [...students];
  students1학년 = students1학년.filter(m => m.hag === 1);
  //todo: use _.partition
  let students그밖에 = [...students];
  students그밖에 = students그밖에.filter(m => m.hag !== 1);

  students1학년 = students1학년.sort(fieldSorter(["ban", "name"]));
  let students1 = [...students1학년, ...students그밖에];
  students =
    props.time === "12:40" || props.time === "1:40" ? students1 : students;
  let newProps = { ...props };
  newProps.students = students;
  //#endregion
  return (
    <>
      <OnOffStudents {...newProps} allStudents={props.students} />
    </>
  );
};