import React from "react";
import { YYMM } from "../common/bookChartFunc";

const Books = ({ Books: data, bookFilter }) => {
  let filteredData = []
  for (let f of bookFilter) {
    for (let d of data.books) {
      if (d.title === f) filteredData.push(d)
    }
  }
  // console.log('filteredData', filteredData)
  return (
    <td style={{ minWidth: 183 }}>
      {filteredData.map(p => p.books.map((b1, idx) => {
        return (
          <div key={idx} style={{ "fontSize": "12px" }}>
            <span className="m-0" key={1}>
              {YYMM(b1.date)}
            </span>
            {" "}
            <span className="m-0" key={2}>
              {b1.book}
            </span>
          </div>
        );
      }))}
    </td >
  );
}

export default Books 