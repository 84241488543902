import React from "react";
import { HandlerConsumer } from '../new/handler'
const ImageB = props => {
  if (!props.student.edit) {
    return (
      <HandlerConsumer>
        {handler =>
          <button
            className="btn btn-info ml-1 mr-1"
            key={`X`}
            onClick={() => {
              handler.onImageButton(
                props.student,
              )
            }}
          >
            {`사진`}
          </button>
        }
      </HandlerConsumer>
    );
  }
  return null;
};

export default ImageB;
