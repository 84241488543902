import React from "react";
export const raiseSort = (path, sortColumn1, onSort) => {
  const sortColumn = { ...sortColumn1 };
  if (sortColumn.path === path)
    sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
  else {
    sortColumn.path = path;
    sortColumn.order = "asc";
  }
  onSort(sortColumn);
};

export const renderSortIcon = (column, sortColumn) => {

  if (column.path !== sortColumn.path) return null;
  if (sortColumn.order === "asc") {
    return (
      <div className="noPrint">
        <i className="fa fa-sort-asc" />
      </div>
    );
  } else {
    return (
      <div className="noPrint">
        <i className="fa fa-sort-desc" />
      </div>
    );
  }
};