import React from "react";
import { HandlerConsumer } from "../new/handler";

const Times = props => {
  return <TimeList color="btn-info" textColor="text-white" {...props} />;
};

const TimeList = props => {
  let onoff;
  if (props.onoff === "off") {
    onoff = "out";
  } else if (props.onoff === "on") {
    onoff = "in";
  }

  return (
    <div>
      {props.allTimes
        .filter(m => onoff === m.onoff && m.time !== props.time)
        .map(m => (
          <Time {...props} selectedTime={m.time} />
        ))}
    </div>
  );
};

const Time = props => {
  return (
    <HandlerConsumer>
      {handler => (
        <button
          className="btn m-1 btn-info"
          key={props.selectedTime}
          onClick={() =>
            handler.onSelectTime({
              student: props.student,
              dow: props.day,
              onoff: props.onoff,
              time: props.selectedTime
            })
          }
        >
          {props.selectedTime}
        </button>
      )}
    </HandlerConsumer>
  );
};
export default Times;
