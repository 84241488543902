import React, { Component } from "react";
import { getPlace, getTime } from "./func";
import { HandlerConsumer } from "../new/handler";

class EditField extends Component {
  constructor(props) {
    super(props);
    const student1 = this.props.chulsOrig.filter(
      s => s._id === this.props.student._id
    );
    const student = student1[0];
    const { day, onoff } = this.props;
    this.state = {
      onoff: this.props.onoff === "on" ? "등" : "하",
      time: getTime({ student, day, onoff, today: false }),
      timeToday: getTime({ student, day, onoff, today: true }),
      place: getPlace({ student, day, onoff, today: false }),
      placeToday: getPlace({ student, day, onoff, today: true })
    };
  }

  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.setState(state);
  };
  render() {
    const { time, place, onoff, timeToday, placeToday } = this.state;
    if (
      this.props.student.edit &&
      this.props.student.temp.onoff === this.props.onoff &&
      this.props.student.temp.time === this.props.time &&
      this.props.student.temp.day === this.props.day
    )
      return (
        <>
          <form>
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInput">
                  Name
                </label>
                <input
                  value={onoff}
                  name="onoff"
                  type="text"
                  className="form-control mb-2"
                  id="inlineFormInput"
                  placeholder=""
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInput1">
                  시간
                </label>
                <input
                  value={time}
                  name="time"
                  type="text"
                  className="form-control mb-2"
                  id="inlineFormInput1"
                  placeholder="시간"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInput2">
                  장소
                </label>
                <input
                  value={place}
                  name="place"
                  type="text"
                  className="form-control mb-2"
                  id="inlineFormInput2"
                  placeholder="장소"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInput1">
                  오늘시간
                </label>
                <input
                  value={timeToday}
                  name="timeToday"
                  type="text"
                  className="form-control mb-2"
                  id="inlineFormInput1"
                  placeholder="오늘시간"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInput2">
                  오늘장소
                </label>
                <input
                  value={placeToday}
                  name="placeToday"
                  type="text"
                  className="form-control mb-2"
                  id="inlineFormInput2"
                  placeholder="오늘장소"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-auto">
                <HandlerConsumer>
                  {handler => (
                    <button
                      onClick={() => {
                        let onoff;
                        if (this.state.onoff === "등") onoff = "on";
                        else if (this.state.onoff === "하") onoff = "off";
                        else return;
                        handler.onChangeCar({
                          student: this.props.student,
                          onoff: onoff,
                          dow: this.props.day,
                          time: this.state.time,
                          place: this.state.place,
                          timeToday: this.state.timeToday,
                          placeToday: this.state.placeToday
                        });

                        // todo: should be reflected to chuls here. After changing the time, it will be displayed on the screen, but if you press Edit again, just the previous time will appear.
                        // todo: After updating time or place fields in the boarding list, if you press edit button again, the previous value is displayed again. Let's fix it.
                      }}
                      className="btn btn-primary mb-2"
                    >
                      저장
                    </button>
                  )}
                </HandlerConsumer>
              </div>
              <div className="col-auto">
                <HandlerConsumer>
                  {handler => (
                    <button
                      onClick={() =>
                        handler.onChangeCarCancel(this.props.student)
                      }
                      className="btn btn-primary mb-2"
                    >
                      취소
                    </button>
                  )}
                </HandlerConsumer>
              </div>
            </div>
          </form>
        </>
      );
    return null;
  }
}

export default EditField;
