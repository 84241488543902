import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import FAButton from '../../story_comps/atoms/FAbutton/FAbutton';  //font awesome button
import { faCheckCircle, faTimesCircle, faExclamationTriangle, faInfoCircle, faUserCircle, faClock } from '@fortawesome/free-solid-svg-icons'
import Filename from '../common/filename';

library.add(faCheckCircle, faTimesCircle, faExclamationTriangle, faInfoCircle, faUserCircle);

const MenuButtons = ({ param }) => {
  const { history, thisScope, socket } = param
  return (
    <div >
      {Filename('menuButtons.jsx')}
      <div style={{ lineHeight: '2' }}>
        {thisScope.props.user && thisScope.props.user.isPrincipal && (
          <button
            className="btn btn-info mr-2"
            onClick={() => {
              history.push("/bus1");
            }}
          >
            차
          </button>
        )}
        {thisScope.props.user && thisScope.props.user.isPrincipal && (
          <>
            <button
              className="btn btn-info mr-2"
              onClick={() => {
                history.push("/books");
              }}
            >
              교재
          </button>
            <button
              className="btn btn-info mr-2"
              onClick={() => {
                history.push("/studentAllCs");
              }}
            >
              학생
          </button>
            <button
              className="btn btn-info mr-2"
              onClick={() => {
                history.push("/ChulBu");
              }}
            >
              출석부
          </button>
            <button
              className="btn btn-info mr-2"
              onClick={() => {
                history.push("/lessons");
              }}
            >
              레슨기록
          </button>
            <button
              className="btn btn-info mr-2"
              onClick={() => {
                history.push("/evals");
              }}
            >
              평가
          </button>
          </>
        )}
        {thisScope.props.user && thisScope.props.user.isPrincipal && (
          <>
            <button
              className="btn btn-info mr-2"
              onClick={() => {
                // 전부리셋할때 students collection 을 통째로 log에 저장합니다.
                let params = { room: "레슨", name: thisScope.props.user.name };
                socket.emit("resetAll", params, () => thisScope.dataDown());
              }}
            >
              전부리셋
          </button>
          </>
        )}
        {thisScope.props.user && <FAButton icon="faClock" onClick={() =>
          history.push("/ontime")} />
        }
        <FAButton icon="faBusAlt" onClick={() =>
          history.push("/rides")} />

        <FAButton
          icon="faUserCircle"
          onClick={() => thisScope.setState({ showPic: !thisScope.state.showPic })}
        />
        <FAButton
          icon="faHandPointUp"
          onClick={() =>
            thisScope.setState({ showAjNum: !thisScope.state.showAjNum })}
        />

        <FAButton
          icon="faPhone"
          onClick={() =>
            thisScope.setState({ showTel: !thisScope.state.showTel })}
        />

        <FAButton
          icon="faSpinner"
          onClick={() => thisScope.dataDown()}
        />

      </div>
    </div>
  );
};
export default MenuButtons