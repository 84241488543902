import React, { Component } from "react";

class FieldSelector extends Component {
  handleClick = e => {
    this.props.onSelectField(e.currentTarget.value);
  };

  render() {
    let class_1 =
      this.props.field === "모두"
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    let class_2 =
      this.props.field === "진도"
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    let class_3 =
      this.props.field === "이론"
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    let class_4 =
      this.props.field === "편지"
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    let class_5 =
      this.props.field === "평가"
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div>
            <div className="badge badge-info">common/fieldSelector.jsx</div>
          </div>
        )}

        <div
          className="btn-group"
          role="group"
          aria-label="Basic example"
          onChange={this.handleChange}
        >
          <button
            type="button"
            className={class_1}
            onClick={this.handleClick}
            value="모두"
          >
            모두
          </button>
          <button
            type="button"
            className={class_2}
            onClick={this.handleClick}
            value="진도"
          >
            진도
          </button>
          <button
            type="button"
            className={class_3}
            onClick={this.handleClick}
            value="이론"
          >
            이론
          </button>
          <button
            type="button"
            className={class_4}
            onClick={this.handleClick}
            value="편지"
          >
            편지
          </button>
          <button
            type="button"
            className={class_5}
            onClick={this.handleClick}
            value="평가"
          >
            평가
          </button>
        </div>
      </>
    );
  }
}

export default FieldSelector;
