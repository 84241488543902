import React, { Component } from "react";
import Table from "../common/table9";
// import "./studentAllCs.css";
// 20200701 import "../../my.css";

class StudentAllCsTable extends Component {
  columns = [];

  constructor(props) {
    super();
    props.columns.map(c => this.columns.push(c));
  }
  render() {
    const {
      students,
      onSort,
      sortColumn,
      print,
      esc,
      classString,
      onChanged,
      onDelete,
      onEditMode
    } = this.props;
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div className="noPrint">
            <code> students/studentAllCsTable.jsx</code>
          </div>
        )}
        <Table
          columns={this.columns}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          onDelete={onDelete}
          className={classString}
          print={print}
          esc={esc}
          onEditMode={(item, path) => onEditMode(item, path)}
          onChanged={(id, path, value) => onChanged(id, path, value)}
        />
      </>
    );
  }
}

export default StudentAllCsTable;
