import React from "react";
import Day from "../common/day";

export const dayButtons = ({ setDay, thisScope }) => {
  const { user } = thisScope;
  return (
    user &&
    user.isPrincipal && (
      <div
        style={{
          marginTop: "2.8rem",
          right: "0",
          position: "fixed",
          zIndex: 1
        }}
      >
        {/*  바꾸면 원장님 화면 위치 바뀜 */}
        <Day scope={thisScope} onSetDay={setDay} />
      </div>
    )
  );
};
