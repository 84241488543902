import React, { Component } from "react";
import { createKey } from "../common/func7";
import _ from "lodash";
import { getStudentsLetter as getStudents } from "../../services/studentService";
import auth from "../../services/authService";
import SearchBox from "../searchBox";
import { paginate } from "../../utils/paginate";

import "./hbLetter1.css";
import { rgbToHex } from "../lesson/bookChart/colorCode";

// 출력은 아직은 (결과물이 약간 다르므로) local에서 한다. CTRL-P 누르고 open PDF in Preview 눌러서 pdf app을 열어서 marginless 로 찍는다
const noShow = 0; // 확인검사가 끝나서 화면에 그만 보이게 할때
const noPublish = 1; // 그만둬서 출력안할때
const inspect = false; // 모니터상에서 검사를 진행중일때.

// 변수 초기화
const sample = false,
  sampleSize = 4; // 프로그램 개발시 빠른 테스트를 위해 학생수를 조정한다
const month = 1; // 몇월달 학부모편지인가?
// 편지란의 내용 길이에 따라 화면에서 확인하면서 폰트를 키우거나 줄인다.
//todo: 이름으로 찾는 기능 복구할것
//todo: db.students 에서 hbletter 필드 삭제할것

// students.js route 에서 letter route 가서 교재 8월을 9월로 바꿀것

// 학부모편지 프린트 설정방법.png 참조할것
const letterSize = [
  // 이름,웹폰트크기,프린트폰트크기,0=확인완료
  ["강나루", 18, 0, -noPublish],
  ["강주하", 15, 0, -noPublish],
  ["고서윤", 16, 26, -noPublish], //바하
  ["고준영", 16, 23, -noPublish], //바하
  ["고하은", 16, 13, -noPublish],
  ["권도영", 17, 16, -noPublish, 0],
  ["권도윤", 14, 16, -noPublish], //바하
  ["권한중", 13, 14, -noPublish], //바하
  ["김가빈", 15, 16, -noPublish], //바하
  ["김가원", 14, 18, -noPublish], //바하
  ["김가은", 14, 15, -noPublish], //바하
  ["김다인", 14, 15, -noPublish], //바하
  ["김민성", 17, 14, -noPublish], //바하
  ["김민주", 17, 15, -noPublish], //not yet
  ["김민지", 14, 15, -noPublish], //바하
  ["김서윤", 15, 15, -noPublish], //바하
  ["김서율", 15, 15, -noPublish], //바하
  ["김서현", 15, 15, -noPublish], //바하
  ["김소윤", 17, 14, -noPublish], //바하
  ["김수아", 15, 16, -noPublish], //바하
  ["김수정", 15, 16, -noPublish], //바하
  ["김시현", 16, 18, -noPublish], //바하
  ["김예은", 14, 15, -noPublish], //바하
  ["김윤빈", 18, 18, -noPublish], //바하
  ["김윤중", 18, 18, -noPublish], //바하
  ["김윤희", 16, 15, -noPublish], //바하
  ["김이도", 16, 15, -noPublish], //바하
  ["김지율", 18, 17, -noPublish], //not yet
  ["김진휘", 17, 16, -noPublish], //not yet
  ["김채은", 15, 15, -noPublish], //바하
  ["김태연", 15, 16, -noPublish],
  ["김태희", 15, 17, -noPublish], //바하
  ["남예서", 16, 16, -noPublish], //바하
  ["남유림", 17, 13, -noPublish],
  ["노혜영", 17, 16, -noPublish], //바하
  ["문지호", 16, 15, -noPublish], //바하
  ["박새봄", 16, 17, -noPublish], //바하
  ["박서현", 15, 15, -noPublish],
  ["박소윤", 16, 15, -noPublish],
  ["박시후", 15, 15, -noPublish],
  ["박연경", 14, 16, -noPublish], //바하
  ["박유하", 15, 15, -noPublish],
  ["박은서", 15, 15, -noPublish],
  ["박정우", 17, 14, -noPublish],
  ["박현솔", 16, 15, -noPublish],
  ["성현진", 14, 13, -noPublish],
  ["소재원", 15, 15, -noPublish],
  ["손서아", 15, 16, -noPublish], //바하
  ["손주아", 15, 15, -noPublish],
  ["심소윤", 15, 15, -noPublish], //바하
  ["안서영", 14, 15, -noPublish],
  ["양성빈", 15, 15, -noPublish, 0],
  ["양지은", 17, 15, -noPublish],
  ["양하은", 15, 15, -noPublish], //바하
  ["오서연", 13, 16, -noPublish],
  ["오석준", 17, 16, -noPublish],
  ["오예은", 14, 16, -noPublish],
  ["오윤서", 17, 16, -noPublish], //바하
  ["오윤채", 16, 16, -noPublish], //바하
  ["위지우", 13, 16, -noPublish], //
  ["위준서", 15, 15, -noPublish],
  ["유소을", 16, 16, -noPublish], //
  ["유채원", 15, 15, -noPublish], //바하
  ["윤민희", 16, 17, -noPublish], //바하
  ["윤슬민", 15, 15, -noPublish, 0],
  ["윤지우", 15, 15, -noPublish], //바하
  ["이다경", 13, 15, -noPublish],
  ["이다인", 18, 18, -noPublish], //바하
  ["이동건", 15, 15, -noPublish],
  ["이새봄", 16, 16, -noPublish], //바하
  ["이서준", 14, 16, -noPublish],
  ["이아진", 16, 16, -noPublish], //바하
  ["이예원", 15, 15, -noPublish], //바하
  ["이유빈", 14, 14, -noPublish],
  ["이은재", 16, 14, -noPublish],
  ["이재서", 16, 13, -noPublish],
  ["이정민", 16, 18, -noPublish],
  ["이지연", 15, 15, -noPublish, 0],
  ["이지환", 15, 15, -noPublish], //바하
  ["이찬혁", 15, 15, -noPublish],
  ["이채윤", 15, 15, -noPublish], //바하
  ["장은서", 13, 15, -noPublish, 0],
  ["장하연", 15, 16, -noPublish], //바하
  ["정수아", 15, 16, -noPublish], //바하
  ["정수현", 17, 18, -noPublish], //바하
  ["정아인", 16, 17, -noPublish], //바하
  ["정인영", 16, 15, -noPublish], //not yet
  ["조영민", 16, 15, -noPublish],
  ["조영진", 13, 15, -noPublish],
  ["조유빈", 17, 18, -noPublish, 0], //바하
  ["조해율", 15, 18, -noPublish], //바하
  ["천서준", 15, 15, -noPublish, 0],
  ["최민서", 15, 15, -noPublish],
  ["최민준", 15, 15, -noPublish], //바하
  ["최서영", 17, 14, -noPublish],
  ["최연후", 18, 16, -noPublish], //바하
  ["최윤서", 14, 16, -noPublish], //바하
  ["한서우", 14, 16, -noPublish], //바하
  ["한예석", 16, 19, -noPublish], //바하
  ["한채은", 16, 18, -noPublish], //not yet
  ["홍세아", 15, 15, -noPublish],
  ["황주혜", 15, 15, -noPublish] //바하
];

class HbLetter extends Component {
  state = {
    students: [],
    searchQuery: "",
    currentPage: 1,
    pageSize: 200,
    sortColumn: { path: "name", order: "asc" }
  };
  user = auth.getCurrentUser();
  async componentDidMount() {
    const { data: students } = await getStudents();
    this.setState({ students });
  }
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allStudents
    } = this.state;

    let filtered = allStudents;
    if (searchQuery)
      filtered = allStudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const students = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: students };
  };

  //todo:react-pdf.org 추가할것
  render() {
    const { sortColumn, searchQuery } = this.state;
    const { data: students1 } = this.getPagedData();
    let students = students1;
    // .filter(s => s.hbLetter===true);
    students.forEach(function (s) {
      for (let l of letterSize) {
        if (s.name === l[0]) {
          s.webFontSize = l[1];
          s.printFontSize = l[2];
          if (l[3] === noShow) s.noShow = true;
          // 이미 확정한 것은 볼필요 없음
          else s.noShow = false;
          if (l[3] === noPublish) s.noPublish = true;
          // 발행 자체를 안함
          else s.noPublish = false;
          if (l[4] === 0)
            // 내용없으므로 발행 안한다는 표시
            s.noPublish = true;

          // 연주회 참가학생명단
          if ([].includes(l[0])) s.noPublish = true;

          // 모두 확인한 것이므로 무조건 출력되게 할때 여기에 이름을 쓴다
          if (
            [
              // "소재원",
              // "심소윤",
            ].includes(l[0])
          )
            s.noPublish = false;
        }
      }
      return s;
    });
    if (sample)
      // 프로그램 개발시 빠른 테스트를 위해서 학생수 조절
      students = _.sampleSize(students, sampleSize);
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <>
            <div className="d-print-none">
              <code>hbLetter.jsx</code>
              <br />
              <code>
                localhost:3000 에서 학부모편지 프린트 설정방법1 참고해서
                출력하시오
              </code>
            </div>
          </>
        )}
        <SearchBox
          value={searchQuery}
          onChange={this.handleSearch}
          className="d-print-none"
        />

        <StudentAllCsTable
          user={this.user}
          students={students}
          sortColumn={sortColumn}
          onLike={this.handleLike}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
        />
        {/* <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
          className="d-print-none"
        /> */}
      </>
    );
  }
}
//todo: 편지보기에서 프린터하기 직전에 변수를 적용하는 방법을 찾아서 필드를 정확하게 위치시키게 하자.
class StudentAllCsTable extends Component {
  columns = [
    { path: "name", label: "Name" },
    { path: "sex", label: "성별" },
    { path: "payday", label: "Payday" },
    { path: "schoolLong", label: "School" },
    { path: "hag", label: "Schyear" },
    { path: "age", label: "Year" },
    { path: "dob", label: "생년월일" },
    { path: "letter", label: "Letter" },
    { path: "eval", label: "평가" },
    { path: "pJin", label: "Pjin" },
    { path: "theory", label: "Theory" },
    { path: "Book", label: "이달교재" }
  ];

  render() {
    const { students } = this.props;
    return (
      <>
        <Table columns={this.columns} data={students} className="table" />
      </>
    );
  }
}
const Table = ({ columns, data }) => {
  return (
    <>
      <TableBody columns={columns} data={data} />
    </>
  );
};

class TableBody extends Component {
  render() {
    const { data, columns } = this.props;
    return (
      <>
        {data &&
          data.map(item => {
            if (inspect && item.noShow !== undefined && item.noShow)
              // 내용 검사가 끝났으므로 더이상 모니터에서 볼 필요가 없음
              return null;
            if (item.noPublish !== undefined && item.noPublish)
              // 퇴원 등의 사유로 출력 자체를 할 필요가 없음.
              return null;
            return (
              <div className="page page2">
                <h1 className="month">{month}</h1>
                <img
                  src={`assets/img/hb.png`}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    opacity: 1,
                    zIndex: -1
                  }}
                />
                <Columns columns={columns} item={item} />
              </div>
            );
          })}
      </>
    );
  }
}
const Columns = ({ columns, item }) =>
  columns.map(column => {
    if (column.path === "eval") {
      const p = 1.25;
      const xx = 125;
      const yy = 312;
      const gap = 35.5;
      const xgap = 43;
      return (
        <svg height="1000" width="1000" className="eval">
          <circle
            cx={(parseInt(String(item.eval).substring(0, 1)) * xgap + xx) * p}
            cy={(0 * gap + yy) * p}
            r="10"
            stroke={rgbToHex(255, 216, 42)}
            stroke-width="3"
            fill-opacity="0"
          />
          <circle
            cx={(parseInt(String(item.eval).substring(1, 2)) * xgap + xx) * p}
            cy={(1 * gap + yy) * p}
            r="10"
            stroke={rgbToHex(255, 216, 42)}
            stroke-width="3"
            fill-opacity="0"
          />
          <circle
            cx={(parseInt(String(item.eval).substring(2, 3)) * xgap + xx) * p}
            cy={(2 * gap + yy) * p}
            r="10"
            stroke={rgbToHex(255, 216, 42)}
            stroke-width="3"
            fill-opacity="0"
          />
          <circle
            cx={(parseInt(String(item.eval).substring(3, 4)) * xgap + xx) * p}
            cy={(3 * gap + yy) * p}
            r="10"
            stroke={rgbToHex(255, 216, 42)}
            stroke-width="3"
            fill-opacity="0"
          />
          <circle
            cx={(parseInt(String(item.eval).substring(4, 5)) * xgap + xx) * p}
            cy={(4 * gap + yy) * p}
            r="10"
            stroke={rgbToHex(255, 216, 42)}
            stroke-width="3"
            fill-opacity="0"
          />
          <circle
            cx={(parseInt(String(item.eval).substring(5, 6)) * xgap + xx) * p}
            cy={(5 * gap + yy) * p}
            r="10"
            stroke={rgbToHex(255, 216, 42)}
            stroke-width="3"
            fill-opacity="0"
          />
          <circle
            cx={(parseInt(String(item.eval).substring(6, 7)) * xgap + xx) * p}
            cy={(6 * gap + yy) * p}
            r="10"
            stroke={rgbToHex(255, 216, 42)}
            stroke-width="3"
            fill-opacity="0"
          />
        </svg>
        // </div>
      );
    }
    if (column.path === "dob")
      return (
        <>
          <div key={createKey(item, column)} className="payday">
            {_.get(item, "payday")}
          </div>
          <div key={createKey(item, column)} className="school">
            {_.get(item, "schoolLong")}
          </div>
          <div key={createKey(item, column)} className="hag_hbletter">
            {_.get(item, "hag")}
          </div>
          <div key={createKey(item, column)} className="age">
            {_.get(item, "age")}
          </div>
          <div key={createKey(item, column)} className="birthyear">
            {String(item.dob).substring(0, 4)}
          </div>
          <div key={createKey(item, column)} className="birthmonth">
            {String(item.dob).substring(5, 6) === "0"
              ? String(item.dob).substring(6, 7)
              : String(item.dob).substring(5, 7)}
          </div>
          <div key={createKey(item, column)} className="birthdate">
            {String(item.dob).substring(8, 9) === "0"
              ? String(item.dob).substring(9, 10)
              : String(item.dob).substring(8, 10)}
          </div>
        </>
      );
    if (column.path === "name") {
      return (
        <React.Fragment className="name1" >
          <div className="name1" key={createKey(item, column)}>
            {_.get(item, "name")}
          </div>
        </React.Fragment>
      );
    }
    if (column.path === "Book") {
      return (
        <React.Fragment className="book" >
          <div className="book" key={createKey(item, column)}>
            {_.get(item, "Book")}
          </div>
        </React.Fragment>
      );
    }
    if (column.path === "sex" && item.sex === "남") {
      return (
        <>
          <div key={createKey(item, column)} className="male">
            <svg height="30" width="30" className="male">
              <circle
                cx="15"
                cy="15"
                r="10"
                stroke="green"
                stroke-width="2"
                fill-opacity="0"
              />
            </svg>
          </div>
        </>
      );
    } else if (column.path === "sex" && item.sex === "여") {
      return (
        <>
          <div key={createKey(item, column)} className="female">
            <svg height="30" width="30" className="female">
              <circle
                cx="15"
                cy="15"
                r="10"
                stroke="green"
                stroke-width="2"
                fill-opacity="0"
              />
            </svg>
          </div>
        </>
      );
    }
    if (column.path === "letter") {
      // let fontsize = 18; //webSize
      let fontsize = 23; //printSize
      // for (let s of letterSize) {
      // if (item.name===s[0]) fontsize = s[1];
      // if (item.name===s[0])
      fontsize = item.webFontSize;
      console.log("print font size", fontsize);
      // fontsize = s[2];
      // }
      let style = { fontSize: fontsize };
      return (
        <div id="card" style={style} className="card letter">
          <div className="card-body">
            <p className="card-text" style={style}>
              {_.get(item, "letter")}
            </p>
          </div>
        </div>
      );
    }
    if (column.path === "pJin")
      return (
        <div key={createKey(item, column)} className="pJin">
          <div className="card" id="card">
            <div className="card-body1">
              <p className="card-text">{_.get(item, "pJin")}</p>
            </div>
          </div>
        </div>
      );
    if (column.path === "theory")
      return (
        <div key={createKey(item, column)} className="theory">
          <div className="card" id="card">
            <div className="card-body">
              <p className="card-text">{_.get(item, "theory")}</p>
            </div>
          </div>
        </div>
      );
    return true;
  });
export default HbLetter;
