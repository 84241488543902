import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      // <div className="col">
      <img src="./assets/img/levelup.png" className="img-fluid" alt="" />
      // </div>
    );
  }
}

export default Home;
