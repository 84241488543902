import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody4";

const Table = ({
  outColumns,
  inColumns,
  sortColumn,
  onSort,
  data,
  className
}) => {
  return (
    <table className={className}>
      <TableHeader
        columns={outColumns}
        sortColumn={sortColumn}
        onSort={onSort}
      />
      <TableBody outColumns={outColumns} inColumns={inColumns} data={data} />
    </table>
  );
};

// const TableBody1 = ({ columns, data }) => {
//   return <TableBody columns={columns} data={data} />;
//    <TableBody columns={columns} data={data} />;
// };

export default Table;
