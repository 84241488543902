import React from "react";

class MyImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExist: null
    };
  }
  name = [
    "고흐P",
    "김민석",
    "김치순",
    "동건M",
    "오윤서",
    "송인원",
    "최재은",
    "양성빈",
    "김미정"
  ];
  //todo: 성인은 사진 안나오게로 고칠것
  render() {
    if (this.name.includes(this.props.img)) return null;
    // if (this.state.isExist === true) {
    // return <img src={MY_HOST + "/public/" + this.props.MY_IMAGE_NAME} />;
    const url = process.env.REACT_APP_API_URL;
    const url1 = url.substring(0, url.indexOf("/api"));
    return (
      <img
        src={`${url1}/user-uploads/${this.props.img}.png`}
        style={{ width: 30, height: 30, borderRadius: 30 / 2 }}
        alt=""
      />
    );
  }
}

export default MyImage;
