import React, { Component } from "react";
import Loading from "../../common/loading";
import fileName from "../../common/func2";
import { btnStyle1 } from "../../common/setting";
import { Link } from "react-router-dom";
import StudentAllCsTable from "./studentAllCsTable";
import Pagination from "../../common/pagination";
import {
  getStudentsAndOldStudents,
  saveStudent
} from "../../../services/studentService";
import { paginate } from "../../../utils/paginate";
import _ from "lodash";
import SearchBox from "../../searchBox";
import authService from "../../../services/authService";
// import ReactToPrint from "react-to-print";
// 20200701 import "./studentAllCs.css";
// import styles from "./studentAllCs.module.css";
import "./studentAllCs.css";
import { fieldSorter } from "../../common/func1";

class StudentAllCs extends Component {
  state = {
    students: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
    widthArray: [70, 70, 70, 130, 130, 70, 70],
    fontSize: 17,
    subTitle: '',
    showOldStudent: false
  };
  user = authService.getCurrentUser();
  async componentDidMount() {
    const { data: students } = await getStudentsAndOldStudents();
    students.forEach(s => {
      s.pianoBan === "성인반"
        ? (s.schoolHag = "성인")
        : (s.schoolHag = s.schoolShort
          ? s.schoolShort + (s.hag ? s.hag : s.age ? s.age : "")
          : "");
      if (s.schoolLong === "성인") s.schoolShort = "성인";
    });

    this.setState({ students });
  }
  PrintNameOrder = () => {
    let students = this.state.students
    students = students.sort(fieldSorter(["name"]));
    this.setState({
      students: students, sortColumn: { path: "name", order: "asc" }
    })

    this.setState({ subTitle: '이름순' })
    // window.print();
  };
  PrintPayOrder = () => {
    let students = this.state.students
    students = students.sort(fieldSorter(["payday", "name"]));
    this.setState({
      students: students, sortColumn: { path: "payday", order: "asc" }
    })

    this.setState({ subTitle: '결제일순' })
    // window.print();
  };

  sortByHagAge = () => {
    let students = this.state.students
    students.forEach((s) => {
      s.hagAge = 0
      if (s.age !== undefined && s.age !== null)
        s.hagAge = parseInt(s.age)
      if (String(s.schoolLong).includes('초등학교'))
        s.hagAge = s.hagAge + parseInt(s.hag) + 7
      if (String(s.schoolLong).includes('중학교'))
        s.hagAge = s.hagAge + parseInt(s.hag) + 7 + 6
      if (String(s.schoolLong).includes('고등학교'))
        s.hagAge = s.hagAge + parseInt(s.hag) + 7 + 9
      if (s.hagAge === 0) s.hagAge = 40
    })
    students = students.sort(fieldSorter(["hagAge", "name"]));
    this.setState({
      students: students, sortColumn: {}
    })
  }
  sortByBirthMonth = () => {
    let students = this.state.students
    students.forEach((s) => {
      s.mob = String(s.dob).substring(5, 10) //month of birth
    })
    students = students.sort(fieldSorter(['mob', "name"]))
    this.setState({
      students: students, sortColumn: {}
    })
  }

  PrintHagOrder = () => {
    this.sortByHagAge()
    this.setState({ subTitle: '학년순' })
  };
  PrintMonthOrder = () => {
    this.sortByBirthMonth()
    this.setState({ subTitle: '생월순' })
  };

  handleAdd = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].currentStudent = true;
    saveStudent(students[index]);
    this.setState({ students });
  };

  handleDelete = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].currentStudent = false;
    saveStudent(students[index]);
    this.setState({ students });
  };

  handleLike = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };
  handleChangeWithArray = e => {
    let v = e.target.value;
    v = v.split(",");
    this.setState({
      [e.target.name]: [...v]
    });
  };
  handleChange = e => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value
    });
  };
  handleChange2 = e => {
    this.setState({
      [e.target.name]: JSON.parse(e.target.value)
    });
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };
  handleEditMode = (item, changedItem) => {
    let students = [...this.state.students];
    const index = _.findIndex(this.state.students, item);
    students.map(m => {
      if (m.edit) {
        delete m.edit;
        delete m.evalOld;
        delete m.pJinOld;
        delete m.theoryOld;
        delete m.schoolHag;
        // const temp = m.hag1;
        // delete m.hag1;
        saveStudent(m);
        // m.hag1 = temp;
      }
      return null
    });
    students[index].edit = true;
    this.setState({ students });
  };
  handleChanged = (id, path, value) => {
    const students = [...this.state.students];
    const ids = students.map(b => b._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    students[index][path] = value;
    this.setState({ students });
  };
  getPagedData = () => {

    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      showOldStudent
    } = this.state;
    let {
      students,
    } = this.state;
    const allStudents = studentDisplayed(students, showOldStudent)
    let filtered = allStudents;
    if (searchQuery)
      filtered = allStudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const students1 = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: students1 };
  };
  columns = [
    {
      path: "name",
      label: "이름",
      minWidth: 70,
      content: student => (
        <Link to={`/students/${student._id}`}>{student.name}</Link>
      )
    },
    { path: "currentStudent", label: "현", minWidth: 60 },
    { path: "pianoBan", label: "피아노반", minWidth: 80 },
    { path: "otherBan", label: "악기", minWidth: 30 },
    { path: "sex", label: "성별", minWidth: 60 },
    { path: "payday", label: "결제일", minWidth: 70 },
    { path: "fee", label: "교육비", minWidth: 70 },
    { path: "schoolLong", label: "학교", minWidth: 130 },
    { path: "hag", label: "학년", minWidth: 70 },
    { path: "ban", label: "반", minWidth: 70 },
    { path: "age", label: "나이", minWidth: 70 },
    { path: "dob", label: "생년월일", minWidth: 130 },
    { path: "apt", label: "아파트", minWidth: 70 },
    { path: "ajNum", label: "알번", minWidth: 70 },
    { path: "attend", label: "수업요일", minWidth: 80 },
    { path: "attendNum", label: "일/주", minWidth: 70 }
  ];

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;
    const { totalCount, data: students } = this.getPagedData();
    if (students.length === 0) {
      return <Loading></Loading>
    }
    else
      return (
        <>
          <div className="noPrint">
            {fileName("students/studentAllCs/studentAllCs.jsx", this.user)}
            <br />
            <code>출력은 gma-orig-print에서 하시오</code>
          </div>
          {user && (
            <>
              <div className="noPrint">
                <Link
                  to="/students/new"
                  // to="/students"
                  className="btn btn-primary mr-2"
                >
                  New Student
              </Link>
                {/* {/* //todo: Change to <Ibtn/> */}
                <i className="fas fa-chalkboard-teacher fa-lg" onClick={() => {
                  this.props.history.push("/Chuls5");
                }} style={btnStyle1}
                ></i>
                {/* <button
                  className="btn btn-info mr-2"
                  onClick={() => this.PrintNameOrder()}
                >
                  이름순 출력
              </button>
                <button
                  className="btn btn-info mr-2"
                  onClick={() => this.PrintPayOrder()}
                >
                  결제일순 출력
              </button>
                <button
                  className="btn btn-info mr-2"
                  onClick={() => this.PrintHagOrder()}
                >
                  학년순 출력
              </button>
                <button
                  className="btn btn-info mr-2"
                  onClick={() => this.PrintMonthOrder()}
                >
                  생월순 출력
              </button> */}
              </div>
            </>
          )}
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <input
            className="noPrint"
            name="widthArray"
            value={this.state.widthArray}
            onChange={this.handleChangeWithArray}
            style={{ width: 400, fontSize: 25, textAlign: "center" }}
          />
          <input
            className="noPrint"
            name="fontSize"
            value={this.state.fontSize}
            onChange={this.handleChange}
            style={{ width: 60, fontSize: 25, textAlign: "center" }}
          />
          <label>
            퇴원생 표시:
          <input
              type='checkbox'
              name="showOldStudent"
              value={this.state.showOldStudent}
              onChange={this.handleChange}
            />
          </label>

          <StudentAllCsTable
            className="noPrint"
            user={this.user}
            students={students}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onAdd={this.handleAdd}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            columns={this.columns}
            classString="table table-striped table-borderless noPrint"
            onEditMode={(item, changedItem) =>
              this.handleEditMode(item, changedItem)
            }
            onChanged={(id, path, value) => this.handleChanged(id, path, value)}
            fontSize={this.state.fontSize}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
          <section className="page">
            {/* <div id="title1">
              <h3 id="title"> 학원생명단</h3>
              <h3 id="subTitle">{'( ' + this.state.subTitle + ' )'}</h3>
            </div> */}
            <div className="twoCol">
              {/* <StudentAllCsTablePN
                user={this.user}
                students={students}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                columns={columns1}
                classString="table table-striped table-borderless"
                fontSize={this.state.fontSize}
              /> */}
            </div>
          </section>
        </>
      );
  }
}

const studentDisplayed = (student, includeOld) => {
  return includeOld ? [...student] : [...student.filter(m => m.currentStudent === true)]
}

export default StudentAllCs;
