function toTimestamp(strDate) {
  var datum = Date.parse(strDate);
  return datum;
}
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const getWeekAgo = today => {
  let dd = String(today.substring(6, 8));
  let mm = String(today.substring(4, 6));
  let yyyy = String(today.substring(0, 4));
  let theDate = new Date(toTimestamp(yyyy + '/' + mm + '/' + dd + ' 00:00:00') - 604800000); //a week ago
  let dateString = formatDate(theDate)

  return dateString.replace(/-/gi, '')
};
export const getToday = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  return yyyy + mm + dd;
};

