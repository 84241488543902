import React from 'react'
import { OutTable, ExcelRenderer } from 'react-excel-renderer'

// export class Excel extends React.Component {
export default class MyDropzone extends React.Component {
  // I want to use react-dropzone but I don't know how to match fileObj. I prepared drop3.js for later.

  state = {
    rows: "",
    cols: ""
  }

  fileHandler = (event) => {
    let fileObj = event.target.files[0]
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err)
      }
      else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows
        })
      }
    })
  }

  render() {
    return (

      <div>
        <header>
          <input type="file" onChange={this.fileHandler.bind(this)} style={{ "padding": "10px" }} />
          <div>
            {this.state.rows && <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />}
          </div>
        </header>
      </div>
    )
  }
}