import React from "react";
import EditField from "./editField";
import { HandlerConsumer } from "../new/handler";

const EditB = props => {
  if (!props.student.edit) {
    return (
      <HandlerConsumer>
        {handler => (
          <button
            className="btn btn-info ml-1"
            key={`편집`}
            onClick={() =>
              handler.onEditButton({
                student: props.student,
                onoff: props.onoff,
                time: props.time,
                dow: props.day
              })
            }
          >
            {`편집`}
          </button>
        )}
      </HandlerConsumer>
    );
  }
  if (
    props.student.show &&
    props.student.temp.onoff === props.onoff &&
      props.student.temp.time === props.time &&
      props.student.temp.day === props.day
  ) {
    return <EditField {...props} />;
  }
  return null;
};

export default EditB;
