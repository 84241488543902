import React from "react";
import { getButtonClasses } from "./class_badge";
import { CustomGesture, Swipeable } from "react-touch";
import { 복구제스쳐 } from "./class_badge";

export default class NameBtn extends React.Component {
  handleClick = student => {
    this.props.onClick(student);
  };
  handleCancelAction = (student, onoff) => {
    this.props.onCancelAction(student, onoff);
  };
  handleShowButtons = (student, onoff, time, day) => {
    this.props.onShowButtons(student, onoff, time, day);
  };
  render() {
    return (
      <CustomGesture
        config={복구제스쳐}
        onGesture={() =>
          this.handleCancelAction(this.props.student, this.props.onoff)
        }
      >
        <Swipeable
          onSwipeLeft={() =>
            this.handleShowButtons(
              this.props.student,
              this.props.onoff,
              this.props.time,
              this.props.day
            )
          }
          onSwipeRight={() =>
            this.handleShowButtons(
              this.props.student,
              this.props.onoff,
              this.props.time,
              this.props.day
            )
          }
        >
          <button
            className={getButtonClasses({ student: this.props.student, onoff: this.props.onoff, todayDay: this.props.todayDay, day: this.props.day })}
            key={this.props.key}
            onClick={() => this.handleClick(this.props.student)}
          >
            {this.props.name}
            {/* {getBadges(
              this.props.student,
              this.props.onoff,
              this.props.students,
              this.props.time,
              this.props.day
            )} */}
          </button>
        </Swipeable>
      </CustomGesture>
    );
  }
}
