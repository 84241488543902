import React, { Component } from "react";
import fileName from "../common/func2";
import auth from "../../services/authService";
import { getStudentsBig as getStudents } from "../../services/studentService";
import NameList from "../common/nameListWeek";
import { getDayOfWeek } from "../../utils/func";
import { onlyUnique, fieldSorter } from "../common/func1";

class RidesWeek extends Component {
  state = {
    chuls: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "이름", order: "asc" },
    day: 0 // 일 0, 월 1, 화 2, 수 3, 목 4, 금 5, 토 6
  };

  constructor(props) {
    super(props);
    this.state.day = getDayOfWeek();
    this.state.day = this.state.day === (6 || 0) ? 1 : this.state.day;
  }

  async componentDidMount() {
    let { data: chuls } = await getStudents();
    this.setState({ chuls });
  }



  user = auth.getCurrentUser();

  render() {
    //=============================================//
    // get the time table

    // onChuls: 등원차이용학생
    const onChuls = this.state.chuls;

    let onTimes = onChuls.map(m => {
      return (
        ("it1" in m && m.it1 !== null && m.it1) ||
        ("it2" in m && m.it2 !== null && m.it2) ||
        ("it3" in m && m.it3 !== null && m.it3) ||
        ("it4" in m && m.it4 !== null && m.it4) ||
        ("it5" in m && m.it5 !== null && m.it5)
      );
    });

    onTimes = onTimes
      .filter(m => m !== false)
      .filter(m => m !== "")
      .filter(onlyUnique)
      .sort();

    // offChuls: 하원차이용학생
    const offChuls = this.state.chuls;

    let offTimes = offChuls.map(m => {
      return (
        ("ot1" in m && m.ot1 !== null && m.ot1) ||
        ("ot2" in m && m.ot2 !== null && m.ot2) ||
        ("ot3" in m && m.ot3 !== null && m.ot3) ||
        ("ot4" in m && m.ot4 !== null && m.ot4) ||
        ("ot5" in m && m.ot5 !== null && m.ot5)
      );
    });

    offTimes = offTimes
      .filter(m => m !== false)
      .filter(m => m !== "")
      .filter(onlyUnique)
      .sort();

    let onOffTimes1 = [];
    for (let i = 0; i < onTimes.length; i++) {
      onOffTimes1.push({ time: onTimes[i], onoff: "in" });
    }
    for (let i = 0; i < offTimes.length; i++) {
      onOffTimes1.push({ time: offTimes[i], onoff: "out" });
    }

    onOffTimes1 = onOffTimes1.sort(fieldSorter(["time", "onoff"]));

    return (
      <>
        {fileName("ridesWeek.jsx", this.user)}


        <div className="container">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">월</th>
                <th scope="col">화</th>
                <th scope="col">수</th>
                <th scope="col">목</th>
                <th scope="col">금</th>
              </tr>
            </thead>
            <tbody>
              {onOffTimes1.map(m => (
                <tr>
                  <td>
                    <div
                      className={
                        m.onoff === "in"
                          ? "badge badge-success"
                          : "badge badge-secondary"
                      }
                    >
                      <span className="badge badge-light mr-1">
                        {m.onoff === "in" ? "등" : "하"}
                      </span>
                      {m.time}
                    </div>
                  </td>
                  <td>
                    <div className="col-md-2">
                      <NameList
                        day={1}
                        onoff={m.onoff === "in" ? "on" : "off"}
                        time={m.time}
                        students={this.state.chuls}
                        cancelAction={this.cancelAction}
                        onClick={this.handleClick}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="col-md-2">
                      <NameList
                        day={2}
                        onoff={m.onoff === "in" ? "on" : "off"}
                        time={m.time}
                        students={this.state.chuls}
                        cancelAction={this.cancelAction}
                        onClick={this.handleClick}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="col-md-2">
                      <NameList
                        day={3}
                        onoff={m.onoff === "in" ? "on" : "off"}
                        time={m.time}
                        students={this.state.chuls}
                        cancelAction={this.cancelAction}
                        onClick={this.handleClick}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="col-md-2">
                      <NameList
                        day={4}
                        onoff={m.onoff === "in" ? "on" : "off"}
                        time={m.time}
                        students={this.state.chuls}
                        cancelAction={this.cancelAction}
                        onClick={this.handleClick}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="col-md-2">
                      <NameList
                        day={5}
                        onoff={m.onoff === "in" ? "on" : "off"}
                        time={m.time}
                        students={this.state.chuls}
                        cancelAction={this.cancelAction}
                        onClick={this.handleClick}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default RidesWeek;
