import React from "react";
import TableHeader from "./tableHeader7";
import TableBody from "./tableBody6";

const Table = ({ columns, sortColumn, onSort, data, className }) => {
  return (
    <table className={className}>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} />
    </table>
  );
};

export default Table;
