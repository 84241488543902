import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {




  render() {
    const { data, outColumns } = this.props;
    // const upcolumns=[]
    // upcolumns.push
    let num = 0;
    return (
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            {outColumns.map(column => {
              num = num + 1;
              return (
                <React.Fragment key={num} >
                  <td key={num}>
                    <InsideTr item={item} column={column} />
                    {/* {this.renderCell(item, column)} */}
                  </td>
                </React.Fragment>
              );
            })}
          </tr>
        ))
        }
      </tbody>
    );
  }
}
class InsideTr extends Component {


  render() {
    const { item, column } = this.props;
    if (column.path === "name")
      return (
        <>
          <div>{_.get(item, "name")}</div>
        </>
      );
    else if (column.path === "payday")
      return (
        <>
          <div>{_.get(item, "payday")}</div>
        </>
      );
    else if (column.path === "tag")
      return (
        <>
          <div>납부일</div>
          <div>결제일</div>
        </>
      );
    else if (column.path === "due1")
      return (
        <>
          <div>{_.get(item, "due1")}</div>
          <div>{_.get(item, "paid1")}</div>
        </>
      );
    else if (column.path === "due2")
      return (
        <>
          <div>{_.get(item, "due2")}</div>
          <div>{_.get(item, "paid2")}</div>
        </>
      );
    else if (column.path === "due3")
      return (
        <>
          <div>{_.get(item, "due3")}</div>
          <div>{_.get(item, "paid3")}</div>
        </>
      );
    else if (column.path === "due4")
      return (
        <>
          <div>{_.get(item, "due4")}</div>
          <div>{_.get(item, "paid4")}</div>
        </>
      );
    else return null;
    // this.renderCell(item, column);
    // columns.map(column => {
    //   return (
    //     <td key={this.createKey(item, column)}>
    //       {this.renderCell(item, column)}
    //     </td>
    //   );
    // });
  }
}
export default TableBody;
