import React from "react";
import EditB from "./editB";
import MoveB from "./moveB";
import RemoveB from "./removeB";
import ImageB from "./imageB";
import TodayInRideB from "./todayInRideB";
import TodayOutRideB from "./todayOutRideB";
import AddB from "./addB";
import { Link } from "react-router-dom";
import { HandlerConsumer } from '../new/handler'

const OtherBs = props => {
  return (
    <>
      <EditB className="btn btn-info ml-1" {...props} />
      {!props.student.edit && (
        <>
          <MoveB className="btn btn-info ml-1" {...props} />
          <AddB className="btn btn-info ml-1" {...props} />
          <TodayInRideB className="btn btn-info ml-1" {...props} />
          <TodayOutRideB className="btn btn-info ml-1" {...props} />
          <RemoveB className="btn btn-info ml-1" {...props} />
          <ImageB className="btn btn-info ml-1" {...props} />
          <button className="btn btn-info ml-1" {...props}>
            <Link
              className="nav-link p-0 text-white"
              to={`/busOnOff/${props.student._id}`}
            >
              원장
            </Link>
          </button>
          {props.student.studPhone !== undefined && props.student.studPhone !== null && props.student.studPhone !== "" && <button className="btn btn-info ml-1"><a href={`tel:010-${props.student.studPhone}`} style={{ color: "rgb(255,255,255)" }}>학생콜</a></button>}
          <HandlerConsumer>
            {handler =>
              <button
                className="btn btn-info ml-1"
                {...props}
                onClick={() =>
                  handler.onCancelAction(
                    {
                      student: props.student,
                      onoff: props.onoff
                    }
                  )
                }
              >
                초기화
              </button>
            }
          </HandlerConsumer>
        </>
      )}
    </>
  );
};

export default OtherBs;
