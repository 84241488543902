import React from "react";
import EvalRadioGroup from "./evalRadioGroup1";

const Eval7 = props => {
  if (!props.eval) return null;
  else
    return (
      <table className="table table-sm">
        <tbody>
          <EvalRadioGroup
            className="my-0"
            name="음악이론"
            id="0"
            value={props.eval[0]}
            onChange={props.onChange}
          />
          <EvalRadioGroup
            className="my-0"
            name="이해도"
            id="1"
            value={props.eval[1]}
            onChange={props.onChange}
          />
          <EvalRadioGroup
            name="초견력"
            id="2"
            value={props.eval[2]}
            onChange={props.onChange}
          />
          <EvalRadioGroup
            name="리듬감"
            id="3"
            value={props.eval[3]}
            onChange={props.onChange}
          />
          <EvalRadioGroup
            name="표현력"
            id="4"
            value={props.eval[4]}
            onChange={props.onChange}
          />
          <EvalRadioGroup
            name="손모양"
            id="5"
            value={props.eval[5]}
            onChange={props.onChange}
          />
          <EvalRadioGroup
            name="학습태도"
            id="6"
            value={props.eval[6]}
            onChange={props.onChange}
          />
        </tbody>
      </table>
    );
};

export default Eval7;
