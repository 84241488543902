import React from "react";
import fileName from "../common/func2";
import auth from "../../services/authService";
import Form1 from "../common/form1";
import { getTime } from "../../services/timeService";

class StudTimes extends Form1 {
  state = {
    data: ""
  };

  async componentDidMount() {
    const studentId = this.props.match.params.id;
    const { data } = await getTime(studentId);
    this.setState(data);
  }

  user = auth.getCurrentUser();

  date = {
    a0: "1203",
    b0: "1204",
    c0: "1205",
    d0: "1206",
    e0: "1207",
    a1: "1210",
    b1: "1211",
    c1: "1212",
    d1: "1213",
    e1: "1214",
    a2: "1217",
    b2: "1218",
    c2: "1219",
    d2: "1220",
    e2: "1221",
    a3: "1224",
    b3: "1225",
    c3: "1226",
    d3: "1227",
    e3: "1228",
    a4: "1231",
    b4: "0101",
    c4: "0102",
    d4: "0103",
    e4: "0104",
    a5: "0107",
    b5: "0108",
    c5: "0109",
    d5: "0110",
    e5: "0111"
  };
  render() {
    return (
      <>
        <div>
          {fileName("studTimes.jsx", this.user)}
          <h3>
            <small className="text-muted"> 지난 등원시간 : </small>
            {this.state.name}
          </h3>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" />
              <th scope="col">월</th>
              <th scope="col">화</th>
              <th scope="col">수</th>
              <th scope="col">목</th>
              <th scope="col">금</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1주</th>
              <td>
                <span className="badge badge-info mr-2">{this.date.a0}</span>
                {this.state[this.date.a1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.b0}</span>
                {this.state[this.date.b1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.c0}</span>
                {this.state[this.date.c1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.d0}</span>
                {this.state[this.date.d1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.e0}</span>
                {this.state[this.date.e1]}
              </td>
            </tr>

            <tr>
              <th scope="row">2주</th>
              <td>
                <span className="badge badge-info mr-2">{this.date.a1}</span>
                {this.state[this.date.a1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.b1}</span>
                {this.state[this.date.b1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.c1}</span>
                {this.state[this.date.c1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.d1}</span>
                {this.state[this.date.d1]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.e1}</span>
                {this.state[this.date.e1]}
              </td>
            </tr>
            <tr>
              <th scope="row">3주</th>
              <td>
                <span className="badge badge-info mr-2">{this.date.a2}</span>
                {this.state[this.date.a2]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.b2}</span>
                {this.state[this.date.b2]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.c2}</span>
                {this.state[this.date.c2]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.d2}</span>
                {this.state[this.date.d2]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.e2}</span>
                {this.state[this.date.e2]}
              </td>
            </tr>
            <tr>
              <th scope="row">4주</th>
              <td>
                <span className="badge badge-info mr-2">{this.date.a3}</span>
                {this.state[this.date.a3]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.b3}</span>
                {this.state[this.date.b3]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.c3}</span>
                {this.state[this.date.c3]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.d3}</span>
                {this.state[this.date.d3]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.e3}</span>
                {this.state[this.date.e3]}
              </td>
            </tr>
            <tr>
              <th scope="row">5주</th>
              <td>
                <span className="badge badge-info mr-2">{this.date.a4}</span>
                {this.state[this.date.a4]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.b4}</span>
                {this.state[this.date.b4]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.c4}</span>
                {this.state[this.date.c4]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.d4}</span>
                {this.state[this.date.d4]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.e4}</span>
                {this.state[this.date.e4]}
              </td>
            </tr>
            <tr>
              <th scope="row">6주</th>
              <td>
                <span className="badge badge-info mr-2">{this.date.a5}</span>
                {this.state[this.date.a5]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.b5}</span>
                {this.state[this.date.b5]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.c5}</span>
                {this.state[this.date.c5]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.d5}</span>
                {this.state[this.date.d5]}
              </td>
              <td>
                <span className="badge badge-info mr-2">{this.date.e5}</span>
                {this.state[this.date.e5]}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default StudTimes;
