import React, { Component } from "react";
import { raiseSort, renderSortIcon } from "../../common/func_sort"
class TableHeader extends Component {
  render() {
    const { columns, print, onSort, sortColumn } = this.props;
    let num = 0;
    return (
      <div className='title-wrapper'>
        {columns.map(column => {
          num = num + 1;
          if (!print)
            return (
              <p
                className={"clickable ".concat(column.label === '이름' ? "name" :
                  column.label === '학년' ? "hag" :
                    column.label === '진도' ? "jindo" :
                      column.label === '콩쿨' ? "concr" :
                        column.label === '작곡자' ? "melodizer" :
                          column.label === '곡명' ? "musicTitle" :
                            column.label === '교재명' ? "musicBook" : '')}
                key={num}
                onClick={() => raiseSort(column.path, sortColumn, onSort)}
              >
                {column.label} {renderSortIcon(column, sortColumn)}
              </p>
            );
          return null
        })}
      </div>
    );
  }
}

export default TableHeader;
