import React from "react";
import { pastMinutes } from "../../utils/func";

const PianoRoom = ({ piano, onSelectPiano, student }) => <>
  <button
    key={piano._id}
    className="btn btn-warning m-2"
    onClick={() => {
      onSelectPiano({ piano, student });
    }}
  >
    {piano.name}
    {piano.users !== undefined && piano.users[0] !== undefined && (
      <span className="badge badge-info m-1">
        {piano.users[0].student}
      </span>
    )}
    {piano.users !== undefined && piano.users[0] !== undefined && (
      <span className="badge badge-info m-1">
        {pastMinutes(piano.users[0].startTime) + "m"}
      </span>
    )}
    {piano.users !== undefined && piano.users[0] !== undefined && (
      <span className="badge badge-info m-1">
        {piano.users[0].teacher}
      </span>
    )}
  </button>
</>

export default PianoRoom

