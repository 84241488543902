import React from "react";
import { HandlerConsumer } from "../new/handler";

const AddB = props => {
  console.log("AddB props.day", props.day);
  console.log("AddB props.student.temp.day", props.student.temp.day);
  if (!props.student.edit) {
    if (
      props.student.choose &&
      props.student.temp.onoff === props.onoff &&
      props.student.temp.time === props.time &&
      props.student.temp.day === props.day
    ) {
    } else {
      return (
        <HandlerConsumer>
          {handler => (
            <>
              <button
                className="btn btn-info ml-1 mr-1"
                onClick={() =>
                  handler.onAddButton({
                    student: props.student,
                    onoff: props.onoff,
                    time: props.time,
                    dow: props.day,
                    students: props.students,
                    allStudents: props.allStudents,
                    onAddSelect: props.onAddSelect
                  })
                }
              >
                학생추가
              </button>
              {/* <pre>
                {JSON.stringify(
                  {
                    where: "AddB",
                    onoff: props.onoff,
                    time: props.time,
                    dow: props.dow,
                    day: props.day,
                    dow: "here"
                  },
                  null,
                  2
                )}
              </pre> */}
            </>
          )}
        </HandlerConsumer>
      );
    }
  }

  return null;
};

export default AddB;
