import React from "react";
const fileName = (name, user) => {
  return (
    <>
      {user && user.isAdmin && (
        // <div>
        //   <div className="badge badge-info">{name}</div>
        // </div>
        // <div>
        <code>{name}</code>
        // </div>
      )}
    </>
  );
};
export default fileName