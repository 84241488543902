import React from "react";
import { withRouter } from "react-router-dom";
import { Docu1 } from '../concert1/docu'
import DocusTable from "./docusTable";

// 20200701 import './concert.css';
class Docu extends Docu1 {
  fontInput = <>
    <span>font size:</span>
    <input
      name="fontSize"
      placeholder="input font-size"
      value={this.state.fontSize}
      onChange={this.handleChange}
      style={{
        width: 40,
        textAlign: "center",
        backgroundColor: 'lightgreen'
      }}
    />
  </>
  className = "page"
  style = { width: '320mm' }
  title = <div id='title'>
    <p className='centered' id="subject">정기연주회 (콩쿨) 명단</p>
    <p id="date">( 2020년 2월 15일 )</p>
  </div>
  fileName = 'concert3/concert.jsx'
  DocusTable = DocusTable
}
export default withRouter(Docu);
