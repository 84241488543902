import React from 'react'
import { btnStyle2 } from "../common/setting";
import Ibtn from '../common/i'


export const FloatButtons = ({ f }) => (
  <div style={{
    marginTop: "0.5rem",
    right: '0',
    position: 'fixed',
    zIndex: 1,
  }}
  >
    {f.NameOnly && (<Ibtn icon="fas fa-hands"
      onClick={() => f.showNameOnlyFunc()}
      style={btnStyle2} />)}
    {!f.NameOnly && (<Ibtn icon="fas fa-praying-hands"
      onClick={() => f.showNameOnlyFunc()}
      style={btnStyle2} />)}
    <Ibtn icon="far fa-hand-point-up fa-lg" onClick={() => f.showAjNumFunc()}
      style={btnStyle2} />
    <Ibtn icon="fas fa-user-circle fa-lg" onClick={() => f.showPicFunc()}
      style={btnStyle2} />
    <Ibtn icon="fas fa-phone fa-lg" onClick={() => f.showTelFunc()}
      style={btnStyle2} />
    <Ibtn icon="fas fa-spinner fa-lg but" style={btnStyle2} onClick={() => f.dataDownFunc()} />
  </div>
);