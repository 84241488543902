import React from "react";
import { string } from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faHandPointUp, faPhone, faSpinner, faBusAlt, faClock } from '@fortawesome/free-solid-svg-icons'

const Icons = {
  faUserCircle: faUserCircle,
  faHandPointUp: faHandPointUp,
  faPhone: faPhone,
  faSpinner: faSpinner,
  faBusAlt: faBusAlt,
  faClock: faClock,
  default: faUserCircle,
};

const defaultStyle = {
  marginRight: "0.5rem",
  backgroundColor: "#17A2B8",
  padding: 9,
  color: "#FFFFFF",
  borderRadius: "0.25rem",
  verticalAlign: "middle",
  border: "1px solid transparent",
  fontSize: "1rem",
  lineHeight: "1.5"
}

const FAButton = ({ onClick, style, icon }) => {
  const style1 = Object.assign({}, defaultStyle, style);
  return (<span style={style1} onClick={onClick} ><Icon name={icon} /></span>)
}
const color = "white";
const size = "lg";
const Icon = ({ name }) => {
  if (Icons[name] === undefined) return null;
  const icon = Icons[name];
  return (<FontAwesomeIcon icon={icon} color={color} size={size} />)
};

Icon.propTypes = {
  icon: string
};
export default FAButton; //font awesome button
