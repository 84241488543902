import React from "react";

export const Button = ({
  onClick, children
}) => {
  return <button className="btn btn-info m-2" onClick={onClick} >
    {children}
  </button>
}

export const LinkButton = ({
  children, student, href
}) => {
  if (student.studPhone !== undefined &&
    student.studPhone !== null &&
    student.studPhone !== "") return (
      <button className="btn btn-info ml-1">
        <a
          href={href}
          style={{ color: "rgb(255,255,255)" }}
        >
          {children}
        </a>
      </button>
    )
  else return null
}
