import _ from "lodash";
import { setStatus, unsetStatus, isOn } from "../../utils/stat";
import auth from "../../services/authService";
import { getParams, setTime } from "../common/ridesFunc";
const {
  탑승,
  학원도착,
  하원차탑승,
  집도착,
  하원차체크
} = require("../../gma/Stats");
export const handleAddButton = ({
  student,
  onoff,
  time,
  dow,
  students,
  allStudents,
  onAddSelect,
  setState,
  chuls: chuls1
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);

  chuls
    .filter(m => m !== student)
    .map(m => {
      m.move = false;
      m.edit = false;
      m.show = false;
      return null;
    });
  chuls[index].show = true;
  chuls[index].edit = false;
  chuls[index].move = false;
  chuls[index].choose = true;
  // temp={onoff, time, day} 는 사용자가 선택한 버튼을 알아내기 위해서
  // 임시로 보관하는 것이므로 이후에 언제든지 지워져도 된다
  chuls[index].temp = { onoff: onoff, time: time, day: dow };
  setState({
    chuls,
    win1param: {
      student: student,
      onoff: onoff,
      time: time,
      day: dow,
      dow: dow,
      students: students,
      allStudents: allStudents,
      onAddSelect: onAddSelect
    },
    win1open: true
  });
};
export const handleAddSelect = ({
  student,
  dow,
  onoff,
  time,
  setState,
  chuls: chuls1,
  socket
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  setTime({ day: dow, onoff, time, student: chuls[index] });
  chuls.map(m => {
    m.show = false;
    m.edit = false;
    m.choose = false;
    return null;
  });
  delete chuls[index].temp;
  setState({ chuls, win1open: false, win2open: false });
  let params = getParams(auth.getCurrentUser(), chuls[index]);
  socket.emit("rides", params, function (err) { });
};
export const handleCancelAction = ({
  student: student1,
  onoff,
  setState,
  chuls: chuls1,
  doSubmit
}) => {
  let chuls = [...chuls1];
  let index = _.findIndex(chuls1, student1);
  let student = chuls[index];
  if (onoff === "off") {
    // (
    //   isOn(student, 하원차체크) &&
    //   isOn(student, 하원차탑승) &&
    //   !isOn(student, 집도착)
    // ) {
    //   student = setStatus(student, 집도착);
    // }
    // if (isOn(student, 하원차체크) && !isOn(student, 집도착)) {
    //   student = setStatus(student, 하원차탑승);
    // }
    // if (
    //   !isOn(student, 하원차체크) &&
    //   !isOn(student, 하원차탑승) &&
    //   !isOn(student, 집도착)
    // ) {
    if (
      isOn(student, 집도착) ||
      isOn(student, 하원차탑승) ||
      isOn(student, 하원차체크)
    ) {
      student = unsetStatus(student, 하원차체크);
      student = unsetStatus(student, 하원차탑승);
      student = unsetStatus(student, 집도착);
    }
  } else if (onoff === "on") {
    if (isOn(student, 학원도착)) {
      student = unsetStatus(student, 학원도착);
    }

    if (isOn(student, 탑승)) {
      student = unsetStatus(student, 탑승);
    }
    if (isOn(student, 집도착)) {
      student = unsetStatus(student, 집도착);
    }
    if (isOn(student, 하원차탑승)) {
      student = unsetStatus(student, 하원차탑승);
    }
    if (isOn(student, 하원차체크)) {
      student = unsetStatus(student, 하원차체크);
    }
  }
  chuls[index] = student;
  chuls[index].show = false;
  setState({ chuls });
  delete chuls[index].pjin;
  delete chuls[index].tjin;

  doSubmit(chuls[index]);
};
export const handleChangeCar = ({
  student,
  onoff,
  dow,
  time,
  place,
  timeToday,
  placeToday,
  setState,
  chuls: chuls1,
  socket
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  set4Fields({ chuls, index, onoff, dow, time, place, timeToday, placeToday });
  chuls.map(m => {
    m.show = false;
    m.edit = false;
    return null;
  });
  delete chuls[index].temp;
  setState({ chuls });
  let params = getParams(auth.getCurrentUser(), chuls[index]);
  socket.emit("rides", params, function (err) { });
};
export const handleChangeCarCancel = ({ student, setState, chuls: chuls1 }) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  chuls.map(m => {
    m.show = false;
    m.edit = false;
    return null;
  });
  delete chuls[index].temp;
  setState({ chuls });
};
export const handleEditButton = ({
  student,
  onoff,
  time,
  dow,
  setState,
  chuls: chuls1
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  chuls.filter(m => m !== student).map(m => (m.edit = false));
  chuls[index].show = true;
  chuls[index].edit = true;

  // temp={onoff, time, day} 는 사용자가 선택한 버튼을 알아내기 위해서
  // 임시로 보관하는 것이므로 이후에 언제든지 지워져도 된다
  chuls[index].temp = { onoff: onoff, time: time, day: dow };
  setState({ chuls }, () =>
    console.log("chuls[index].temp", chuls[index].temp)
  );
};
export const handleImageButton = ({ student, setState, chuls: chuls1 }) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  chuls[index].showPic = chuls[index].showPic ? !chuls[index].showPic : true;
  chuls[index].show = false;
  setState({ chuls });
};
export const handleMoveButton = ({
  student,
  onoff,
  time,
  dow,
  setState,
  chuls: chuls1
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  chuls
    .filter(m => m !== student)
    .map(m => {
      m.move = false;
      m.edit = false;
      m.show = false;
      m.choose = false;
      return null;
    });
  chuls[index].show = true;
  chuls[index].edit = false;
  chuls[index].choose = false;
  chuls[index].move = true;

  // temp={onoff, time, day} 는 사용자가 선택한 버튼을 알아내기 위해서
  // 임시로 보관하는 것이므로 이후에 언제든지 지워져도 된다
  chuls[index].temp = { onoff: onoff, time: time, day: dow };
  setState({ chuls });
};
export const handleNameClick = ({
  student: student1,
  onoff,
  setState,
  chuls: chuls1,
  doSubmit,
  submitable,
  dow,
  todayDow
}) => {
  if (dow !== todayDow) return null;
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student1);

  let student = chuls[index];
  if (onoff === "on") {
    if (isOn(student, 탑승)) {
      student = setStatus(student, 학원도착);
    } else if (!isOn(student, 탑승)) {
      student = setStatus(student, 탑승);
    }
  }
  if (onoff === "off") {
    if (
      isOn(student, 하원차체크) &&
      isOn(student, 하원차탑승) &&
      !isOn(student, 집도착)
    ) {
      student = setStatus(student, 집도착);
    }
    if (isOn(student, 하원차체크) && !isOn(student, 집도착)) {
      student = setStatus(student, 하원차탑승);
    }
    if (
      !isOn(student, 하원차체크) &&
      !isOn(student, 하원차탑승) &&
      !isOn(student, 집도착)
    ) {
      student = setStatus(student, 하원차체크);
    }
  }
  chuls[index] = student;

  setState({ chuls });
  delete chuls[index].pjin;
  delete chuls[index].tjin;
  doSubmit(submitable(student));
};
export const handleNextCar = ({
  student,
  onoff,
  time,
  dow,
  setState,
  chuls: chuls1,
  socket
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  if (onoff !== "off") return null;
  let field = "ot";
  field = field + dow;
  if (chuls[index][field] === time)
    chuls[index][field] = change(chuls[index][field]);
  setState({ chuls });
  let params = getParams(auth.getCurrentUser(), chuls[index]);
  socket.emit("rides", params, function (err) { });
};
export const handleRemoveButton = ({
  student,
  onoff,
  time,
  dow,
  setState,
  chuls: chuls1,
  socket
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  let field = onoff === "on" ? "i" : "o";
  field = field + "t";
  field = field + dow;
  if (chuls[index][field] === time) chuls[index][field] = "";
  setState({ chuls });
  let params = getParams(auth.getCurrentUser(), chuls[index]);
  socket.emit("rides", params, function (err) { });
};
export const handleSelectTime = ({
  student,
  dow,
  onoff,
  time,
  setState,
  chuls: chuls1,
  socket
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  setTime({ day: dow, onoff, time, student: chuls[index] });
  chuls.map(m => {
    m.show = false;
    m.move = false;
    return null;
  });
  delete chuls[index].temp;
  setState({ chuls });
  let params = getParams(auth.getCurrentUser(), chuls[index]);
  socket.emit("rides", params, function (err) { });
};
export const handleShowButtons = ({
  student,
  onoff,
  time,
  dow,
  setState,
  chuls: chuls1
}) => {
  console.log("howButtons dow", dow);
  let chuls = [...chuls1];
  const index = _.findIndex(chuls, student);
  chuls
    .filter(m => m !== student)
    .map(m => {
      m.show = false;
      m.edit = false;
      m.move = false;
      m.choose = false;
      return null;
    });
  if (chuls[index].show) {
    chuls[index].show = false;
    chuls[index].edit = false;
    chuls[index].move = false;
    chuls[index].choose = false;
    delete chuls[index].temp;
  } else {
    chuls[index].show = true;
    // temp={onoff, time, day} 는 사용자가 선택한 버튼을 알아내기 위해서
    // 임시로 보관하는 것이므로 이후에 언제든지 지워져도 된다
    chuls[index].temp = { onoff: onoff, time: time, day: dow };
  }
  setState({ chuls }, () => console.log("showButtons", chuls[index].temp));
};
export const handleTodayInRideButton = ({
  student,
  setState,
  chuls: chuls1,
  socket
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  if (chuls[index].todayInRide === undefined) chuls[index].todayInRide = false;
  else chuls[index].todayInRide = !chuls[index].todayInRide;
  chuls[index].show = false;
  chuls[index].edit = false;
  chuls[index].choose = false;
  chuls[index].move = false;
  setState({ chuls });
  let params = getParams(auth.getCurrentUser(), chuls[index]);
  socket.emit("rides", params, function (err) { });
};
export const handleTodayOutRideButton = ({
  student,
  setState,
  chuls: chuls1,
  socket
}) => {
  let chuls = [...chuls1];
  const index = _.findIndex(chuls1, student);
  if (chuls[index].todayOutRide === undefined)
    chuls[index].todayOutRide = false;
  else chuls[index].todayOutRide = !chuls[index].todayOutRide;
  chuls[index].show = false;
  chuls[index].edit = false;
  chuls[index].choose = false;
  chuls[index].move = false;
  setState({ chuls });
  let params = getParams(auth.getCurrentUser(), chuls[index]);
  socket.emit("rides", params, function (err) { });
};
const set4Fields = ({
  chuls,
  index,
  onoff,
  dow,
  time,
  place,
  timeToday,
  placeToday
}) => {
  // 오늘 하루만 정한 차 시간과 장소 : timeToday, placeToday
  // set time field
  let field = onoff === "on" ? "i" : onoff === "off" ? "o" : null;
  field = field + "t";
  field = field + dow;
  chuls[index][field] = time;
  // set place field
  field = onoff === "on" ? "i" : onoff === "off" ? "o" : null;
  field = field + "p";
  field = field + dow;
  chuls[index][field] = place;
  // set timeToday field
  field = onoff === "on" ? "i" : onoff === "off" ? "o" : null;
  field = field + "t";
  field = field + dow;
  field = field + "t";
  chuls[index][field] = timeToday;
  // set placeToday field
  field = onoff === "on" ? "i" : onoff === "off" ? "o" : null;
  field = field + "p";
  field = field + dow;
  field = field + "t";
  chuls[index][field] = placeToday;
};
const change = time => {
  if (time[2] === "0" && time[3] === "0") {
    return time[0] + time[1] + "10";
  } else if (time[2] === "1" && time[3] === "0") {
    return time[0] + time[1] + "00";
  }
};
