import React from "react";
import { createKey } from "../../common/func7";
import { Td } from './td'

export const TableBody = (props) => {
  const handleMusicClick = (item, changedItem) => {
    props.onFindMusic(item, changedItem);
  };
  const { data, columns, fontSize, onChanged, onChanged1, onWinOpen, selectedMusic, onEditMode } = props;
  return (
    <tbody>
      {data.map(item => (
        <tr key={item._id}>
          {columns.map(column =>
            <Td
              key={createKey(item, column)}
              item={item}
              column={column}
              fontSize={fontSize}
              handleClick={(path, value) => onEditMode(item, path, value)}
              handleMusicClick={handleMusicClick}
              onChanged={onChanged}
              onChanged1={onChanged1}
              onWinOpen={onWinOpen}
              selectedMusic={selectedMusic}
            ></Td>
          )}
        </tr>
      ))}
    </tbody>
  );
}
