import React from "react";
import Filename from '../common/filename';
import StudentsList from './studentsList1'

const ShowBan = ({ ban, classify }) => {
  return (
    <>
      {Filename('common/students.jsx')}
      <StudentsList ban={ban} classify={classify} />
    </>
  );
}

export default ShowBan