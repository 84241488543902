import React from "react";

const defaultstyle = {
	border: "1px dashed gray",
	color: "#000000"
};
const Badge = ({ children, style, counter }) => {
	const style1 = Object.assign({}, defaultstyle, style)
	if (!counter)
		return (
			<span
				className="badge badge-pill badge-primary badge-warning mr-2"
				style={style1}
			>
				{children}
			</span>
		);
	else return (
		<div className={`badge badge-warning mx-1 mr-1`} style={style1} >
			{children}
		</div>
	);
};

export default Badge;
