import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table2";
// 20200701 import "./evalsTable1.css";

const COLORS = ["red", "blue", "green", "yellow"];

class EvalsTable extends Component {
  constructor(props) {
    super(props);
    const user = auth.getCurrentUser();

    if (user && user.isAdmin) {
      this.columns = [];

      // this.columns.push(this.setColumn);
      // this.columns.push(this.deleteColumn);
    }
  }
  handleClick = id => {
    this.props.onClick(id);
  };
  render() {
    // let curMonth, prevMonth
    if (this.props.setting === undefined) return null
    let { curMonth, prevMonth } = this.props.setting

    const color = COLORS[1];
    const { students, onSort, sortColumn } = this.props;
    this.columns = [
      {
        path: "name",
        label: "이름",
        content: student => (
          <span
            className={`${color}-bg`}
            onClick={() => this.handleClick(student._id)}
          >
            {student.name}
          </span>
        )
      }
    ];

    if (this.props.field === "모두") {
      this.columns.push({
        // place for 4 columns
        path: "pJin",
        label: "진도 및 이론"
      });
      // this.columns.push({
      //   path: "pJin",
      //   label: "5월진도"
      // });
      // this.columns.push({
      //   path: "pJinOld",
      //   label: "4월진도"
      // });
      // this.columns.push({
      //   path: "theory",
      //   label: "5월이론"
      // });
      // this.columns.push({
      //   path: "theoryOld",
      //   label: "4월이론"
      // });
      this.columns.push({
        path: "letter",
        label: curMonth + "월 학습상황"
      });
      this.columns.push({
        path: "letterOld",
        label: prevMonth + "월 편지"
      });
      this.columns.push({
        path: "eval",
        label: curMonth + "월 평가"
      });
      this.columns.push({
        path: "evalOld",
        label: prevMonth + "월 평가"
      });
    }
    if (this.props.field === "진도") {
      this.columns.push({
        path: "pJin",
        label: "현재 진도"
      });
      this.columns.push({
        path: "pJinOld",
        label: "전달 진도"
      });
    }

    if (this.props.field === "이론") {
      this.columns.push({
        path: "theory",
        label: "현재 이론"
      });
      this.columns.push({
        path: "theoryOld",
        label: "전달 이론"
      });
    }
    if (this.props.field === "편지") {
      this.columns.push({
        path: "letter",
        label: curMonth + "월 편지"
      });
      this.columns.push({
        path: "letterOld",
        label: prevMonth + "월 편지"
      });
    }

    if (this.props.field === "평가") {
      this.columns.push({
        path: "eval",
        label: curMonth + "월 평가"
      });
      this.columns.push({
        path: "evalOld",
        label: prevMonth + "월 평가"
      });
    }

    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div>
            <div className="badge badge-info">eval/evalsTable.jsx</div>
          </div>
        )}
        <Table
          curMonth={curMonth}
          prevMonth={prevMonth}
          columns={this.columns}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table"
          onClickItem={(item, path) => this.props.onClickItem(item, path)}
          onChanged={(id, path, value) => this.props.onChanged(id, path, value)}
        />
      </>
    );
  }
}

// export default withRouter(EvalsTable);
export default EvalsTable;
