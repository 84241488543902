import React, { Component } from "react";
import fileName from "../../common/func2";
import { btnStyle1 } from "../../common/setting";
import { getLessons } from "../../../services/lessonService";
import { onlyUnique, fieldSorter } from "../../common/func1";
import auth from "../../../services/authService";
import shortid from 'shortid'
export default class Lessons extends Component {
  state = { lessons: [], date: "", date1: "" };
  async componentDidMount() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + mm + dd;
    const { data: lessons } = await getLessons({ date: today });
    this.setState({ lessons, date: today, date1: today });
  }
  async componentDidUpdate() { }

  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.setState(state);
  };
  handleClick = async () => {
    const { data: lessons } = await getLessons({ date: this.state.date1 });
    this.setState({ lessons, date: this.state.date1 });
  };
  user = auth.getCurrentUser();

  render() {
    // this.state.lessons = this.state.lessons.filter(
    //   m => m.date === this.state.date
    // );
    const lessons = this.state.lessons.filter(m => m.onTime !== undefined);
    lessons.sort(
      fieldSorter(["teacher", "onTime"])
    );
    let teacher = lessons.map(m => m.teacher);
    teacher = teacher.filter(onlyUnique).sort();
    teacher = ["YB", "HR", "ES", "HN"];
    return (
      <>
        {fileName("lessons.jsx", this.user)}

        <div className="form-row align-items-center">
          <div className="col-auto">
            <label className="sr-only" htmlFor="inlineFormInput">
              Name
            </label>
            <input
              value={this.state.date1}
              name="date1"
              type="text"
              className="form-control mb-2"
              id="inlineFormInput"
              placeholder="날짜입력"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-auto">
            <button
              onClick={() => this.handleClick()}
              className="btn btn-primary mr-2"
            >
              조회
            </button>
            {/* //todo: Change to <Ibtn/> */}
            <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
              this.props.history.push("/Chuls5");
            }} style={btnStyle1}
            ></i>
          </div>
        </div>
        <LessonsTable
          teacher={teacher}
          lessons={this.state.lessons}
          date={this.state.date}
        />
      </>
    );
  }
}

const isInTimeRange = (start, end, time) => {
  const s = new Date(2000, 0, 1, start.substring(0, 2), start.substring(3, 6));
  const e = new Date(2000, 0, 1, end.substring(0, 2), end.substring(3, 6));
  const t = new Date(2000, 0, 1, time.substring(0, 2), time.substring(3, 6));
  return t - s >= 0 && e - t > 0 ? true : false;
};
class LessonsTable extends Component {
  render() {
    return (
      <>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              {this.props.teacher.map(teacher => (
                <th scope="col" key={shortid.generate()}>
                  {teacher === "ES" && "은솔쌤"}
                  {teacher === "HN" && "한나쌤"}
                  {teacher === "YB" && "예본쌤"}
                  {teacher === "HR" && "혜란쌤"}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1시부</th>
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"12:00"}
                    to={"13:00"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"13:00"}
                    to={"13:20"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"13:20"}
                    to={"13:40"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row">2시부</th>
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"13:40"}
                    to={"14:00"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"14:00"}
                    to={"14:20"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"14:20"}
                    to={"14:40"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">3시부</th>
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"14:40"}
                    to={"15:00"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"15:00"}
                    to={"15:20"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"15:20"}
                    to={"15:40"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row">4시부</th>
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"15:40"}
                    to={"16:00"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"16:00"}
                    to={"16:20"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"16:20"}
                    to={"16:40"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">5시부</th>
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"16:40"}
                    to={"17:00"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"17:00"}
                    to={"17:20"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"17:20"}
                    to={"17:40"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row">6시부</th>
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"17:40"}
                    to={"18:00"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"18:00"}
                    to={"18:20"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr style={{ backgroundColor: "#E6E6FF" }}>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"18:20"}
                    to={"18:40"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th scope="row">7시부</th>
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"18:40"}
                    to={"19:00"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"19:00"}
                    to={"19:20"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" />
              {this.props.teacher.map(teacher => (
                <td>
                  <Lesson2
                    date={this.props.date}
                    lessons={this.props.lessons}
                    from={"19:20"}
                    to={"19:40"}
                    teacher={teacher}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}
class Lesson2 extends Component {
  render() {
    return this.props.lessons.map(lesson => (
      <Lesson
        date={this.props.date}
        lesson={lesson}
        from={this.props.from}
        to={this.props.to}
        teacher={this.props.teacher}
      />
    ));
  }
}

class Lesson extends Component {
  진도 = jindo => {
    let a = String(jindo)
      .replace(" ", "")
      .match(/체르니(\d+)(\W*-\W*(\d+)(번)?)?/);
    if (a) {
      return (
        <>
          <span className="badge badge-pill badge-success">
            {a[1] ? a[1] : ""}
          </span>
          <span className="badge badge-pill badge-warning">
            {a[3] ? a[3] : ""}
          </span>
        </>
      );
    } else {
      a = String(jindo)
        .replace(" ", "")
        .match(/바이엘(\d+)권(\W*-\W*(\d+)번)?/);
      if (a) {
        return (
          <>
            <span className="badge badge-pill badge-success">
              {a[1] ? a[1] : ""}
            </span>
            <span className="badge badge-pill badge-warning">
              {a[3] ? a[3] : ""}
            </span>
          </>
        );
      }
    }
  };

  render() {
    if (
      isInTimeRange(
        this.props.from,
        this.props.to,
        this.props.lesson.onTime.substring(11, 16)
      ) &&
      this.props.teacher === this.props.lesson.teacher &&
      this.props.date === this.props.lesson.date
    ) {
      return (
        // <div>
        //   {/* {this.props.lesson.date} */}
        //   {/* <badge className="badge badge-info mr-2">
        //     {this.props.lesson.onTime.substring(11, 16)}
        //   </badge> */}
        <button className="btn btn-light p-0">
          <badge className="badge badge-info">
            {this.props.lesson.onTime.substring(14, 15) === "0" &&
              this.props.lesson.onTime.substring(15, 16)}
            {this.props.lesson.onTime.substring(14, 15) !== "0" &&
              this.props.lesson.onTime.substring(14, 16)}
            분
          </badge>
          {this.props.lesson.student}
        </button>
        // {/* {this.진도(this.props.lesson.pJin)}
        // {this.진도1(this.props.lesson.pJin)} */}
        // {/* <badge className="badge badge-warning mr-2 ml-2">
        //   {this.props.lesson.pJin}
        // </badge> */}
        // {/* {this.props.lesson.theory} */}
        // </div>
      );
    } else return null;
  }
}
