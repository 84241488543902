import React from "react";

const Input = ({ name, label, error, className, style, ...rest }) => {
  let a = style;
  return (
    <>
      {/* <div className="form-inline">
        <label className="mr-1" htmlFor={name}>
          {label}
        </label>
        <input {...rest} name={name} id={name} className="form-control" />
        {error && <div className="alert alert-danger">{error}</div>}
      </div> */}

      <div className={"form-group " + className}>
        {/* <label className="col-md-4 control-label" for="prependedtext">
                Prepended Text
              </label> */}
        {/* <div className="col-md-4">
          <div className="input-group">
            <span className="input-group-addon">{label}</span> */}
        <label htmlFor={name}>{label}</label>
        {label !== "결제일" && label !== "교육비" && (
          <input
            className="form-control"
            style={{ maxWidth: a }}
            {...rest}
            name={name}
            id={name}
          />
        )}
        {(label === "결제일" || label === "교육비") && (
          <input
            className="form-control"
            // style={{ maxWidth: 10 }}
            name={name}
            id={name}
            {...rest}
          />
        )}

        {error && <div className="alert alert-danger">{error}</div>}
        {/* </div> */}
        {/* <p className="help-block">help</p> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Input;
