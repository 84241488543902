import React from "react";
import TableHeader from "../../common/tableHeader";
import { TableBody } from "./tableBody";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  className,
  onMusicSelect,
  fontSize,
}) => {
  return (
    <table className={className}>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody
        columns={columns}
        fontSize={fontSize}
        data={data}
        onMusicSelect={onMusicSelect}
      />
    </table>
  );
};

export default Table;
