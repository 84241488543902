import React, { Component } from "react";
import { raiseSort, renderSortIcon } from "../../common/func_sort"
class TableHeader extends Component {
  render() {
    const { columns, print, sortColumn, onSort } = this.props;
    let num = 0;
    return (
      <thead>
        <tr>
          {columns.map(column => {
            num = num + 1;
            if (!print)
              return (
                <th
                  className="clickable"
                  style={{ minWidth: column.minWidth }}
                  key={num}
                  onClick={() => raiseSort(column.path, sortColumn, onSort)}
                >
                  {column.label} {renderSortIcon(column, sortColumn)}
                </th>
              );
            return null
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
