import React from "react";
import ChooseTimes from "./chooseTimes";

const SelectTime = props => {
  if (
    props.student.move &&
    (props.student.temp.onoff === props.onoff &&
      props.student.temp.time === props.time &&
      props.student.temp.day === props.day)
  ) {
    return <ChooseTimes {...props} />;
  }
  return null;
};

export default SelectTime;
