import React from "react";
import fileName from "../common/func2";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { getStudent, saveStudent } from "../../services/studentService";
import { getSetting } from "../../services/settingService";

// import { saveStat } from "../../services/statService";
import { saveLesson } from "../../services/lessonService";
import EvalRadioGroup from "./evalRadioGroup";
import { replaceAt } from "../../utils/func";
// 20200701 import "./evalForm.css";
//todo: studentAllCs에서 새학생 만들때 eval='3333333' 추가할것
//todo: 여기 이 파일에는 eval 필드 없으면 추가해 줄것  
// 학부모편지 발행할때 사용하는 폼은 따로 저장되어있음: evalForm__saved
class EvalOnlyForm extends Form {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        _id: "",
        name: "",
        status: "", //상태
        pJin: "", //피아노진도
        theory: "", //이론
        eval: "3333333", //평가 (기본값은 필드가 없을경우에도 에러나지않게 하기 위한것)
        letter: "", //학부모편지
        letterOld: "", //학부모편지
        eduDetail: "", //교육관련사항
        offDetail: "" //결석관련사항
      },
      disableSaveBtn: true,
      errors: {}
    };
  }

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("이름"),
    status: Joi.string()
      .allow(null, "")
      .label("상태"),
    pJin: Joi.string()
      .allow(null, "")
      .label("피아노진도"),
    theory: Joi.string()
      .allow(null, "")
      .label("이론"),
    eval: Joi.string()
      .allow(null, "")
      .label("평가"),
    letter: Joi.string()
      .allow(null, "")
      .label("학부모편지"),
    letterOld: Joi.string()
      .allow(null, "")
      .label("전월 학부모편지"),
    eduDetail: Joi.string()
      .allow(null, "")
      .label("교육관련사항"),
    offDetail: Joi.string()
      .allow(null, "")
      .label("결석관련사항")
  };

  async populateStudent() {
    try {
      const studentId = this.props.match.params.id;
      if (studentId === "new") return;

      const { data: student } = await getStudent(studentId);
      this.setState({ data: this.mapToViewModel(student), disableSaveBtn: false });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateStudent();
    getSetting().then(({ data }) => {
      this.setState({ setting: data[0] })
    });
  }

  mapToViewModel(student) {
    return {
      _id: student._id,
      name: student.name,
      status: student.status,
      pJin: student.pJin,
      theory: student.theory,
      eval: student.eval,
      letter: student.letter,
      letterOld: student.letterOld,
      eduDetail: student.eduDetail,
      offDetail: student.offDetail
    };
  }
  lessonSubmit = async lesson => await saveLesson(lesson);
  user = auth.getCurrentUser();

  doSubmit = async () => {
    await saveStudent(this.state.data);
    // if (this.user.name !== "admin") {
    //   // 선생님이 아니면 레슨기록을 하지 않는다. debug시 주의하시오.
    //   this.lessonSubmit(lesson); // 레슨 기록 작성
    // }
    let student = this.state.data;
    // setStatus(student, 레슨마침);
    saveStudent(student);
    this.props.history.goBack();
  };

  handleRadioChange = (index, value) => {
    let data = this.state.data;
    const eval1 = data.eval;
    data.eval = replaceAt(eval1, index * 1, value);
    this.setState({ data });
  };

  addChar = char1 => {
    const data = this.state.data;
    // data.학부모편지 += char1;
    data.letter += char1;

    // data.학부모편지 += "\u{1F3B5}";
    this.setState({ data });
  };

  render() {
    const chars = [
      "\u{1F3B5}", // 🎵  Musical Note
      "\u{1F3B6}", // 🎶  Multiple Musical Notes
      "\u{1F3BC}", // 🎼  Musical Score
      "\u{1F3B9}", // 🎹  Musical Keyboard
      "\u{1F3BB}", // 🎻  Violin
      "\u{1F3B8}", // 🎸  Guitar
      "\u{1F941}", // 🥁  Drum
      "\u{266D}", // ♭  Flat Sign
      "\u{266E}", // ♮  Natural Sign
      "\u{266F}", // ♯  Sharp Sign
      "\u{1D11E}", // 𝄞  G Clef
      "\u{1D122}", // 𝄢  F Clef
      "\u{1D192}", // 𝆒  Crescendo
      "\u{1D193}", // 𝆓  Decrescendo
      // 💓 Beating Heart &#x1F493;
      // 💖 Sparkling Heart &#x1F496;
      // 💗 Growing Heart &#x1F497;
      // 💕 Two Hearts  &#x1F495;
      // 💞 Revolving Hearts  &#x1F49E;
      // 💝 Heart With Ribbon &#x1F49D;
      // 💟 Heart Decoration  &#x1F49F;
      // 💌 Love Letter &#x1F48C;
      // 😍 Smiling Face With Heart-shaped Eyes &#x1F60D;
      // 😘 Face Blowing a Kiss &#x1F618;
      // 😻 Smiling Cat Face With Heart-shaped Eyes &#x1F63B;
      // 💑 Couple With Heart &#x1F491;
      "\u{1F423}", // 🐣  Hatching Chick
      "\u{1F424}", // 🐤  Baby Chick
      "\u{1F425}" // 🐥 Baby Chick
      // ⭐  White Medium Star &#11088;
      // ⭑  Black Small Star  &#11089;
      // ⭒  White Small Star  &#11090;
      // 🌟 Glowing Star  &#127775
      // ☺  White Smiley Face 1
      // ✅  White Heavy Check Mark  &#x2705;
      // ✓  Check Mark  &#x2713;
      // ✔  Heavy Check Mark  &#x2714;
      // 🗸 Light Check Mark  &#x1F5F8;
      // ☑  Ballot Box With Check &#x2611;
      // →  Rightwards Arrow  26
      // ←  Leftwards Arrow 27
      // ►  Rightwards filled arrow 16
    ];
    if (this.state.setting === undefined) return null
    let { curMonth, prevMonth } = this.state.setting

    return (
      <>
        <div>{fileName("evalOnlyForm.jsx", this.user)}</div>
        <form onSubmit={this.handleSubmit}>
          {/* <fieldset> */}
          {/* <!-- Form Name --> */}
          <legend>{curMonth}월: 평가 / 학부모편지</legend>
          {/* </fieldset> */}

          {this.renderInput("name", "", "text", null, true)}
          <div className="badge badge-success">피아노진도</div>
          <div className="form-group">
            {this.renderTextarea("pJin", "피아노진도")}
          </div>
          <div className="badge badge-success">이론</div>
          <div className="form-group">
            {this.renderTextarea("theory", "이론")}
          </div>
          <div className="badge badge-success">교육관련사항</div>
          <div className="form-group">
            {this.renderTextarea("eduDetail", "교육관련사항")}
          </div>
          <div className="badge badge-success">결석관련사항</div>
          <div className="form-group">
            {this.renderTextarea("offDetail", "결석관련사항")}
          </div>
          {this.renderButton1(this.state.disableSaveBtn)}
          <div>
            <span className="badge badge-success">{prevMonth}월 학부모편지</span>
            {this.renderTextarea(
              "letterOld",
              "12월 학부모편지",
              "text",
              "col-sm-12",
              true
            )}
          </div>
          <div>
            <span className="badge badge-success">{curMonth}월 학부모편지</span>
            {this.renderTextarea(
              "letter",
              "1월 학부모편지",
              "text",
              "col-sm-12",
              false
            )}
          </div>
          {/* https://www.alt-codes.net/music_note_alt_codes.php */}
          {chars.map(m => (
            <span
              key={m}
              onClick={() => this.addChar(m)}
              className="badge badge-pill badge-warning mr-2"
            >
              {m}
            </span>
          ))}

          {this.renderButton1(this.state.disableSaveBtn)}
          <table className="table table-sm">
            <tbody>
              <EvalRadioGroup
                className="my-0"
                name="음악이론"
                id="0"
                value={this.state.data.eval[0]}
                onChange={this.handleRadioChange}
              />
              <EvalRadioGroup
                className="my-0"
                name="이해도"
                id="1"
                value={this.state.data.eval[1]}
                onChange={this.handleRadioChange}
              />
              <EvalRadioGroup
                name="초견력"
                id="2"
                value={this.state.data.eval[2]}
                onChange={this.handleRadioChange}
              />
              <EvalRadioGroup
                name="리듬감"
                id="3"
                value={this.state.data.eval[3]}
                onChange={this.handleRadioChange}
              />
              <EvalRadioGroup
                name="표현력"
                id="4"
                value={this.state.data.eval[4]}
                onChange={this.handleRadioChange}
              />
              <EvalRadioGroup
                name="손모양"
                id="5"
                value={this.state.data.eval[5]}
                onChange={this.handleRadioChange}
              />
              <EvalRadioGroup
                name="학습태도"
                id="6"
                value={this.state.data.eval[6]}
                onChange={this.handleRadioChange}
              />
            </tbody>
          </table>
          {this.renderButton1(this.state.disableSaveBtn)}
        </form>
      </>
    );
  }
}

export default EvalOnlyForm;
