import React from "react";
import { Swipeable } from "react-touch";
import StudentBtn from "./studentBtn";
import { AnimatedButton } from "./animatedButton1";
import legend from './legend'
//import { isOn } from "../utils/stat";
import { getClasses, ordering, tagColor_Time, 레슨대기자표시, 레슨마침표시, 레슨중표시, 미등원배지표시, 상태표시 } from "./chulsFunc";
import { fieldSorter } from "./func1";
//const { 결석, 등원, 레슨마침, 레슨시작, 하원 } = require("../gma/Stats");

//const StudentsList = ({ classify, onClick, onOpenWin, showPic, showAjNum, showTel }) => {
const StudentsList = ({ ban, classify, socket, user }) => {
  const { showPic, showAjNum, onOpenWin, showTel } = ban
  const handleClick = async (student) => {
    socket.emit("click", { user: user._id, _id: student._id }, (err) => { });
  };
  return (
    <div className='ban' >
      {/* {["레슨중", "등원", "레슨마침", "미출석", "하원", "결석"].map( */}
      {['lessoning', 'waitLesson', 'lessonEnd', 'willCome', 'wentHome', 'absent'].map(
        (kind, idx) => {
          let kind1;
          if (idx === 0) kind1 = '레슨중';
          if (idx === 1) kind1 = '등원';
          if (idx === 2) kind1 = '레슨마침';
          if (idx === 3) kind1 = '미출석';
          if (idx === 4) kind1 = '하원';
          if (idx === 5) kind1 = '결석';
          const array = classify[kind]
          return <div key={kind1}>
            {classify[kind].length > 0 ? legend(kind1) : null}
            {array.map(
              b => {
                const btnSize = 1, badgeSize = 1;
                let m = { ...b };
                if (idx === 0) {
                  m.myButton = getClasses(m, btnSize, badgeSize);
                  m.myBadge = 레슨중표시(m, btnSize, badgeSize);
                }
                if (idx === 1) {
                  m.myButton = getClasses(m, btnSize, badgeSize);
                  m.myBadge = 레슨대기자표시(m, btnSize, badgeSize);
                }
                if (idx === 2) {
                  m.myButton = getClasses(m, btnSize, badgeSize);
                  m.myBadge = 레슨마침표시(m, btnSize, badgeSize);
                }
                if (idx === 3) {
                  m.myButton = getClasses(m, btnSize, badgeSize);
                  m.myBadge = 미등원배지표시(
                    m,
                    false,//showTime,
                    false,//showHak,
                    btnSize,
                    badgeSize
                  );
                }
                if (idx === 4) {
                  m.myButton = getClasses(m, btnSize, badgeSize);
                  m.myBadge = 상태표시(m, btnSize, badgeSize);
                }
                if (idx === 5) {
                  m.myButton = getClasses(m, btnSize, badgeSize);
                  m.myBadge = 상태표시(m, btnSize, badgeSize);
                }
                return (
                  <AnimatedButton b={m} onClick={handleClick(m._id)} key={m._id} >
                    {/* <Swipeable
                      onSwipeLeft={() => onOpenWin(m)}
                      onSwipeRight={() => onOpenWin(m)}
                    > */}
                    {StudentBtn(m, showPic, showAjNum, showTel)}
                    {/* </Swipeable>  */}
                  </AnimatedButton>
                )
              })
            }
          </div>
        })}
    </div>
  )
}
export default StudentsList