import React from "react";
import MyImage from "./myImage";
import _ from "lodash";
import { HandlerConsumer } from "../new/handler";
const Kids = props => {
  return <Names color="btn-info" textColor="text-white" {...props} />;
};

const Names = props => {
  let otherStudents = _.difference(props.allStudents, props.students);

  otherStudents = otherStudents.filter(
    m => m.name.substring(0, 1) === props.lastName.lastName
  );
  let num = 0;
  return (
    <div>
      {otherStudents.map(student => {
        num = num + 1;
        return <Name student1={student} {...props} key={num} />;
      })}
    </div>
  );
};

const Name = props => {
  const 학년_연령 = student => {
    return student.hag ? student.hag : student.age ? student.age : null;
  };
  return (
    <HandlerConsumer>
      {handler => (
        <button
          className="btn m-1 btn-info"
          key={props.student1._id}
          onClick={() =>
            handler.onAddSelect({
              student: props.student1,
              dow: props.dow,
              onoff: props.onoff,
              time: props.time
            })
          }
        >
          <MyImage img={`${props.student1.name}`} />
          {props.student1.name}
          {/*           <pre>{JSON.stringify(props, null, 2)}</pre>
           */}{" "}
          <span className="ml-2 badge badge-light text-muted">
            {학년_연령(props.student1)}
          </span>
        </button>
      )}
    </HandlerConsumer>
  );
};
export default Kids;
