import React from "react";
import Button1 from "../../story_comps/atoms/button1/button1";
const color = {
  레슨중: {
    title: "레슨중", style: { color: "#ffffff", backgroundColor: "#874FFF", border: '2px solid #874FFF' }
  },
  등원: {
    title: "레슨대기", style: { color: "#ffffff", backgroundColor: "#ff3ca3", border: '2px solid #ff3ca3' }
  },
  레슨마침: {
    title: "레슨종료", style: { color: "#000000", backgroundColor: "#8cffc2", border: '2px solid #63ab00' }
  },
  미출석: {
    title: "등원예정", style: { color: "#ffffff", backgroundColor: "#d2b700", border: '2px solid #d2b700' }
  },
  하원: {
    title: "귀가", style: { color: "#000000", backgroundColor: "#fffbd4", border: '2px solid #ffde00' }
  },
  결석: {
    title: "오늘결석", style: { color: "#ffffff", backgroundColor: "#969696", border: '2px solid #969696' }
  },
}
const legend = (kind) =>
  <Button1 styleObj={color[kind].style} >{color[kind].title} </Button1>

export default legend