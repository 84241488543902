import React, { Component } from "react";

class BanSelector extends Component {
  handleClick = e => {
    this.props.onSelectBan(e.currentTarget.value);
  };

  render() {
    let class_1 =
      this.props.ban === ""
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    let class_2 =
      this.props.ban === "베토벤"
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    let class_3 =
      this.props.ban === "바하"
        ? "badge badge-pill badge-warning m-1"
        : "badge badge-pill badge-light m-1";
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div>
            <div className="badge badge-info">common/banSelector.jsx</div>
          </div>
        )}

        <div
          className="btn-group"
          role="group"
          aria-label="Basic example"
          onChange={this.handleChange}
        >
          <button
            type="button"
            className={class_1}
            onClick={this.handleClick}
            value=""
          >
            전체
          </button>
          <button
            type="button"
            className={class_2}
            onClick={this.handleClick}
            value="베토벤"
          >
            베토벤
          </button>
          <button
            type="button"
            className={class_3}
            onClick={this.handleClick}
            value="바하"
          >
            바하
          </button>
        </div>
      </>
    );
  }
}

export default BanSelector;
