import { getBookColorHex } from "./colorCode";
import { getTodayYYYYMMDD } from "../../common/jindoFunc";
import { dayDiff } from "../common/bookChartFunc";

const booksBarPart = books => {
  if (books === []) return;
  const outData1 = [];
  books.map(b => {
    let books1 = [
      { book: "", date: "20190101" },
      ...b.books,
      { book: "", date: getTodayYYYYMMDD(), date1: getTodayYYYYMMDD() },

      { book: "", date: "20201231", date1: "20201231" }
    ];
    for (let i = 1; i < books1.length; i = i + 1) {
      books1[i - 1].date1 = books1[i].date;
    }
    let outData = books1.map(function (item, index) {
      return {
        name: "",
        value: dayDiff(item.date, item.date1),
        description: item.book === "" ? " " : item.book,
        color: item.book === "" ? "rgb(255,255,255)" : getBookColorHex(item.book)
      };
    });
    outData1.push(outData);
    return null
  });
  return outData1;
};

export default booksBarPart

