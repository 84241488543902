export const btnStyle1 = {
  marginRight: 8,
  backgroundColor: "#17A2B8",
  padding: 11,
  color: "#FFFFFF",
  borderRadius: "0.25rem"
}

export const btnStyle2 = {
  borderRadius: '20px',
  margin: '3px',
  padding: '10px',
  fontSize: 15,
  border: "#feffeb",
  backgroundColor: 'rgba(61, 255, 113, .3)',
}