import React, { useState } from "react";
export const EditTd = ({ item, itemId, path, onChanged, className }) => {
  const [item1, setItem] = useState(item)
  const handleChange = ({ currentTarget }) => {
    setItem(currentTarget.value)
    onChanged(itemId, path, currentTarget.value);
  };
  return (
    <input
      value={item1}
      name="item"
      type="text"
      className={className}
      id="inlineFormInput"
      placeholder=""
      onChange={handleChange}
    />
  );
}