import React, { Fragment } from "react";
import { NameList } from "../common/nameList";
import { getOnOffTimes } from "./timeTable";
import Car from "../new/car";
import { howMany } from "../common/ridesFunc";

export const RidesChart = ({
  chuls,
  dow: day,
  showNameOnly,
  showPic,
  showTel,
  showAjNum,
  chulsOrig,
  todaysDow: todayDay
}) => {
  const onOffTimes = getOnOffTimes(chuls, day);
  let num = 0;

  return (
    <Fragment>
      <div
        style={{
          color: "rgb(255,255,255)"
        }}
      >
        <h2>Dummy</h2>
      </div>
      <div
        style={{
          topMargin: "6rem"
        }}
      >
        <span className="badge badge-info mr-2">등 무색: 건물도착</span>
        <span className="badge badge-info mr-5">하 무색: 집도착</span>
      </div>
      {onOffTimes.map(m => {
        num = num + 1;
        return (
          <div key={num}>
            <h4>
              <Car
                day={day}
                time={m.time}
                onoff={m.onoff}
                count={howMany({ chuls, day, m })}
                students={chuls}
              />
            </h4>
            <NameList
              day={day}
              todayDay={todayDay}
              onoff={m.onoff === "in" ? "on" : "off"}
              time={m.time}
              students={chuls}
              chulsOrig={chulsOrig}
              showNameOnly={showNameOnly}
              showPic={showPic}
              showTel={showTel}
              showAjNum={showAjNum}
              allTimes={onOffTimes.filter(s => m)}
            />
          </div>
        );
      })}
    </Fragment>
  );
};
