import React from 'react';
import { useField, } from 'formik';
import { TextField, Radio, FormControlLabel } from "@material-ui/core";

export const MyTextField = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField placeholder={placeholder} {...field} helperText={errorText} error={!!errorText} />
  )
}
export const MyRadio = ({ label, ...props }) => {
  const [field] = useField(props);
  return <FormControlLabel {...field} control={<Radio />} label={label} ></FormControlLabel>
}

