import React from 'react'
import { auth } from "./services/index";
import { gql, useQuery } from '@apollo/client';

const GET_USERS = gql`
query GetUsers{
  userAll{
    name
    email
  }
}
`;

const SelectUser = ({ user, onChange }) => {
  const { loading, error, data } = useQuery(GET_USERS);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleChange = async (event) => {
    const name = data.userAll[event.target.value].name
    let password
    const email = data.userAll[event.target.value].email
    if (name === 'YB') password = '12345'
    if (name === 'SY') password = '12345'
    await auth.loginNew(email, password);
    onChange(auth.getCurrentUser())
  }

  let i = null
  for (let ii = 0; ii < data.userAll.length; ii++) {
    if (data.userAll[ii].email === user.email) i = ii;
  }

  return (
    <>
      <label>Select user:&nbsp;
        <select value={i} onChange={handleChange}>
          {data.userAll.map((u, index) => <option value={index}>{u.name} - {u.email}</option>)}
        </select>
      </label>
    </>)
}

export default SelectUser