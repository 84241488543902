import React from "react";
import { ShowBan, Counter, BanName } from "../common/index1";
import { Board } from "./index1";

const Monitor = ({ board, ban, user, counter, board1 }) => <>
  <Board board={board} board1={board1} />
  {/* <BanName /> */}
  <ShowBan ban={ban} />
  <Counter user={user} counter={counter} />
</>

export default Monitor;
