import http from "./httpService";

const apiEndpoint = "/lessons";

function lessonUrl(id) {
  return `${apiEndpoint}/${id}`;
}
// TODO getLesson(date) 두개 합치자
export function getLessons(obj) {
  if (obj) {
    if ("date" in obj) return http.get(apiEndpoint + "/d/" + obj.date);
    if ("month" in obj) return http.get(apiEndpoint + "/m/" + obj.month);
  }
  return http.get(apiEndpoint);
}
export function getLessonsOfAllStudents(obj) {
  if (obj) {
    if ("date" in obj) return http.get(apiEndpoint + "/das/" + obj.date);
  }
  return http.get(apiEndpoint);
}
//todo: date가 위에도 있음
export function getLessonsWithDate(date) {
  return http.get(apiEndpoint + "/d/" + date);
}
export function getLessonsOrdeyByStudentDate() {
  return http.get(apiEndpoint + "/sd");
}
export function getLessonsOfStudent(studentId) {
  return http.get(apiEndpoint + "/sid/" + studentId);
}
export function getLesson(lessonId) {
  return http.get(lessonUrl(lessonId));
}

export function saveLessonsAsExcel(chulBu) {
  return http.post(apiEndpoint + "/toexcel", chulBu);
}
export function saveLesson(lesson) {
  if (lesson._id) {
    const body = { ...lesson };
    delete body._id;
    delete body.id;
    return http.put(lessonUrl(lesson._id), body);
  }
  return http.post(apiEndpoint, lesson);
}
export function deleteLesson(lessonId) {
  return http.delete(lessonUrl(lessonId));
}
