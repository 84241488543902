import _ from "lodash";

export const renderCell = (item, column) => {
  if (column.content) return column.content(item);
  return _.get(item, column.path);
};

export const createKey = (item, column) => {
  return item._id + (column.path || column.key);
};




