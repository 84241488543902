import React from "react";
// 개발중이라 감춤
const HiddenMenu = ({
  thisScope
}) => <>
    {thisScope.user && thisScope.user.isAdmin && (
      <>
        <button onclick="document.location.href='tel:010-2256-3783'" />
        <a href="tel:010-2256-3783">010-1234-5678로 전화걸기</a>
        <a href="sms:01028423783">문자 보내기</a>
        <a href="mailto:이메일주소">메일 보내기</a>
        <a href="tel-av:전화번호">영상전화</a>
        <button
          className="btn btn-info m-2"
          onClick={() => {
            thisScope.setState({
              win1open: false,
              win2open: false,
              win3open: true
            });
          }}
        >
          문자보내기
          </button>
      </>
    )}
  </>

export default HiddenMenu