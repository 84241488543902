module.exports = {
  결석: 0b1000000000000, //pw
  탑승대기: 0b100000000000, //cw
  탑승: 0b10000000000, //c
  픽업: 0b1000000000, //p
  학원도착: 0b100000000, //i
  등원: 0b10000000, //i
  레슨시작: 0b1000000, //l
  레슨마침: 0b100000, //l
  하원: 0b10000, //o
  하원차탑승: 0b1000, //ch
  집도착: 0b100, //h
  인계완료: 0b10, //d
  하원차체크: 0b1 //d
};


// 이렇게 바꾸려면 stat.js 에 있는 함수들을 먼저 수정해야 한다.
// const Status = Object.freeze({
//   결석: Symbol("결석"),
//   탑승대기: Symbol("탑승대기"),
//   탑승: Symbol("탑승"),
//   픽업: Symbol("픽업"),
//   학원도착: Symbol("학원도착"),
//   등원: Symbol("등원"),
//   레슨시작: Symbol("레슨시작"),
//   레슨마침: Symbol("레슨마침"),
//   하원: Symbol("하원"),
//   하원차탑승: Symbol("하원차탑승"),
//   집도착: Symbol("집도착"),
//   인계완료: Symbol("인계완료"),
//   하원차체크: Symbol("하원차체크"),
// });
// export default Status