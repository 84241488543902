import React from "react";
import { node, string } from "prop-types";
const defaultStyle = { fontSize: "12px", padding: "0px", marginLeft: "20px", color: "#ffffff", backgroundColor: "#874FFF", border: '2px solid #874FFF', borderTopRightRadius: "1rem", borderBottomRightRadius: "1rem" }
const Button1 = ({ children, styleObj }) => {
	const style1 = { ...defaultStyle, ...styleObj }
	return (
		<button style={style1}>
			{children}
		</button>
	);
};

// Expected prop values
Button1.propTypes = {
	children: node.isRequired,
	style: string
};

// Default prop values
Button1.defaultProps = {
	children: "Button text",
};

export default Button1;
