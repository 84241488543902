import { onlyUnique, fieldSorter } from "../common/func1";

const putTodayValue = (chuls) => {
  const doItWith = (field1, s) => {
    for (let day = 1; day < 6; day++) {
      let fieldt = ''
      let field = field1
      field = field + day
      fieldt = field + 't'
      s[field] = (s[fieldt] === undefined || s[fieldt] === null || s[fieldt] === '') ? s[field] : s[fieldt]
    }
  }
  chuls.map(s => {
    doItWith('it', s)
    doItWith('ip', s)
    doItWith('ot', s)
    doItWith('op', s)
    return null
  })
  return chuls
}
export const getOnOffTimes = (chuls1, day) => {
  //=============================================//
  // get the time table
  let chuls2 = [...chuls1]

  let chuls = putTodayValue(chuls2) // 오늘만 변경된 "차량 시간/장소"를 탑승명단 만드는데 계산에 넣는다

  // onChuls: 등원차이용학생
  const onChuls = chuls.filter(
    m => {
      let field = 'ip' + day
      return String(m[field]).substring(0, 1) !== "*";
    }
    // 차량이용정보 입력할때 장소입력시 '*'를 앞에 붙이면 픽업/배웅을 의미한다. 그래서 여기서는 보이지 않기위해서 필터링한다.
  ).filter(
    m => {
      let ignore = "i" + day + "ignore"

      return m[ignore] === undefined || (m[ignore] !== undefined && m[ignore] === false)
    }
  )


  let onTimes = onChuls.map(m => {
    let field = 'it' + day

    return field in m && m[field] !== null && m[field];
  });

  onTimes = onTimes
    .filter(m => m !== undefined && m !== false && m !== "")
    .filter(onlyUnique)
    .sort();
  // offChuls: 하원차이용학생
  const offChuls = chuls.filter(m => {
    let field = 'op' + day
    return String(m[field]).substring(0, 1) !== "*";
    // 차량이용정보 입력할때 장소입력시 '*'를 앞에 붙이면 픽업/배웅을 의미한다. 그래서 여기서는 보이지 않기위해서 필터링한다.
  }).filter(m => {
    let ignore = "o" + day + "ignore"
    return (m[ignore] === undefined || (m[ignore] !== undefined && m[ignore] === false))
  });

  let offTimes = offChuls.map(m => {
    let field = 'ot' + day
    return field in m && m[field] !== null && m[field];
  });

  offTimes = offTimes
    .filter(m => m !== undefined && m !== false && m !== "")
    .filter(onlyUnique)
    .sort();

  // let onOffTimes = [...onTimes, ...offTimes];
  // onOffTimes = onOffTimes.filter(onlyUnique).sort();
  let onOffTimes1 = [];
  for (let i = 0; i < onTimes.length; i++) {
    onOffTimes1.push({ time: onTimes[i], onoff: "in" });
  }

  for (let i = 0; i < offTimes.length; i++) {
    onOffTimes1.push({ time: offTimes[i], onoff: "out" });
  }

  onOffTimes1 = onOffTimes1.sort(fieldSorter(["time", "onoff"]));
  // 12:10, 12:40, 1:40 1학년만 반 순서로 정렬됨

  //#endregion
  //#region
  // 동승선생님에게 보이는 부분
  // if (this.user.isCarTeacher) {
  //   onOffTimes1 = onOffTimes1.filter(
  //     m =>
  //       !(
  //         m.time === "12:40" ||
  //         m.time === "1:40" ||
  //         m.time === "2:00" ||
  //         m.time === "2:30" ||
  //         m.time === "5:30" ||
  //         m.time === "5:45" ||
  //         m.time === "6:00"
  //       )
  //   );
  // }
  // 운전기사에게 보이는 부분
  // if (this.user.isDriver) {
  //   onOffTimes1 = onOffTimes1.filter(
  //     m =>
  //       !(
  //         m.time === "5:00" ||
  //         m.time === "5:30" ||
  //         m.time === "5:45" ||
  //         m.time === "6:00"
  //       )
  //   );
  // }
  return onOffTimes1
}