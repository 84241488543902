import React from "react";
import { renderCell } from "../../common/func7";

export const Td = ({ item, column, fontSize, onMusicSelect }) => {
  if (column.path === 'button')
    return (
      <td>
        <i
          onClick={() => onMusicSelect(item)}
          class="far fa-hand-point-right" style={{ color: 'magenta' }}></i>
      </td>
    );
  if (column.path === undefined) return null;
  else
    return (
      <td
        style={{ fontSize: parseInt(fontSize) }}
      >
        {renderCell(item, column)}
      </td>
    );
}