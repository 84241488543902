import http from "./httpService";

const apiEndpoint = "/times";
function timeUrl(id) {
  return `${apiEndpoint}/${id}`;
}
export function getTimes() {
  return http.get(apiEndpoint);
}

export function getTime(studentId) {
  return http.get(timeUrl(studentId));
}
