import React from "react";
import ExButton from "./exButton";

const OnOffStudents = props => {
  return (
    <>
      {props.students
        // .filter(s => s.age===7) //when testing...
        .map(student => (
          <ExButton
            {...props}
            student={student}
            // name={student.name}
            // image={student.image}
            key={student._id}
          />
        ))}
    </>
  );
};

export default OnOffStudents;
