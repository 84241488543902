export function isOn(student, statusCode) {
  return (student.status & statusCode) === statusCode;
}
export function setStatus(student, statusCode) {
  student.status = student.status | statusCode;
  return student;
}
export function unsetStatus(student, statusCode) {
  student.status = student.status & ~statusCode;
  return student;
}
export function toggleStatus(student, statusCode) {
  student.status = student.status ^ statusCode;
  return student;
}
// export function getStatus(student, statusCode) {
//   return (student.status & statusCode) === statusCode;
// }
export function compStat(status, statusCode) {
  return (status & statusCode) === statusCode;
}
