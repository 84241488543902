import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "./table";
// 20200701 import "./my.css";
// concert {
//   nameColor
//   concr
//   ccColor
//   hag
//   jindo
//   melodizer
//   musicTitle
//   musicBook
// }

class DocusTable extends Component {
  columns = []
  render() {
    const {
      students,
      onSort,
      sortColumn,
      onChangeColor,
      onChanged,
      onChanged1,
      onEditMode,
      fontSize,
      onWinOpen,
      selectedMusic,
      small
    } = this.props;
    if (this.props.small)
      this.columns = [
        { path: "name", label: "이름", minWidth: 70 },
        { path: "button", label: "" },
        { path: "melodizer", label: "작곡자" },
        { path: "musicTitle", label: "곡명" },
        { path: "musicBook", label: "교재명" },
      ];
    else
      this.columns = [
        { path: "name", label: "이름", minWidth: 70 },
        { path: "hag", label: "학년" },
        { path: "jindo", label: "진도", minWidth: 100 },
        { path: "concr", label: "콩쿨" },
        { path: "button", label: "" },
        { path: "melodizer", label: "작곡자" },
        { path: "musicTitle", label: "곡명" },
        { path: "musicBook", label: "교재명" },
      ];
    // { path: "musicTitle", label: "곡명" },
    // { path: "melodizer", label: "작곡자" },
    // { path: "musicBook", label: "교재명" },
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div className="noPrint">
            <code>docusTable.jsx</code>
          </div>
        )}
        <Table
          small={small}
          columns={this.columns}
          fontSize={fontSize}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table table-bordered"
          onEditMode={onEditMode}
          onChangeColor={onChangeColor}
          onChanged={onChanged}
          onChanged1={onChanged1}
          style={{ pageBreakInside: "auto" }}
          onWinOpen={onWinOpen}
          selectedMusic={selectedMusic}
        />
      </>
    );
  }
}
export default withRouter(DocusTable);
