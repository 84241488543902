import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table6";

class StudentAllCsTable extends Component {
  columns = [
    {
      path: "name",
      label: "Name"
      // content: student => (
      //   <Link to={`/students/${student._id}`}>{student.name}</Link>
      // )
    },
    { path: "sex", label: "성별" },
    { path: "payday", label: "Payday" },
    { path: "schoolLong", label: "School" },
    { path: "hag", label: "Schyear" },
    { path: "age", label: "Year" },
    { path: "dob", label: "생년월일" },
    { path: "letter", label: "Letter" },
    { path: "eval", label: "평가" },
    { path: "pJin", label: "Pjin" },
    { path: "theory", label: "Theory" }
  ];

  deleteColumn = {
    key: "delete",
    content: student => (
      <button
        onClick={() => this.props.onDelete(student)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { students, onSort, sortColumn } = this.props;
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div>
            <div className="badge badge-info">students/hbLettersTable.jsx</div>
          </div>
        )}
        <Table
          columns={this.columns}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table"
        />
      </>
    );
  }
}

export default StudentAllCsTable;
