import React from "react";
import { moves } from "react-touch";
import { isOn } from "../../utils/stat";
const {
  탑승,
  학원도착,
  하원,
  등원,
  하원차탑승,
  집도착,
  인계완료,
  하원차체크,
  결석
} = require("../../gma/Stats");
export const 복구제스쳐 = [
  moves.RIGHT,
  moves.DOWNRIGHT,
  moves.DOWN,
  moves.DOWNLEFT,
  moves.LEFT
];

//todo: class 처리방법 바꾸자
// https://github.com/JedWatson/classnames

// var classNames = require('classnames');

// class Button extends React.Component {
//   // ...
//   render () {
//     var btnClass = classNames({
//       btn: true,
//       'btn-pressed': this.state.isPressed,
//       'btn-over': !this.state.isPressed && this.state.isHovered
//     });
//     return <button className={btnClass}>{this.props.label}</button>;
//   }
// }
export function getButtonClasses({ student, onoff, todayDay, day }) {
  let class1 = "btn m-1 p-0 btn-";
  let class2 = "warning";
  let class3 = "secondary"
  let class4 = 'success'
  if (todayDay !== day) return class1 + class4
  if (onoff === "on" && student.todayInRide !== undefined && student.todayInRide === false) return class1 + class3
  if (onoff === "on" && isOn(student, 결석)) {
    return class1 + class3
  }
  if (onoff === "on") {
    if (isOn(student, 탑승)) {
      class2 = "success";
    }
    if (isOn(student, 학원도착) || isOn(student, 등원)) {
      class2 = "light";
    }
    return class1 + class2;
  }
  if (onoff === "off" && student.todayOutRide !== undefined && student.todayOutRide === false) return class1 + class3
  if (onoff === "off" && isOn(student, 결석)) {
    return class1 + class3
  }
  if (onoff === "off") {
    if (isOn(student, 하원)) {
      class2 = "warning";
    }
    if (isOn(student, 하원차체크)) {
      class2 = "primary";
    }
    if (isOn(student, 하원차탑승)) {
      class2 = "success";
    }
    if (isOn(student, 집도착)) {
      class2 = "light";
    }
    return class1 + class2;
  }
}
export function getBadges({ student, onoff, students, time, day, showNameOnly }) {
  if (showNameOnly) {
    return null;
  }
  if (isOn(student, 결석)) {
    return <span className="m-0 badge badge-light">결석</span>;
  } else {
    //todo: 주(2,3,4)회 안오는날 결석으로 나오지 않게 고치자
    return (
      <>
        {students.length > 10 && onoff === "off" && is유치원생(student) && (
          <span className="m-0 badge badge-danger">{`${student.age}세`}</span>
        )}
        {students.length > 10 && onoff === "off" && is1학년(student) && (
          <span className="m-0 badge badge-danger">{`${student.hag}학년`}</span>
        )}
        <span className={getBadgeClasses1(student, onoff)}>
          {!isOn(student, 탑승) &&
            student.hag === 1 &&
            getBan(student, onoff, time, day)}
        </span>
        {showPlace(student, onoff, day, time)}
        <span className={getBadgeClasses1(student, onoff)}>
          {onoff === "on" && !isOn(student, 학원도착) && isOn(student, 탑승)
            ? "승차"
            : null}
        </span>
        <span className={getBadgeClasses1(student, onoff)}>
          {onoff === "off" &&
            !isOn(student, 하원차탑승) &&
            !isOn(student, 집도착) &&
            !isOn(student, 하원차체크) &&
            isOn(student, 하원)
            ? "하원"
            : null}
        </span>
        <span className={getBadgeClasses1(student, onoff)}>
          {onoff === "off" &&
            !isOn(student, 집도착) &&
            isOn(student, 하원차탑승)
            ? "승차"
            : null}
        </span>
        <span className={getBadgeClasses1(student, onoff)}>
          {onoff === "off" &&
            !isOn(student, 집도착) &&
            !isOn(student, 하원차탑승) &&
            isOn(student, 하원차체크)
            ? "체크"
            : null}
        </span>
        <span className={getBadgeClasses1(student, onoff)}>
          {isOn(student, 인계완료) ? "인계" : null}
        </span>
      </>
    );
  }
}
function showPlace(student, onoff, day, time) {
  if (onoff === "off") {
    if (is푸르지오(student, onoff, day)) {
      return (
        <span className="m-0 badge badge-danger">
          {getPlace(student, onoff, day, time)}
        </span>
      );
    } else {
      return (
        <span className="m-0 badge badge-info">
          {getPlace(student, onoff, day, time)}
        </span>
      );
    }
  } else {
    return (
      <span className="m-0 badge badge-info">
        {getPlace(student, onoff, day, time)}
      </span>
    );
  }
}
function is푸르지오(student, onoff, day, time) {
  if (getPlace(student, onoff, day, time))
    return getPlace(student, onoff, day, time)[0] === "푸" ? true : false;
}
function is1stTime(day, time) {
  return (
    (day === 1 && time === "1:40") ||
    (day === 2 && time === "1:40") ||
    (day === 3 && time === "12:40") ||
    (day === 4 && time === "1:40") ||
    (day === 5 && time === "12:40")
  );
}
function getPlace(student, onoff, day, time) {
  return onoff === "on"
    ? student['ip' + day] === "난향초" && is1stTime(day, time)
      ? ""
      : student['ip' + day]
    : student['op' + day];
}
function getBan(student, onoff, time, day) {
  return onoff === "on" &&
    ((time === "1:40" && day === 1) ||
      (time === "1:40" && day === 2) ||
      (time === "12:40" && day === 3) ||
      (time === "1:40" && day === 4) ||
      (time === "12:40" && day === 5))
    ? `${student.ban}반`
    : null;
}
function is유치원생(student) {
  return student.age === 5 || student.age === 6 || student.age === 7
    ? true
    : false;
}
function is1학년(student) {
  return student.hag === 1 ? true : false;
}
function getBadgeClasses1(student, onoff) {
  let class1 = "m-0 badge badge-";
  let class2 = "info";
  if (onoff === "on") {
    if (isOn(student, 탑승)) {
      class2 = "light";
    }
    if (isOn(student, 학원도착) || isOn(student, 등원)) {
      class2 = "light";
    }
    return class1 + class2;
  }
  if (onoff === "off") {
    if (isOn(student, 하원)) {
      class2 = "success";
    }
    if (isOn(student, 하원차체크) || isOn(student, 하원차탑승)) {
      class2 = "light";
    }

    if (isOn(student, 집도착)) {
      class2 = "light";
    }
    return class1 + class2;
  }
}
