import React from "react";
export const 진도1 = jindo => {
  //#region
  let a = String(jindo)
    .replace(" ", "")
    .match(/동요집/);
  let b = String(jindo)
    .replace(" ", "")
    .match(/발표곡/);
  let c = String(jindo)
    .replace(" ", "")
    .match(/하농/);
  let d = String(jindo)
    .replace(" ", "")
    .match(/소곡집/);
  let e = String(jindo)
    .replace(" ", "")
    .match(/연주곡/);
  let f = String(jindo)
    .replace(" ", "")
    .match(/반주/);
  return (
    <>
      <span className="badge badge-pill badge-secondary">{a ? "동" : ""}</span>
      <span className="badge badge-pill badge-secondary">{b ? "발" : ""}</span>
      <span className="badge badge-pill badge-secondary">{c ? "하" : ""}</span>
      <span className="badge badge-pill badge-secondary">{d ? "소" : ""}</span>
      <span className="badge badge-pill badge-secondary">{e ? "연" : ""}</span>
      <span className="badge badge-pill badge-secondary">{f ? "반" : ""}</span>
    </>
  );
};
//#endregion
export const 복습포함진도 = (jindo, jsx = true) => is복습(jindo) ? '복습' : jsx ? 진도(jindo, jsx) : 진도(jindo, jsx)
export const is복습 = jindo => String(jindo).includes('복습') === true

export const 진도 = (jindo, jsx = true) => {
  if (jsx) {
    //#region
    if (jindo === null) return null;
    let a = String(jindo)
      .replace(" ", "")
      .match(/체르니(\d+)(\W*-\W*(\d+)(번)?)?/);
    if (a) {
      return (
        <>
          {/* <span className="badge badge-pill badge-success">
              {a[1] ? a[1] : ""}
            </span> */}
          {/* <span className="badge badge-pill badge-warning"> */}
          {a[3] ? a[3] : "x"}
          {/* </span> */}
        </>
      );
    } else {
      a = String(jindo)
        .replace(" ", "")
        .match(/바이엘(\d+)권(\W*-\W*(\d+)(번)?)?/);
      if (a) {
        return (
          <>
            {/* <span className="badge badge-pill badge-success">
                {a[1] ? a[1] : ""}
              </span> */}
            {/* <span className="badge badge-pill badge-warning"> */}
            {a[3] ? a[3] : "o"}
            {/* </span> */}
          </>
        );
      }
    }
    return "v";
  }
  else {
    if (jindo === null) return null;
    let a = String(jindo)
      .replace(" ", "")
      .match(/체르니(\d+)(\W*-\W*(\d+)(번)?)?/);
    if (a) {
      return a[3] ? a[3] : "x";
    } else {
      a = String(jindo)
        .replace(" ", "")
        .match(/바이엘(\d+)권(\W*-\W*(\d+)(번)?)?/);
      if (a) {
        return a[3] ? a[3] : "o"
      }
    }
    return "v";
  }
};

// export const 진도번호 = jindo => {
//   //#region
//   if (jindo === null) return null;
//   let a = String(jindo)
//     .replace(" ", "")
//     .match(/체르니(\d+)(\W*-\W*(\d+)(번)?)?/);
//   if (a) {
//     return a[3] ? a[3] : 0

//   } else {
//     a = String(jindo)
//       .replace(" ", "")
//       .match(/바이엘(\d+)권(\W*-\W*(\d+)(번)?)?/);
//     if (a) {
//       return a[3] ? a[3] : 0
//     }
//   }
//   return 0;
// };
export const 책진도 = jindo => {
  //#region
  if (jindo === null) return null;
  let a = String(jindo)
    .replace(" ", "")
    .match(/체르니(\d+)(\W*-\W*(\d+)(번)?)?/);
  if (a) {
    return (
      a[3] ? '체' + a[1] + '-' + a[3] : ""
    );
  } else {
    a = String(jindo)
      .replace(" ", "")
      .match(/바이엘(\d+)권(\W*-\W*(\d+)(번)?)?/);
    if (a) {
      return (
        a[3] ? '바' + a[1] + '-' + a[3] : ""
      );
    }
  }
  return "";
};
//#endregion
export const 진도내용 = jindo => {
  //#region
  let a = String(jindo)
    .replace(" ", "")
    .match(/체르니(\d+)(\W*-\W*(\d+)(번)?)?/);
  if (a) {
    return jindo + " : " + a[3] ? a[3] : "x";
  } else {
    a = String(jindo)
      .replace(" ", "")
      .match(/바이엘(\d+)권(\W*-\W*(\d+)(번)?)?/);
    if (a) {
      return jindo + " : " + a[3] ? a[3] : "o";
    }
  }
};
//#endregion
export const isSameBook = (jindo, book) => {
  //#region
  let a = String(jindo)
    .replace(" ", "")
    .match(/(체르니\d+)(\W*-\W*(\d+)(번)?)?/);
  if (a) {
    return a[1] === book.title;
  } else {
    a = String(jindo)
      .replace(" ", "")
      .match(/(바이엘\d+)권(\W*-\W*(\d+)(번)?)?/);
    if (a) {
      return a[1] === book.title;
    }
  }
  return false;
};
//#endregion
export const 진도번호 = jindo => {
  //#region
  let a = String(jindo)
    .replace(" ", "")
    .match(/(체르니\d+)(\W*-\W*(\d+)(번)?)?/);
  if (a) {
    return a[3] ? a[3] : "";
  } else {
    a = String(jindo)
      .replace(" ", "")
      .match(/(바이엘\d+)권(\W*-\W*(\d+)(번)?)?/);
    if (a) {
      return a[3] ? a[3] : "";
    }
  }
  return "";
};
//#endregion
export const getHagAge = (id, students) => {
  //#region
  for (let s of students) {
    if (id === s._id) {
      return { hag: s.hag, age: s.age, schoolLong: s.schoolLong };
    }
  }
  return null;
};
//#endregion
export const book = [
  { title: "체르니50", number: 50 },
  { title: "체르니40", number: 30 },
  { title: "체르니30", number: 25 },
  { title: "체르니100", number: 30 },
  { title: "바이엘4", number: 57 },
  { title: "바이엘3", number: 45 },
  { title: "바이엘2", number: 57 },
  { title: "바이엘1", number: 35 } //todo:(35)
];
export const getTodayYYYYMMDD = () => {
  let today = new Date();
  return today.toISOString().substr(0, 10).replace(/-/g, '')
};
export const getThisMonthYYYYMM = () => {
  let today = new Date();
  return today.toISOString().substr(0, 7).replace(/-/g, '')
};

var orderKeys = function (o, f) {
  var os = [], ks = [], i;
  for (i in o) {
    os.push([i, o[i]]);
  }
  os.sort(function (a, b) {
    return f(a[1], b[1]);
  });
  for (i = 0; i < os.length; i++) {
    ks.push(os[i][1]);
  }
  return ks;
};

export const 정렬 = (students, book, stack) => {
  let sss = students.filter(s => isSameBook(s.pJin, book) === true)
  let sss2 = orderKeys(sss, function (a, b) {
    return 진도번호(b.pJin) - 진도번호(a.pJin)
  });
  sss2.map(s => {
    stack.push(s);
    return null
  })
}