import React, { Component } from "react";
import { renderCell, createKey } from "./func7";
import _ from "lodash";
class TableBody extends Component {
  render() {
    const { data, columns } = this.props;

    return (
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            {columns.map(column => {
              if (
                column.path !== "dob" &&
                column.path !== "letter" &&
                column.path !== "sex" &&
                column.path !== "eval" &&
                column.path !== "pJin" &&
                column.path !== "theory"
              )
                return (
                  <td key={createKey(item, column)}>
                    {renderCell(item, column)}
                  </td>
                );
              if (column.path === "dob")
                return (
                  <>
                    <td key={createKey(item, column)}>
                      {String(item.dob).substring(0, 4)}
                    </td>
                    <td key={createKey(item, column)}>
                      {String(item.dob).substring(5, 6) === "0"
                        ? String(item.dob).substring(6, 7)
                        : String(item.dob).substring(5, 7)}
                    </td>
                    <td key={createKey(item, column)}>
                      {String(item.dob).substring(8, 9) === "0"
                        ? String(item.dob).substring(9, 10)
                        : String(item.dob).substring(8, 10)}
                    </td>
                  </>
                );
              if (column.path === "sex" && item.sex === "남") {
                return (
                  <>
                    <td key={createKey(item, column)}>o</td>
                    <td key={createKey(item, column)} />
                  </>
                );
              } else if (column.path === "sex" && item.sex === "여") {
                return (
                  <>
                    <td key={createKey(item, column)} />
                    <td key={createKey(item, column)}>o</td>
                  </>
                );
              }
              if (column.path === "eval") {
                return (
                  <>
                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(0, 1) === "1" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(0, 1) === "2" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(0, 1) === "3" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(0, 1) === "4" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(1, 2) === "1" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(1, 2) === "2" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(1, 2) === "3" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(1, 2) === "4" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(2, 3) === "1" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(2, 3) === "2" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(2, 3) === "3" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(2, 3) === "4" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(3, 4) === "1" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(3, 4) === "2" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(3, 4) === "3" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(3, 4) === "4" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(4, 5) === "1" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(4, 5) === "2" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(4, 5) === "3" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(4, 5) === "4" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(5, 6) === "1" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(5, 6) === "2" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(5, 6) === "3" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(5, 6) === "4" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(6, 7) === "1" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(6, 7) === "2" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(6, 7) === "3" ? "o" : null}
                    </td>

                    <td key={createKey(item, column)}>
                      {String(item.eval).substring(6, 7) === "4" ? "o" : null}
                    </td>
                  </>
                );
              }
              if (column.path === "letter")
                return (
                  <td key={createKey(item, column)}>
                    <pre>{_.get(item, "letter")}</pre>
                  </td>
                );
              if (column.path === "pJin")
                return (
                  <td key={createKey(item, column)}>
                    {_.get(item, "pJin")}
                  </td>
                );
              if (column.path === "theory")
                return (
                  <td key={createKey(item, column)}>
                    {_.get(item, "theory")}
                  </td>
                );
              return null
            })}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
