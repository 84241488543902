import React from "react";
import { NavLink } from "react-router-dom";

export const Etc = ({ user }) => {
  return <>
    {user && user.isPrincipal && (
      <div className='container'>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-sm">
            <NavLink className="btn btn-success m-2" to="/iotime" >
              등하시
        </NavLink>
            {/* <NavLink className="btn btn-success m-2" to="/students">
                원생
              </NavLink> */}
            <NavLink className="btn btn-success m-2" to="/weekCB">
              출출력
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/chulBu">
              출석부
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/times2">
              등원표
              </NavLink>
          </div>
          <div className="col-sm">
            {/* <NavLink className="btn btn-success m-2" to="/times">
                시간표
              </NavLink> */}
            <NavLink className="btn btn-success m-2" to="/lessons">
              레슨기록부
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/jindoChart">
              진도
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/hbLetter">
              편지보기
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/dropzone">
              파일올리기
              </NavLink>
          </div>
          <div className="col-sm">
            <NavLink className="btn btn-success m-2" to="/bookChart">
              교재차트
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/books">
              교재장부 열람
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/recordBook">
              교재장부 입력
              </NavLink>
          </div>
          <div className="col-sm">
            <NavLink className="btn btn-success m-2" to="/ct">
              교육비입력
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/recordPay">
              교육비 납부 확인
              </NavLink>
            <NavLink className="btn btn-success m-2" to="/nyp">
              미납자 보기
              </NavLink>
          </div>
          <div className="col-sm">
            <NavLink className="btn btn-success m-2" to="/st">
              문자
              </NavLink>
          </div>
        </div>
      </div>
    )}
  </>
}

