import React from "react";

const Input = ({ name, label, error, className, ...rest }) => {
  if (error === '"email" must be a valid email') {
    error = '틀린 이메일입니다'
  }
  if (error === '"password" length must be at least 5 characters long') {
    error = '비밀번호는 최소 5글자이어야 합니다'
  }
  if (error === '"비밀번호" length must be at least 5 characters long') {
    error = '비밀번호는 최소 5글자이어야 합니다'
  }
  if (error === 'Invalid email or password.') {
    error = '이메일 또는 비밀번호가 틀립니다'
  }
  if (error === '"이름" is not allowed to be empty') {
    error = '이름을 입력해 주십시오'
  }
  if (error === '"name" length must be at least 2 characters long') {
    error = '이름은 최소 2글자이어야 합니다'
  }
  if (error === 'User already registered.') {
    error = '이미 등록된 사용자입니다.'
  }
  if (error === '"이름" length must be at least 2 characters long') {
    error = '이름은 최소 2글자이어야 합니다'
  }
  if (error === '"이름" must not have leading or trailing whitespace') {
    error = '이름 앞이나 뒤에 빈칸은 입력하지 말아주세요'
  }
  return (
    <>
      {/* <div className="form-inline">
        <label className="mr-1" htmlFor={name}>
          {label}
        </label>
        <input {...rest} name={name} id={name} className="form-control" />
        {error && <div className="alert alert-danger">{error}</div>}
      </div> */}

      <div className={"form-group " + className}>
        {/* <label className="col-md-4 control-label" for="prependedtext">
                Prepended Text
              </label> */}
        {/* <div className="col-md-4">
          <div className="input-group">
            <span className="input-group-addon">{label}</span> */}
        <label htmlFor={name}>{label}</label>
        <input className="form-control " {...rest} name={name} id={name} />
        {error && <div className="alert alert-danger">{error}</div>}
        {/* </div> */}
        {/* <p className="help-block">help</p> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Input;
