import React, { Component } from "react";
import { getStudentsBus as getStudents } from "../../services/studentService";
import NameButtons from "./nameButtons1";
import { btnStyle1 } from "../common/setting";

class Kids extends Component {
  //#region
  state = {
    students: [],
    car: [],
    noCar: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };
  kidsUsingCar = [];
  async componentDidMount() {
    const { data: students } = await getStudents();
    this.kidsUsingCar = students.filter(
      m =>
        (m.it1 !== undefined && m.it1 !== null && m.it1 !== "") ||
        (m.ot1 !== undefined && m.ot1 !== null && m.ot1 !== "") ||
        (m.it2 !== undefined && m.it2 !== null && m.it2 !== "") ||
        (m.ot2 !== undefined && m.ot2 !== null && m.ot2 !== "") ||
        (m.it3 !== undefined && m.it3 !== null && m.it3 !== "") ||
        (m.ot3 !== undefined && m.ot3 !== null && m.ot3 !== "") ||
        (m.it4 !== undefined && m.it4 !== null && m.it4 !== "") ||
        (m.ot4 !== undefined && m.ot4 !== null && m.ot4 !== "") ||
        (m.it5 !== undefined && m.it5 !== null && m.it5 !== "") ||
        (m.ot5 !== undefined && m.ot5 !== null && m.ot5 !== "")
    );
    this.kidsUsingCar = this.kidsUsingCar.map(m => m._id);
    this.setState({ students: students });
  }
  //#endregion
  render() {
    return (
      <>
        <div>
          {this.props.user && this.props.user.isAdmin && (
            <div>
              <div className="badge badge-info">useBus.jsx</div>
            </div>
          )}
          <button className="badge badge-pill badge-warning mx-2">
            {`학원차 이용자: ${this.kidsUsingCar.length} 명`}
          </button>
          <button className="badge badge-pill badge-secondary  mx-5">
            {`비이용자: ${this.state.students.length -
              this.kidsUsingCar.length} 명`}
          </button>
          {this.props.user && this.props.user.isTeacher && (
            < i className="fas fa-bus-alt fa-lg" onClick={() => {
              this.props.history.push("/rides");
            }} style={btnStyle1}></i>
          )}
          {this.props.user && this.props.user.isTeacher && (
            < i className="fas fa-chalkboard-teacher fa-lg" onClick={() => {
              this.props.history.push("/Chuls5");
            }} style={btnStyle1}
            ></i>
          )
          }
        </div>
        <NameButtons
          color="btn-warning"
          textColor="text-white"
          students={this.state.students}
          car={this.kidsUsingCar}
        />
      </>
    );
  }
}

export default Kids;
