import React from "react";
import SelectTime from "./selectTime";
import { HandlerConsumer } from "../new/handler";

const MoveB = props => {
  if (props.student.show && !props.student.edit) {
    if (
      props.student.move &&
      props.student.temp.onoff === props.onoff &&
      props.student.temp.time === props.time &&
      props.student.temp.day === props.day
    ) {
      return <SelectTime {...props} />;
    } else {
      return (
        <HandlerConsumer>
          {handler => (
            <button
              className="btn btn-info ml-1"
              onClick={() =>
                handler.onMoveButton({
                  student: props.student,
                  onoff: props.onoff,
                  time: props.time,
                  dow: props.day
                })
              }
            >{`이동`}</button>
          )}
        </HandlerConsumer>
      );
    }
  }
  return null;
};
export default MoveB;
