import Raven from "raven-js";

function init() {
  Raven.config("https://83192e6da8b449f8885d4a70970967d3@sentry.io/1342178", {
    release: "0-50-0",
    environment: "development-test"
  }).install();
}

function log(error) {
  Raven.captureException(error);
}

export default {
  init,
  log
};
