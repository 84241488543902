import React from "react";
import Modal from "react-responsive-modal";
import PianoRoom from './pianoRoom'
import Filename from '../common/filename';

export const showPianoModal = ({
  win2open,
  modalStudent,
  pianos,
  thisScope,
  socket
}) => {
  const handleSelectPiano = ({ piano, student }) => {
    let params = {
      pianoName: piano.name,
      studentId: student._id,
      student: student.name,
      teacher: thisScope.user.name
    };
    socket.emit("piano", params, () => thisScope.setPianos());
    thisScope.setState({ win2open: false });
  };

  return (
    <Modal
      open={win2open}
      onClose={() => onCloseModal("win2", thisScope)}
      center
      className="btn btn-warning"
    >
      {Filename('showPianos.jsx')}
      <ShowPianos
        student={modalStudent}
        pianos={pianos}
        onSelectPiano={handleSelectPiano}
      />
      <button
        className="btn btn-info m-2"
        onClick={() => {
          thisScope.setState({ win2open: false });
        }}
      >
        취소
      </button>
    </Modal>
  );
};

const ShowPianos = props => {
  let pianos = props.pianos;
  return pianos.map(piano => <PianoRoom piano={piano}
    onSelectPiano={props.onSelectPiano}
    student={props.student}
  />);
};

// of modals

export const onCloseModal = (win, thisScope) => {
  if (win === "win1") thisScope.setState({ win1open: false });
  if (win === "win2") thisScope.setState({ win2open: false });
};

export const handleOpenWin = ({ student, thisScope }) => {
  thisScope.setState({
    win1open: true,
    modalStudent: student
  });
};

export const handleCancel = thisScope => {
  thisScope.setState({ win1open: false });
};