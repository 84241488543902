import React from "react";
import HSBar from "./hsBar";
import { dates } from "./colorCode";

const MonthBar = props => {
  // https://www.w3schools.com/colors/colors_names.asp
  // https://www.rapidtables.com/web/color/Web_Color.html
  let data = dates.map(function (item, index) {
    return {
      // name: index + 1 + "월",
      value: item,
      description: index % 12 + 1 + "월",
      // color: props.color[15]
      // color: hexToRgb(props.color[index])
      color: "rgb(255, 255, 255)"
    };
  });
  return (
    <>
      <HSBar
        height={20}
        showTextIn
        id="new_id"
        fontColor="rgb(50,20,100)"
        data={data}
        style={{
          stroke: "#000000",
          strokeWidth: 6
        }}
      />
    </>
  );
};
export default MonthBar