import React from "react";
import MyImage from "./myImage";
import { 배지표시 } from "./chulsFunc";
import Info from './info'
import useLongPress from "./useLongPress";

const StudentBtn = (b, showPic, showAjNum, showTel, onOpenWin, handleOnClick) => {

  const onLongPress = () => {
    onOpenWin(b);
  };

  const onClick = () => {
    handleOnClick(b)
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  return (
    <div {...longPressEvent}>
      {(showPic || b.showPic) && <MyImage img={`${b.name}`} />}
      {b.name}
      {배지표시(b)}
      <Info show={showAjNum} num={b.ajNum} />
      <Info show={showTel} num={b.studPhone} />
    </div >
  );
}

export default StudentBtn;
