import React, { Component } from "react";
import { createKey } from "./func7";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column, lessons) => {
    if (column.path === "attendNum" && item.attendNum && item.attendNum !== "")
      return "주" + _.get(item, column.path) + "일";
    if (column.content) return column.content(item);

    if (
      column.path === "it1" ||
      column.path === "it2" ||
      column.path === "it3" ||
      column.path === "it4" ||
      column.path === "it5"
    ) {
      const time = _.get(item, column.path);
      if (time !== undefined && time !== "" && time !== null) {
        return time;
      } else {
        let out;
        for (let l of lessons) {
          if (l.lesson.length > 0) {
            if (
              item._id === l.lesson[0].studentId &&
              column.path === "it1" &&
              l.day === 1
            ) {
              out = l.lesson;
              break;
            }
            if (
              item._id === l.lesson[0].studentId &&
              column.path === "it2" &&
              l.day === 2
            ) {
              out = l.lesson;
              break;
            }
            if (
              item._id === l.lesson[0].studentId &&
              column.path === "it3" &&
              l.day === 3
            ) {
              out = l.lesson;
              break;
            }
            if (
              item._id === l.lesson[0].studentId &&
              column.path === "it4" &&
              l.day === 4
            ) {
              out = l.lesson;
              break;
            }
            if (
              item._id === l.lesson[0].studentId &&
              column.path === "it5" &&
              l.day === 5
            ) {
              out = l.lesson;
              break;
            }
          }
        }
        //todo:등하시: 레슨을 두개 찾아서 서버에서 보내주는데 어떤것을 먼저 찾아서 보여주는지 확인해야함.
        if (out !== undefined) {
          let temp = new Date(out[0].onTime);
          temp.setHours(temp.getHours() - 12);
          return (
            <span>
              {temp.toJSON().substring(11, 13) === "00"
                ? "12" + temp.toJSON().substring(14, 16)
                : temp.toJSON().substring(11, 12) === "0"
                  ? temp.toJSON().substring(12, 16)
                  : temp.toJSON().substring(11, 16)}
            </span>
          );
        } else return null;
      }
    } else return _.get(item, column.path);
  };



  render() {
    const { data, columns, lessons } = this.props;
    return (
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            {columns.map(column => (
              <td
                className="text-center"
                key={createKey(item, column)}
                style={{ padding: "1px" }}
              >
                {((column.path === "it1" &&
                  ((item.ip1 && item.ip1.substring(0, 1) === "+") ||
                    (item.ip1 && item.ip1.substring(0, 1) === "*"))) ||
                  (column.path === "it2" &&
                    ((item.ip2 && item.ip2.substring(0, 1) === "+") ||
                      (item.ip2 && item.ip2.substring(0, 1) === "*"))) ||
                  (column.path === "it3" &&
                    ((item.ip3 && item.ip3.substring(0, 1) === "+") ||
                      (item.ip3 && item.ip3.substring(0, 1) === "*"))) ||
                  (column.path === "it4" &&
                    ((item.ip4 && item.ip4.substring(0, 1) === "+") ||
                      (item.ip4 && item.ip4.substring(0, 1) === "*"))) ||
                  (column.path === "it5" &&
                    ((item.ip5 && item.ip5.substring(0, 1) === "+") ||
                      (item.ip5 && item.ip5.substring(0, 1) === "*"))) ||
                  (column.path === "ot1" &&
                    ((item.op1 && item.op1.substring(0, 1) === "+") ||
                      (item.op1 && item.op1.substring(0, 1) === "*"))) ||
                  (column.path === "ot2" &&
                    ((item.op2 && item.op2.substring(0, 1) === "+") ||
                      (item.op2 && item.op2.substring(0, 1) === "*"))) ||
                  (column.path === "ot3" &&
                    ((item.op3 && item.op3.substring(0, 1) === "+") ||
                      (item.op3 && item.op3.substring(0, 1) === "*"))) ||
                  (column.path === "ot4" &&
                    ((item.op4 && item.op4.substring(0, 1) === "+") ||
                      (item.op4 && item.op4.substring(0, 1) === "*"))) ||
                  (column.path === "ot5" &&
                    ((item.op5 && item.op5.substring(0, 1) === "+") ||
                      (item.op5 && item.op5.substring(0, 1) === "*")))) && (
                    <span className="text-success">
                      {this.renderCell(item, column, lessons)}
                    </span>
                  )}
                {((column.path === "it1" &&
                  !(
                    (item.ip1 && item.ip1.substring(0, 1) === "+") ||
                    (item.ip1 && item.ip1.substring(0, 1) === "*")
                  )) ||
                  (column.path === "it2" &&
                    !(
                      (item.ip2 && item.ip2.substring(0, 1) === "+") ||
                      (item.ip2 && item.ip2.substring(0, 1) === "*")
                    )) ||
                  (column.path === "it3" &&
                    !(
                      (item.ip3 && item.ip3.substring(0, 1) === "+") ||
                      (item.ip3 && item.ip3.substring(0, 1) === "*")
                    )) ||
                  (column.path === "it4" &&
                    !(
                      (item.ip4 && item.ip4.substring(0, 1) === "+") ||
                      (item.ip4 && item.ip4.substring(0, 1) === "*")
                    )) ||
                  (column.path === "it5" &&
                    !(
                      (item.ip5 && item.ip5.substring(0, 1) === "+") ||
                      (item.ip5 && item.ip5.substring(0, 1) === "*")
                    )) ||
                  (column.path === "ot1" &&
                    !(
                      (item.op1 && item.op1.substring(0, 1) === "+") ||
                      (item.op1 && item.op1.substring(0, 1) === "*")
                    )) ||
                  (column.path === "ot2" &&
                    !(
                      (item.op2 && item.op2.substring(0, 1) === "+") ||
                      (item.op2 && item.op2.substring(0, 1) === "*")
                    )) ||
                  (column.path === "ot3" &&
                    !(
                      (item.op3 && item.op3.substring(0, 1) === "+") ||
                      (item.op3 && item.op3.substring(0, 1) === "*")
                    )) ||
                  (column.path === "ot4" &&
                    !(
                      (item.op4 && item.op4.substring(0, 1) === "+") ||
                      (item.op4 && item.op4.substring(0, 1) === "*")
                    )) ||
                  (column.path === "ot5" &&
                    !(
                      (item.op5 && item.op5.substring(0, 1) === "+") ||
                      (item.op5 && item.op5.substring(0, 1) === "*")
                    ))) && (
                    <span className="text-danger">
                      {this.renderCell(item, column, lessons)}
                    </span>
                  )}
                {!(
                  column.path === "it1" ||
                  column.path === "it2" ||
                  column.path === "it3" ||
                  column.path === "it4" ||
                  column.path === "it5" ||
                  column.path === "ot1" ||
                  column.path === "ot2" ||
                  column.path === "ot4" ||
                  column.path === "ot5"
                ) && <span>{this.renderCell(item, column, lessons)}</span>}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}
export default TableBody;
