import React from "react";
import ChooseKids from "./chooseKids";

const SelectName = props => {
  const { student, onoff, time, dow: day } = props;
  if (
    student.choose &&
    student.temp.onoff === onoff &&
      student.temp.time === time &&
      student.temp.day === day
  ) {
    return <ChooseKids {...props} />;
  }
  return null;
};

export default SelectName;
