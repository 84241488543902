import http from "./httpService";

const apiEndpoint = "/chuls";

function studentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getStudents() {
  return http.get(apiEndpoint + '/v1'); //강제 재설치용 버전변경
}
export function getStats() {
  return http.get(apiEndpoint + "/stats");
}
// export function getStudent(studentId) {
//   return http.get(studentUrl(studentId));
// }

export function saveStudent(student) {
  if (student._id) {
    const body = { ...student };
    delete body._id;
    delete body.id;
    return http.put(studentUrl(student._id), body);
  }
  // 학생이 없으면 ?: 처리 필요
  // return http.post(apiEndpoint, student);
}
// export function setAttend(student) {
//   if (student._id) {
//     const body = { ...student };
//     delete body._id;
//     return http.put(studentUrl(student._id), body);
//   }

//   return http.post(apiEndpoint, student);
// }
// export function deleteStudent(studentId) {
//   return http.delete(studentUrl(studentId));
// }
