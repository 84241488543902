import React, { Component } from "react";
import fileName from "../../common/func2";
import { btnStyle1 } from "../../common/setting";
import {
  getLessons,
  getLessonsOfStudent,
} from "../../../services/lessonService";
import { getStudentsJindo as getStudents } from "../../../services/studentService";
import {
  getHagAge,
  진도번호,
  isSameBook,
  book,
  getTodayYYYYMMDD
} from "../../common/jindoFunc";
import { fieldSorter } from "../../common/func1";
import auth from "../../../services/authService";
export default class Lessons extends Component {
  state = {
    lessons: [],
    students: [],
    date: "",
    date1: "",
    table: [],
    count: 0,
    book: [...book]
  };
  lessons = [];
  async componentDidMount() {
    const today = getTodayYYYYMMDD();
    const { data: lessons } = await getLessons({ date: today });
    const { data: students } = await getStudents();
    this.setState({ lessons, students, date: today, date1: today });
  }
  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.setState(state);
  };
  writeTable = (book, 교재, table, showNumber) => {
    function write(i, preNum1, line1, m, table, showNumber) {
      let preNum = preNum1;
      let line = line1;
      if (preNum === 0) {
        line[i] = m.student + " " + (showNumber ? m.num : "");
        preNum = m.num;
      } else {
        if (m.num === preNum) {
          if (line[i] === null)
            line[i] = m.student + " " + (showNumber ? m.num : "");
          else {
            table.push(line);
            line = [null, null, null, null, null, null, null, null, null];
            line[i] = m.student + " " + (showNumber ? m.num : "");
          }
        }
        if (m.num !== preNum) {
          table.push(line);
          line = [null, null, null, null, null, null, null, null, null];
          line[i] = m.student + " " + (showNumber ? m.num : "");
          preNum = m.num;
        }
      }
      return { preNum: preNum, line: line };
    }
    book = book.filter(b => b.pianoBan !== "");
    book = book
      .sort(fieldSorter(["student"]))
      .sort((a, b) => (parseInt(a.num) > parseInt(b.num) ? -1 : 1));
    let line = [
      교재.title + " (" + 교재.number + ")",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ];
    let preNum = 0;
    for (let m1 of book) {
      const m = m1;
      const hagAge = getHagAge(m.studentId, this.state.students);
      if (hagAge !== null) {
        if (m.num > 0) {
          if (
            hagAge.schoolLong !== undefined &&
            hagAge.schoolLong.includes("중학교")
          ) {
            const a = write(1, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }
          if (
            hagAge.hag === 6 &&
            hagAge.schoolLong !== undefined &&
            hagAge.schoolLong.includes("초등학교")
          ) {
            const a = write(2, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }
          if (
            hagAge.hag === 5 &&
            hagAge.schoolLong !== undefined &&
            hagAge.schoolLong.includes("초등학교")
          ) {
            const a = write(3, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }
          if (
            hagAge.hag === 4 &&
            hagAge.schoolLong !== undefined &&
            hagAge.schoolLong.includes("초등학교")
          ) {
            const a = write(4, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }
          if (
            hagAge.hag === 3 &&
            hagAge.schoolLong !== undefined &&
            hagAge.schoolLong.includes("초등학교")
          ) {
            const a = write(5, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }
          if (
            hagAge.hag === 2 &&
            hagAge.schoolLong !== undefined &&
            hagAge.schoolLong.includes("초등학교")
          ) {
            const a = write(6, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }
          if (
            hagAge.hag === 1 &&
            hagAge.schoolLong !== undefined &&
            hagAge.schoolLong.includes("초등학교")
          ) {
            const a = write(7, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }

          if (
            (hagAge.age === 6 || hagAge.age === 7) &&
            hagAge.schoolLong !== undefined &&
            (!hagAge.schoolLong.includes("초등학교") &&
              !hagAge.schoolLong.includes("초등학교"))
          ) {
            const a = write(8, preNum, line, m, table, showNumber);
            preNum = a.preNum;
            line = a.line;
          }
        }
      }
    }
    table.push(line);
    return table;
  };
  getPastJindoNumber = (book, lessons) => {
    let jindoNum = "";
    for (let l of lessons) {
      if (parseInt(l.date) < parseInt(book.date) && 진도번호(l.pJin) > 0) {
        jindoNum = 진도번호(l.pJin);
        break;
      }
    }
    return jindoNum;
  };
  getPastJindoNumber1 = (book, lessons) => {
    let jindoNum = "";
    for (let l of lessons) {
      if (parseInt(l.date) < parseInt(book.date) && 진도번호(l.pJin) > 0) {
        jindoNum = 진도번호(l.pJin);
        break;
      }
    }
    return jindoNum;
  };
  getLessonsOfStudent1 = async studentId => {
    const { data: lessons } = await getLessonsOfStudent(studentId);
    return lessons;
  };
  findJindoNumber = (lessons, book) => {
    //#region
    let temp = [];
    lessons.map(async l => {
      if (진도번호(l.pJin) !== "") {
        temp.push({
          ...l,
          num: 진도번호(l.pJin),
          book: book
        });
      } else {
        temp.push({
          ...l,
          num: this.getRecentJindo(l),
          book: book
        });
      }
    });
    return temp;
  };

  //todo: 결석시 최근진도까지 포함된 진도를 서버에서 보내주면 이런 로직과 프라미스가 필요없게 된다.
  //todo: 진도차트 프린트기능 만들것
  //todo:진도차트 한달전 진도도 다른색으로 오른쪽 위에 표시
  getRecentJindo = l => {
    const lessons = this.getLessonsOfStudent1(l.studentId);
    return lessons.then(res => {
      // 여기서 과거진도를 가져올때 같은 책에서만 진도번호를 찾고있는게 맞을까? 맞겠지? 에러가 발생하면 나중에 알게 되겠지.
      let jindoNum = "";
      for (let r of res) {
        if (parseInt(r.date) < parseInt(l.date) && 진도번호(r.pJin) > 0) {
          jindoNum = 진도번호(r.pJin);
          break;
        }
      }
      return jindoNum;
    });
  };
  table1 = [];
  handleClick = async () => {
    const table = [];
    let bookWithPromise = [];
    let promiseAll = [];
    const { data: lessons } = await getLessons({ date: this.state.date1 }); //
    // const { data: lessons } = await getLessons({ date: "20190614" });
    const students = this.state.students.map(s => ({
      ...s,
      student: s.name,
      studentId: s._id,
      date: this.state.date1
    }));

    const book = this.state.book.map(b =>
      //todo:선택버튼 만들어서 날짜의 진도차트 또는 현재 진도차트를 선택해서 그리도록 하자.
      // this.findJindoNumber(lessons.filter(m => isSameBook(m.pJin, b)), b)
      this.findJindoNumber(students.filter(m => isSameBook(m.pJin, b)), b)
    );
    book.map(b => {
      let promise = [];
      let promiseLesson = [];
      b.map(n => {
        let isPromise = typeof n.num.then === "function";
        if (isPromise) {
          promiseAll.push(n.num);
          promise.push(n.num);
          promiseLesson.push(n);
        }
        return true
      });
      bookWithPromise.push({ dontUse: b, pro: promise, les: promiseLesson });
      return true
    });
    Promise.all(promiseAll).then(res => {
      this.state.book.map(b23 => {
        for (let j = 0; j < bookWithPromise.length; j++) {
          if (b23.title === bookWithPromise[j].dontUse[0].book.title) {
            let b = [];
            Promise.all(bookWithPromise[j].pro).then(res => {
              let bb = [];
              for (let i = 0; i < res.length; i++) {
                bookWithPromise[j].les[i].num = res[i];
                bb.push(bookWithPromise[j].les[i]);
              }
              b = bookWithPromise[j].dontUse;
              this.table1 = this.writeTable(b, b[0].book, table, true); //todo: showNumber 버튼 만들것
            });
          }
        }
        return true
      });
      //===============결과 출력========================================================
      let count = 0;
      let table2 = [];
      let find = false;
      this.state.book.map(b => {
        for (let i = 0; i < this.table1.length; i++) {
          if (
            find === false &&
            b.title + " (" + b.number + ")" === this.table1[i][0]
          )
            find = true;
          else if (
            find === true &&
            this.table1[i][0] !== null &&
            b.title + " (" + b.number + ")" !== this.table1[i][0]
          )
            find = false;
          if (find) {
            table2.push(this.table1[i]);
          }
        }
        return true
      });
      table2.map(m => {
        m.map(l => {
          if (l !== null) count = count + 1;
          return true
        });
        return true
      });
      count = count - this.state.book.length;
      this.setState({
        lessons,
        date: this.state.date1,
        table: table2,
        count: count
      });
    });
  };
  // };
  user = auth.getCurrentUser();
  render() {
    return (
      <>
        {fileName("jindoChart.jsx", this.user)}

        <div className="form-row align-items-center">
          <div className="col-auto">
            <label className="sr-only" htmlFor="inlineFormInput">
              Name
            </label>
            <input
              value={this.state.date1}
              name="date1"
              type="text"
              className="form-control mb-2"
              id="inlineFormInput"
              placeholder="날짜입력"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-auto">
            <button
              onClick={() => this.handleClick()}
              className="btn btn-primary mr-2"
            >
              조회
            </button>
            {/* //todo: Change to <Ibtn/> */}
            <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
              this.props.history.push("/Chuls5");
            }} style={btnStyle1}
            ></i>
          </div>
        </div>
        <ChartTable table={this.state.table} count={this.state.count} />
      </>
    );
  }
}
const ChartTable = props => {
  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">{props.count ? props.count + "명" : null}</th>
            <th scope="col">중</th>
            <th scope="col">초6</th>
            <th scope="col">초5</th>
            <th scope="col">초4</th>
            <th scope="col">초3</th>
            <th scope="col">초2</th>
            <th scope="col">초1</th>
            <th scope="col">유</th>
          </tr>
        </thead>
        <tbody>
          <Chart table={props.table} />
        </tbody>
      </table>
    </>
  );
};
const Chart = props => {
  return props.table.map(l => {
    return (
      <>
        <tr>
          <td style={{ minWidth: 100 }}>{l[0]}</td>
          <td style={{ minWidth: 100 }}>{l[1]}</td>
          <td style={{ minWidth: 100 }}>{l[2]}</td>
          <td style={{ minWidth: 100 }}>{l[3]}</td>
          <td style={{ minWidth: 100 }}>{l[4]}</td>
          <td style={{ minWidth: 100 }}>{l[5]}</td>
          <td style={{ minWidth: 100 }}>{l[6]}</td>
          <td style={{ minWidth: 100 }}>{l[7]}</td>
          <td style={{ minWidth: 100 }}>{l[8]}</td>
        </tr>
      </>
    );
  });
};
