import React from "react";
import NameBtn from "./nameBtn";

const NameButton = props => {
  return (
    <>
      <NameBtn {...props} />
    </>
  );
};
export default NameButton;
