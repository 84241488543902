import React, { Component } from "react";
import fileName from "../../common/func2";
import { getLessonsOrdeyByStudentDate } from "../../../services/lessonService";
import { getStudents } from "../../../services/studentService";
import { allBooks } from "../common/bookChartFunc";
import Table from './bookChartTable'
import Ibtn from '../../common/i'

export default class BookChart extends Component {

  state = {
    lessons: [],
    lessonsAll: [],
    date: "",
    date1: "",
    excelData: [],
    excelHeader: [],
    year: "",
    win1open: false,
    td_pJin: "",
    td: {},
    studentBooks: []
  };

  async componentDidMount() {
    const { data: lessonsAll } = await getLessonsOrdeyByStudentDate();
    const { data: students } = await getStudents();
    let studentBooks = students.map(s => {
      return allBooks(s._id, s.name, lessonsAll);
    });
    this.setState({
      lessonsAll,
      studentBooks,
      students
    });
  }

  render() {
    let students = this.state.students;
    if (students !== undefined) {
      students = students.filter(
        //todo: 반구분을 global로 만들자
        s => s.pianoBan === "바하반" || s.pianoBan === "베토벤반"
      );
    }
    return (
      <>

        {fileName("bookChart.jsx", this.props.user)}

        <Ibtn icon="fas fa-chalkboard-teacher fa-lg" onClick={() => this.props.history.push("/Chuls5")} />

        {this.state.studentBooks.length !== 0 && (
          <Table
            students={students}
            studentBooks={this.state.studentBooks}
          />
        )}

      </>
    );
  }
}