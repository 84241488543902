export const handleSetDay = (e, thisScope) => {
  thisScope.setState({ day: e.currentTarget.value * 1 });
};

export const ch = {
  onShowHak: thisScope => {
    thisScope.setState({ showHak: !thisScope.state.showHak });
  },

  onShowTime: thisScope => {
    thisScope.setState({ showTime: !thisScope.state.showTime });
  },

  onHakOrder: thisScope => {
    if (!thisScope.state.hakOrder) {
      thisScope.setState({ showHak: true, order: "hak" });
    }
  },

  onTimeOrder: thisScope => {
    if (!thisScope.state.timeOrder) {
      thisScope.setState({ showTime: true, order: "time" });
    }
  },

  onNameOrder: thisScope => {
    if (!thisScope.state.nameOrder) {
      thisScope.setState({ order: "name" });
    }
  }
};
