import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import auth from "../../services/authService";
import Table from "../common/table4";

class FeesTable extends Component {
  inColumns = [
    { path: "name", label: "이름" },
    { path: "payday", label: "납부일자" },
    { path: "due1", label: "납부일1" },
    { path: "due2", label: "납부일2" },
    { path: "due3", label: "납부일3" },
    { path: "due4", label: "납부일4" },
    { path: "paid1", label: "결제일1" },
    { path: "paid2", label: "결제일2" },
    { path: "paid3", label: "결제일3" },
    { path: "paid4", label: "결제일4" }
  ];
  outColumns = [
    { path: "name", label: "이름" },
    { path: "payday", label: "납부일" },
    { path: "tag", label: "구분" }, // place for tag
    { path: "due1", label: "1" },
    { path: "due2", label: "2" },
    { path: "due3", label: "3" },
    { path: "due4", label: "4" }
  ];

  deleteColumn = {
    // key: "delete",
    // content: student => (
    //   <button
    //     onClick={() => this.props.onDelete(student)}
    //     className="btn btn-danger btn-sm"
    //   >
    //     Delete
    //   </button>
    // )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.outColumns.push(this.deleteColumn);
  }

  render() {
    const { students, onSort, sortColumn } = this.props;
    return (
      <>
        {this.props.user && this.props.user.isAdmin && (
          <div>
            <div className="badge badge-info">pay/feesTable2.jsx</div>
          </div>
        )}
        <Table
          outColumns={this.outColumns}
          inColumns={this.inColumns}
          data={students}
          sortColumn={sortColumn}
          onSort={onSort}
          className="table"
        />
      </>
    );
  }
}

export default withRouter(FeesTable);
