import { makePlanObj } from "../common/ridesFunc";

// export const handleConfirm = () => {
//   this.setState({ win1open: false });
// };

// export const handleCancel = () => {
//   this.setState({ win1open: false });
// };

export const handleDelAll = ({ fields, chuls, user, toast, socket }) => {
  let all = [];
  chuls.map(m =>
    all.push({
      id: m._id
    })
  );
  let params1 = {
    fields: fields,
    all: all,
    room: "탑승명단",
    name: user.name
  };
  socket.emit("delAllPlans", params1, () => toast("All plans deleted!!"));
};

export const handleRecall = ({ title, chuls, user, toast, socket }) => {
  let all = [];
  chuls.map(m => {
    all.push({
      id: m._id
    });
    return null;
  });
  let params1 = {
    title: title,
    all: all,
    room: "탑승명단",
    name: user.name
  };
  socket.emit("recallPlan", params1, () => {
    toast(`[${title}] 저장본이 소환되었읍니다.`);
    this.dataDown();
  });
};

export const handleSaveMaster = ({ title, chuls, user, toast, socket }) => {
  let all = [];
  chuls.map(m =>
    all.push({
      id: m._id,
      name: m.name,
      oc: makePlanObj(m)
    })
  );
  let params1 = {
    title: title,
    all: all,
    room: "탑승명단",
    name: user.name
  };
  // debugger;
  socket.emit("savePlan", params1, () => toast("원본으로 저장되었읍니다."));
};

export const handleSetDay = (e, thisScope) => {
  thisScope.setState({ dow: e.currentTarget.value * 1 });
};
