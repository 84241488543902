import React from "react";
import PropTypes from 'prop-types'
import { getCurrentUser } from "../../services/authService";

const user = getCurrentUser()
const Filename = (filename) => user && user.isAdmin && (<pre style={{ margin: '0', color: 'red', position: 'relative', left: '80%', top: '3rem' }}>{filename}</pre>)

Filename.propTypes = {
  file: PropTypes.string.isRequired,
}

export default Filename