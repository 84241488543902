import React, { Component } from "react";
import fileName from "../../common/func2";
import Loading from '../../common/loading'
import { getLessons } from "../../../services/lessonService";
import { getStudents } from "../../../services/studentService";
import { fieldSorter, chunkArray } from "../../common/func1";
import { btnStyle1 } from "../../common/setting";
import auth from "../../../services/authService";

export default class Lessons extends Component {
  state = { lessons: [], date: "", date1: "" };
  async componentDidMount() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + mm + dd;
    const { data: lessons } = await getLessons({ date: today });
    const { data: students } = await getStudents();
    this.setState({ lessons, date: today, date1: today, students });
  }
  async componentDidUpdate() { }
  addPianoBan = (lessons, students) => {
    let lessons1 = [...lessons];
    for (let i = 0; i < lessons1.length; i++) {
      for (let j = 0; j < lessons1[i].length; j++) {
        let pianoBan;
        for (let s of students) {
          if (s._id === lessons1[i][j].studentId) {
            pianoBan = s.pianoBan;
            break;
          }
        }
        lessons1[i][j].pianoBan = pianoBan;
      }
    }
    return lessons1;
  };

  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.setState(state);
  };
  noData = true;
  handleClick = async () => {
    let lessons = [[]];
    // const { data: lessons } = await getLessons({ date: this.state.date1 });
    var lessons1 = await getLessons({ date: "20201019" });
    var lessons2 = await getLessons({ date: "20201020" });
    var lessons3 = await getLessons({ date: "20201021" });
    var lessons4 = await getLessons({ date: "20201015" });
    var lessons5 = await getLessons({ date: "20201016" });
    Promise.all([lessons1, lessons2, lessons3, lessons4, lessons5]).then(() => {
      lessons[1] = lessons1.data;
      lessons[2] = lessons2.data;
      lessons[3] = lessons3.data;
      lessons[4] = lessons4.data;
      lessons[5] = lessons5.data;
      this.noData = false;
      lessons[1] = lessons[1].filter(m => m.onTime !== undefined);
      lessons[2] = lessons[2].filter(m => m.onTime !== undefined);
      lessons[3] = lessons[3].filter(m => m.onTime !== undefined);
      lessons[4] = lessons[4].filter(m => m.onTime !== undefined);
      lessons[5] = lessons[5].filter(m => m.onTime !== undefined);
      // lessons에 피아노반 합치기
      // 서버에서 준비해서 보낼수도 있음

      lessons = this.addPianoBan(lessons, this.state.students);
      //todo: 등원표 결석자 과거기록 나오게하기
      //todo: 등원표 빨간줄긋기
      lessons[1] = lessons[1].sort(fieldSorter(["onTime"]));
      lessons[2] = lessons[2].sort(fieldSorter(["onTime"]));
      lessons[3] = lessons[3].sort(fieldSorter(["onTime"]));
      lessons[4] = lessons[4].sort(fieldSorter(["onTime"]));
      lessons[5] = lessons[5].sort(fieldSorter(["onTime"]));
      this.setState({
        lessons,
        date: ["2", "3", "4", "5", "6"],//todo: 여기가 제목에 날짜임
        buttonClicked: true
      });
    });
  };
  user = auth.getCurrentUser();
  //todo:시간표 날짜 자동으로 만들자. 2군데 고칠것
  //todo:시간표 날짜마다 출석인원수 넣고 시간대마다 인원수 넣으면 좋겠다
  //todo:시간표에서 결석한 학생들은 전주 등원시각을 찾아서 표시하게 하자
  render() {
    // this.state.lessons = this.state.lessons.filter(
    //   m => m.date === this.state.date
    // );
    let day = [1, 2, 3, 4, 5]; // <---- 날짜 아님. 변경하지 마시오. 에러남.
    if (this.state.lessons === undefined && this.state.buttonClicked) {
      return <Loading></Loading>
    }
    else {
      return (
        <>
          <div className='noPrint'>
            {fileName("times.jsx", this.user)}
            <pre>먼저 lesson collection 다운받고 times.jsx에서 2군데 고쳐주고 출력.
            조회단추 앞에 날짜입력은 상관없음 </pre>
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInput">
                  Name
            </label>
                <input
                  value={this.state.date1}
                  name="date1"
                  type="text"
                  className="form-control mb-2"
                  id="inlineFormInput"
                  placeholder="날짜입력"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-auto">
                <button
                  onClick={() => this.handleClick()}
                  className="btn btn-primary mr-2"
                >
                  조회
            </button>
                {/* {/* //todo: Change to <Ibtn/> */}
                <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
                  this.props.history.push("/Chuls5");
                }} style={btnStyle1}
                ></i>
              </div>
            </div>

          </div>

          {
            !this.noData && (
              <LessonsTable
                day={day}
                lessons={this.state.lessons}
                date={this.state.date}
              />
            )
          }
        </ >
      );
    }
  }
}

const hour = [1, 2, 3, 4, 5, 6, 7];

const Min20 = ({ i, t, hour, date, lessons, print, day }) => {
  return (
    <tr>
      <td
        style={{
          padding: "1px"
        }}
      >
        {hour ? hour + "시부" : null}
        {hour && <br />}
        <span style={{ color: "#b3e0ff" }}>{t[i][0].substring(3, 5)}</span>
      </td>
      {day.map(day => (
        <td
          style={{
            padding: "1px"
          }}
        >
          <Lesson2
            print={print}
            date={date}
            lessons={lessons}
            from={t[i][0]}
            to={t[i][1]}
            day={day}
          />
        </td>
      ))}
    </tr>
  );
};

const OneTime = ({ hour, date, lessons, print, day }) => {
  const t = [];
  t[0] = [11 + hour + ":40", 12 + hour + ":00"];
  t[1] = [12 + hour + ":00", 12 + hour + ":20"];
  t[2] = [12 + hour + ":20", 12 + hour + ":40"];
  return (
    <>
      <Min20
        i={0}
        t={t}
        hour={hour}
        date={date}
        lessons={lessons}
        print={print}
        day={day}
      />
      <Min20
        i={1}
        t={t}
        hour={false}
        date={date}
        lessons={lessons}
        print={print}
        day={day}
      />
      <Min20
        i={2}
        t={t}
        hour={false}
        date={date}
        lessons={lessons}
        print={print}
        day={day}
      />
    </>
  );
};

const isInTimeRange = (start, end, time) => {
  const s = new Date(2000, 0, 1, start.substring(0, 2), start.substring(3, 6));
  const e = new Date(2000, 0, 1, end.substring(0, 2), end.substring(3, 6));
  const t = new Date(2000, 0, 1, time.substring(0, 2), time.substring(3, 6));
  return t - s >= 0 && e - t > 0 ? true : false;
};
class LessonsTable extends Component {
  render() {
    return (
      <>
        <table className="table table-bordered m-0 p-0">
          <thead>
            <tr>
              <th scope="col" />
              {this.props.day.map(day => (
                <th scope="col">
                  {day === 1 && "월"}
                  {day === 2 && "화"}
                  {day === 3 && "수"}
                  {day === 4 && "목"}
                  {day === 5 && "금"}
                  {day === 1 && "(" + this.props.date[day - 1] + ")"}
                  {day === 2 && "(" + this.props.date[day - 1] + ")"}
                  {day === 3 && "(" + this.props.date[day - 1] + ")"}
                  {day === 4 && "(" + this.props.date[day - 1] + ")"}
                  {day === 5 && "(" + this.props.date[day - 1] + ")"}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {hour.map(h => (
              <OneTime
                hour={h}
                date={this.props.date}
                lessons={this.props.lessons}
                print={true}
                day={this.props.day}
              />
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

class Lesson2 extends Component {
  render() {
    if (this.props.print) {
      let lesson1 = [];
      this.props.lessons[this.props.day].map(lesson => {
        if (
          isInTimeRange(
            this.props.from,
            this.props.to,
            lesson.onTime.substring(11, 16)
          )
        ) {
          lesson1.push(lesson);
        }
        return null
      });
      lesson1 = lesson1.sort(fieldSorter(["student"]));
      lesson1 = chunkArray(lesson1, 2);
      return lesson1.map(lesson => (
        <LessonP
          date={this.props.date}
          lesson={lesson}
          from={this.props.from}
          to={this.props.to}
          day={this.props.day}
        />
      ));
    } else {
      return this.props.lessons[this.props.day].map(lesson => (
        <Lesson
          date={this.props.date}
          lesson={lesson}
          from={this.props.from}
          to={this.props.to}
          day={this.props.day}
        />
      ));
    }
  }
}

class LessonP extends Component {
  render() {
    return (
      // <div>
      //   {/* {this.props.lesson.date} */}
      //   {/* <badge className="badge badge-info mr-2">
      //     {this.props.lesson.onTime.substring(11, 16)}
      //   </badge> */}
      <>
        <div>
          <span style={{ color: "#b3e0ff" }}>
            {this.props.lesson[0].onTime.substring(14, 16)}
          </span>
          {this.props.lesson[0].pianoBan === "바하반" ? (
            <span style={{ backgroundColor: "#ffffB3" }}>
              {this.props.lesson[0].student + " "}
            </span>
          ) : (
              <span>{this.props.lesson[0].student + " "}</span>
            )}
          {this.props.lesson[1] && (
            <>
              <span style={{ color: "#b3e0ff" }}>
                {this.props.lesson[1].onTime.substring(14, 16)}
              </span>
              {this.props.lesson[1].pianoBan === "바하반" ? (
                <span style={{ backgroundColor: "#ffffb3" }}>
                  {this.props.lesson[1].student + " "}
                </span>
              ) : (
                  <span>{this.props.lesson[1].student + " "}</span>
                )}
            </>
          )}
        </div>
      </>
      // {/* {this.진도(this.props.lesson.pJin)}
      // {this.진도1(this.props.lesson.pJin)} */}
      // {/* <badge className="badge badge-warning mr-2 ml-2">
      //   {this.props.lesson.pJin}
      // </badge> */}
      // {/* {this.props.lesson.theory} */}
      // </div>
    );
  }
}
class Lesson extends Component {
  render() {
    if (
      isInTimeRange(
        this.props.from,
        this.props.to,
        this.props.lesson.onTime.substring(11, 16)
        // ) &&
        // // this.props.teacher===this.props.lesson.teacher &&
        // this.props.date===this.props.lesson[this.props.day]
      )
    ) {
      return (
        // <div>
        //   {/* {this.props.lesson.date} */}
        //   {/* <badge className="badge badge-info mr-2">
        //     {this.props.lesson.onTime.substring(11, 16)}
        //   </badge> */}
        <button className="btn btn-light p-0">
          <badge className="badge badge-info">
            {this.props.lesson.onTime.substring(14, 15) === "0" &&
              this.props.lesson.onTime.substring(15, 16)}
            {this.props.lesson.onTime.substring(14, 15) !== "0" &&
              this.props.lesson.onTime.substring(14, 16)}
            분
          </badge>
          {this.props.lesson.pianoBan === "바하반" ? (
            <span style={{ color: "#9999FF" }}>
              {this.props.lesson.student}
            </span>
          ) : (
              <span>{this.props.lesson.student}</span>
            )}
        </button>
        // {/* {this.진도(this.props.lesson.pJin)}
        // {this.진도1(this.props.lesson.pJin)} */}
        // {/* <badge className="badge badge-warning mr-2 ml-2">
        //   {this.props.lesson.pJin}
        // </badge> */}
        // {/* {this.props.lesson.theory} */}
        // </div>
      );
    } else return null;
  }
}
