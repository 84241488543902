import React from "react";
import { HandlerConsumer } from "../new/handler";
const NextCar = props => {
  if (props.onoff === "on") return null;
  if (
    !(
      (props.time[2] === "0" && props.time[3] === "0") ||
      (props.time[2] === "1" && props.time[3] === "0")
    )
  )
    return null;
  let car_num;
  if (props.onoff === "off") {
    if (props.time[2] === "0" && props.time[3] === "0") car_num = 2;
    else if (props.time[2] === "1" && props.time[3] === "0") car_num = 1;
  }

  if (
    props.student.show &&
    props.student.temp.onoff === props.onoff &&
    props.student.temp.time === props.time &&
    props.student.temp.day === props.day
  ) {
    // todo: After running <Move to car2>, you must slide twice. Fix it.
    return (
      !props.student.edit && (
        <HandlerConsumer>
          {handler => {
            return (
              <button
                className="btn btn-info ml-1"
                key={`${car_num}호차로 이동`}
                onClick={() => {
                  handler.onNextCar({
                    student: props.student,
                    onoff: props.onoff,
                    time: props.time,
                    dow: props.day
                  });
                }}
              >
                {`${car_num}호차로 이동`}
              </button>
            );
          }}
        </HandlerConsumer>
      )
    );
  }
  return null;
};

export default NextCar;
