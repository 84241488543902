import React, { Component } from "react";
import fileName from "../../common/func2";
import auth from "../../../services/authService";
import { getLessons } from "../../../services/lessonService";
import { getStudentsJindo as getStudents } from "../../../services/studentService";
import { makeYear, getDates } from "../../common/chulsFunc";
import { 복습포함진도, getThisMonthYYYYMM } from "../../common/jindoFunc";
import { onlyUnique, fieldSorter, generateKey } from "../../common/func1";
// import "./chulBu2.css";
// 20200701 import "../../my.css";
const Print = () => {
  var css = `@page { size: A4 landscape;
      margin: 0;
      margin-right: -3cm;
      width:100%;
      border: 0px ;
      padding: 0px; }
    @page :first {
      margin-left: 0cm;
      margin-right: -3cm;
      width:100%;
      border: 0px ;
      padding: 0px;
    }
    @page :right {
     margin-left: 0cm;
     margin-right: -3cm;
     width:100%;
     border: 0px ;
     padding: 0px;
   }
   @page :left {
     margin-left: 0cm;
     margin-right: -3cm;
     width:100%;
     border: 0px ;
     padding: 0px;
   } `,
    head = document.head || document.getElementsByTagName("head")[0],
    style = document.createElement("style");

  style.type = "text/css";

  style.media = "print";

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  head.appendChild(style);
  window.print();
};
export default class WeekCB extends Component {
  state = {
    lessons: [],
    students: [],
    date: "",
    date1: "",
    month: "",
    year: "",
    td_teacher: "",
    td_pJin: "",
    td: {},
    widthArray: [10, 30, 30, 30, 30, 30, 20],
    fontSize: 11,
    startDate: 6,
    startLessonDay: 3,// 시작 수업일수 
    linesBetween1: 1,
    linesBetween2: 1,
  };
  async componentDidMount() {
    let today = new Date();
    let m = String(today.getMonth() + 1); //January is 0!
    let yyyy = today.getFullYear();
    const { data: lessons } = await getLessons({ month: getThisMonthYYYYMM() });
    const { data: students } = await getStudents();
    this.setState({
      lessons,
      students,
      month: m,
      year: yyyy
    });
  }
  handleChange = e => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value
    });
  };
  handleChangeWithArray = e => {
    let v = e.target.value;
    v = v.split(",");
    this.setState({
      [e.target.name]: [...v]
    });
  };
  // todo: to fix : make local var
  // dates = makeYear();
  dates = makeYear();
  dates2 = "20200110";
  user = auth.getCurrentUser();
  teacherClassName = ["danger", "dark", "primary", "success", "warning"];
  tClass = []; // {teacher : Class}
  render() {
    // to fix
    let dates1 = getDates(2019, 7, this.state.startDate, 19); // 이번주 날짜 세팅

    let teachers = this.state.lessons.map(m => m.teacher);
    teachers = teachers.filter(onlyUnique).sort();
    teachers = ["ES", "HN", "YB", "HR"]; // 선생님 출력순서 바꿈. 바하반,베토벤반
    let students1 = this.state.students.sort(fieldSorter(["pianoBan", "name"]));
    students1 = students1.filter(s => s.pianoBan !== "");
    // let students = students1.map(m => m.name);
    teachers.map((m, index) => {
      const a = {};
      a[m] = this.teacherClassName[index % 5];
      this.tClass.push(a);
      return null
    });

    const teacherTags = () => {
      return (
        <div className="form-row align-items-center" >
          <div className="col-auto">
            {teachers.map((m, index) => (
              <span
                key={generateKey()}
                className={
                  "badge m-2 badge-" + this.teacherClassName[index % 5]
                }
              >
                {m}
              </span>
            ))}
          </div>
        </div >
      )
    }
    const inputBoard = () => {
      return (
        <>
          <input
            name="widthArray"
            value={this.state.widthArray}
            onChange={this.handleChangeWithArray}
            style={{ width: 400, fontSize: 25, textAlign: "center" }}
          />

          <span className="value1">
            <label className="label1" for='fontSize'>font size</label>
            <input
              name="fontSize"
              id="fontSize"
              value={this.state.fontSize}
              onChange={this.handleChange}
              className="input1"
            /></span>
          <span className="value1">
            <label className="label1" for='startDate'>시작일</label><input
              name="startDate"
              id='startDate'
              value={this.state.startDate}
              onChange={this.handleChange}
              className="input1"
            /></span>
          <span className="value1">
            <label className="label1" for='startLessonDay'>수업일수</label><input
              name="startLessonDay"
              id="startLessonDay"
              value={this.state.startLessonDay}
              onChange={this.handleChange}
              className="input1"
            /></span>
          <span className="value1">
            <label className="label1" for='linesBetween1'>빈줄1</label><input
              name="linesBetween1"
              id="linesBetween1"
              value={this.state.linesBetween1}
              onChange={this.handleChange}
              className="input1"
            /></span>
          <span className="value1">
            <label className="label1" for='linesBetween2'>빈줄2</label><input
              name="linesBetween2"
              id="linesBetween2"
              value={this.state.linesBetween2}
              onChange={this.handleChange}
              className="input1"
            /></span>
          <button
            className="btn btn-info mb-2 ml-5 noPrint"
            onClick={() => Print()}
          >
            print
          </button>
        </>
      )
    }
    return (
      <section className="page1" >
        <div>
          {/* <div className="noPrint"> */}
          {fileName("chulBu2.jsx", this.user)}
          {teacherTags()}
          {inputBoard()}
        </div>

        <div className="cols3">
          <div>
            <StudentsTable
              students={students1}
              teachers={teachers}
              dates={dates1}
              month={this.state.month}
              lessons={this.state.lessons}
              tClass={this.tClass}
              fontSize={parseInt(this.state.fontSize)}
              widthArray={this.state.widthArray}
              startDate={this.state.startDate}
              startLessonDay={this.state.startLessonDay}
              linesBetween1={this.state.linesBetween1}
              linesBetween2={this.state.linesBetween2}
            />
          </div>
        </div>
      </section>
    );
  }
}
class StudentsTable extends Component {
  render() {
    const { widthArray, startLessonDay, fontSize } = this.props;
    const { dates } = this.props;
    let key = 0;
    let lessonDays = [...Array(5).keys()];

    let style1 = widthArray.map(w => ({
      minWidth: parseInt(w),
      maxWidth: parseInt(w)
    }));
    let style4and5 = {
      minWidth: parseInt(widthArray[4]) + parseInt(widthArray[5]),
      maxWidth: parseInt(widthArray[4]) + parseInt(widthArray[5]),
      textAlign: "right", paddingRight: 10
    };
    return (
      <>
        <table className="table table-bordered" id="noBackgroundColor" style={{ fontSize: fontSize }}>
          <thead>
            <tr>
              <td style={style1[0]} />
              <td style={style1[1]} />
              <td style={style1[2]} />
              <td style={style1[3]} />
              <td
                style={style4and5}
                colSpan="2"
              >
                수업일수
              </td>
              {lessonDays.map(m => (
                <td key={generateKey()} style={style1[6]}>{m + parseInt(startLessonDay)}</td>
              ))}
            </tr>

            <tr id="field">
              <td style={style1[0]}>#</td>
              <td style={style1[1]}>이름</td>
              <td style={style1[2]}>학년</td>
              <td style={style1[3]}>ID</td>
              <td style={style1[4]}>참고</td>
              <td style={style1[5]}>진도</td>
              <td style={style1[6]}>
                <div style={style1[6]}>
                  {String(dates[0])
                    .substring(6, 8)
                    .substring(0, 1) === "0"
                    ? String(dates[0]).substring(7, 8)
                    : String(dates[0]).substring(6, 8)}
                </div>
                <div style={style1[6]}>월</div>
              </td>
              <td style={style1[6]}>
                <div style={style1[6]}>
                  {String(dates[1])
                    .substring(6, 8)
                    .substring(0, 1) === "0"
                    ? String(dates[1]).substring(7, 8)
                    : String(dates[1]).substring(6, 8)}
                </div>
                <div style={style1[6]}>화</div>
              </td>
              <td style={style1[6]}>
                <div style={style1[6]}>
                  {String(dates[2])
                    .substring(6, 8)
                    .substring(0, 1) === "0"
                    ? String(dates[2]).substring(7, 8)
                    : String(dates[2]).substring(6, 8)}
                </div>
                <div style={style1[6]}>수</div>
              </td>
              <td style={style1[6]}>
                <div style={style1[6]}>
                  {String(dates[3])
                    .substring(6, 8)
                    .substring(0, 1) === "0"
                    ? String(dates[3]).substring(7, 8)
                    : String(dates[3]).substring(6, 8)}
                </div>
                <div style={style1[6]}>목</div>
              </td>
              <td style={style1[6]}>
                <div style={style1[6]}>
                  {String(dates[4])
                    .substring(6, 8)
                    .substring(0, 1) === "0"
                    ? String(dates[4]).substring(7, 8)
                    : String(dates[4]).substring(6, 8)}
                </div>
                <div style={style1[6]}>금</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <TBody
              key={key}
              students={this.props.students}
              dates={dates}
              lessons={this.props.lessons}
              tClass={this.props.tClass}
              linesBetween1={this.props.linesBetween1}
              linesBetween2={this.props.linesBetween2}
            />
          </tbody>
        </table>

        <table className="table table-bordered" id="noBackgroundColor" style={{ fontSize: fontSize }}>
          <tbody>
            <TBody1
              key={key}
              students={this.props.students}
              dates={dates}
              lessons={this.props.lessons}
              tClass={this.props.tClass}
              linesBetween1={this.props.linesBetween1}
              linesBetween2={this.props.linesBetween2}
            />
          </tbody>
        </table>
      </>
    );
  }
}
class TBody extends Component {
  render() {
    const l1 = parseInt(this.props.linesBetween1)
    const l2 = parseInt(this.props.linesBetween2)
    let lines1, lines2
    if (l1 > 0) lines1 = [...Array(l1).keys()];
    if (l2 > 0) lines2 = [...Array(l2).keys()];
    let 바하반 = 0, 베토벤반 = 0, 성인반 = 0, 피아노전체_성인제외_인원수 = 0

    const banSeparate = () => {
      return (
        <>
          {
            this.props.students.map(s => {
              if (s.pianoBan !== "성인반") {
                if (s.pianoBan === "바하반") {
                  바하반 = 바하반 + 1
                  return (
                    <tr >
                      <td style={{ minWidth: 40 }}>{바하반}</td>
                      <th style={{ minWidth: 70 }} id="bach">
                        {s.name}
                      </th>
                      <td />
                      <td />
                      <td />
                      {/* //todo: 현재 진도로 바꿀것 */}
                      <ShowDates
                        key={generateKey()}
                        lessons={this.props.lessons}
                        pJin={s.pJin}
                        student={s.name}
                        dates={["20200103"]}
                        tClass={this.props.tClass}
                      />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  );
                }
                return null
              }
              return null
            })}
          {/* 한줄 띄기 */}
          {
            lines1 && lines1.map(l =>
              <tr>
                <td style={{ minWidth: 40 }}></td>
                <th style={{ minWidth: 70 }}></th>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>)
          }
          {
            this.props.students.map(s => {
              if (s.pianoBan === "베토벤반") {
                베토벤반 = 베토벤반 + 1
                return (
                  <tr>
                    <td style={{ minWidth: 40 }}>{베토벤반}</td>
                    <th style={{ minWidth: 70 }}>{s.name}</th>
                    <td />
                    <td />
                    <td />
                    {/* //todo: 현재 진도로 바꿀것 */}
                    <ShowDates
                      key={this.props.key + "b" + s.name}
                      lessons={this.props.lessons}
                      pJin={s.pJin}
                      student={s.name}
                      dates={["20200103"]}
                      tClass={this.props.tClass}
                    />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                );
              }
              return null
            })
          }

          {/* 한줄 띄기 */}
          {
            lines2 && lines2.map(l =>
              <tr>
                <td style={{ minWidth: 40 }}></td>
                <th style={{ minWidth: 70 }}></th>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>)
          }
        </>
      )
    }
    const banUnseparate = () => {
      return (
        <>
          {
            this.props.students.map(s => {
              if (s.pianoBan !== "성인반") {
                피아노전체_성인제외_인원수 = 피아노전체_성인제외_인원수 + 1
                return (
                  <tr >
                    <td style={{ minWidth: 40 }}>{피아노전체_성인제외_인원수}</td>
                    <th style={{ minWidth: 70 }} id="bach">
                      {s.name}
                    </th>
                    <td />
                    <td />
                    <ShowDates
                      key={generateKey()}
                      lessons={this.props.lessons}
                      pJin={s.pJin}
                      student={s.name}
                      dates={["20200103"]}
                      tClass={this.props.tClass}
                      withName
                    />
                    {/* //todo: 현재 진도로 바꿀것 */}
                    <ShowDates
                      key={generateKey()}
                      lessons={this.props.lessons}
                      pJin={s.pJin}
                      student={s.name}
                      dates={["20200103"]}
                      tClass={this.props.tClass}
                    />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                );
              }
              return null
            })}
        </>
      )
    }
    return (
      <>
        {/* todo: true/false 대신 반 -> 구분/전체 선택박스를 만드시오 */}
        {false ? banSeparate() : banUnseparate()}

        < tr >
          <td colSpan='2' id="pyosi">출석인원</td>
          <td colSpan='4' id="pyosi">전체</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr >
        < tr >
          <td colSpan='2' rowSpan='4'>강사별 레슨인원</td>
          <td colSpan='2' rowSpan='2' id="bach">바하반</td>
          <td colSpan='2' id="bach">은솔쌤</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr >
        < tr >
          <td colSpan='2' id="bach">한나쌤</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr >
        < tr >
          <td colSpan='2' rowSpan='2'>베토벤반</td>

          <td colSpan='2'>화진쌤</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr >
        < tr >
          <td colSpan='2'>혜란쌤</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr >
        {
          this.props.students.map(s => {
            if (s.pianoBan === "성인반") {
              성인반 = 성인반 + 1
              return (
                <tr>
                  <td style={{ minWidth: 40 }}>{성인반}</td>
                  <th style={{ minWidth: 70 }} >
                    {s.name}
                  </th>
                  <td />
                  <td />
                  <td />
                  {/* //todo: 현재 진도로 바꿀것 */}
                  <ShowDates
                    key={generateKey()}
                    lessons={this.props.lessons}
                    student={s.name}
                    pJin={s.pJin}
                    dates={["20200103"]}
                    tClass={this.props.tClass}
                  />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              );
            }
            return null
          })
        }
      </>)
  }
}
class TBody1 extends Component {
  render() {
    const l1 = parseInt(this.props.linesBetween1)
    const l2 = parseInt(this.props.linesBetween2)
    let lines1, lines2
    if (l1 > 0) lines1 = [...Array(l1).keys()];
    if (l2 > 0) lines2 = [...Array(l2).keys()];
    let 바하반 = 0, 베토벤반 = 0, 피아노전체_성인제외_인원수 = 0

    const banSeparate = () => {
      return (
        <>
          {
            this.props.students.map(s => {
              if (s.pianoBan !== "성인반") {
                if (s.pianoBan === "바하반") {
                  바하반 = 바하반 + 1
                  return (
                    <tr >
                      <td style={{ minWidth: 40 }}>{바하반}</td>
                      <th style={{ minWidth: 70 }} id="bach">
                        {s.name}
                      </th>
                      <td />
                      {/* //todo: 현재 진도로 바꿀것 */}
                      <ShowDates
                        key={generateKey()}
                        lessons={this.props.lessons}
                        pJin={s.pJin}
                        student={s.name}
                        dates={["20200103"]}
                        tClass={this.props.tClass}
                      />
                    </tr>
                  );
                }
                return null
              }
              return null
            })}
          {/* 한줄 띄기 */}
          {
            lines1 && lines1.map(l =>
              <tr>
                <td style={{ minWidth: 40 }}></td>
                <th style={{ minWidth: 70 }}></th>
                <td />
                <td />
              </tr>)
          }
          {
            this.props.students.map(s => {
              if (s.pianoBan === "베토벤반") {
                베토벤반 = 베토벤반 + 1
                return (
                  <tr>
                    <td style={{ minWidth: 40 }}>{베토벤반}</td>
                    <th style={{ minWidth: 70 }}>{s.name}</th>
                    <td />
                    {/* //todo: 현재 진도로 바꿀것 */}
                    <ShowDates
                      key={this.props.key + "b" + s.name}
                      lessons={this.props.lessons}
                      pJin={s.pJin}
                      student={s.name}
                      dates={["20200103"]}
                      tClass={this.props.tClass}
                    />
                  </tr>
                );
              }
              return null
            })
          }

          {/* 한줄 띄기 */}
          {
            lines2 && lines2.map(l =>
              <tr>
                <td style={{ minWidth: 40 }}></td>
                <th style={{ minWidth: 70 }}></th>
                <td />
                <td />

              </tr>)
          }
        </>
      )
    }
    const banUnseparate = () => {
      return (
        <>
          {
            this.props.students.map(s => {
              if (s.pianoBan !== "성인반") {
                피아노전체_성인제외_인원수 = 피아노전체_성인제외_인원수 + 1
                return (
                  <tr >
                    <td style={{ minWidth: 40 }}>{피아노전체_성인제외_인원수}</td>
                    <th style={{ minWidth: 70 }} id="bach">
                      {s.name}
                    </th>
                    <ShowDates
                      key={generateKey()}
                      lessons={this.props.lessons}
                      pJin={s.pJin}
                      student={s.name}
                      dates={["20200103"]}
                      tClass={this.props.tClass}
                      withName
                    />
                    {/* //todo: 현재 진도로 바꿀것 */}
                    <ShowDates
                      key={generateKey()}
                      lessons={this.props.lessons}
                      pJin={s.pJin}
                      student={s.name}
                      dates={["20200103"]}
                      tClass={this.props.tClass}
                    />
                  </tr>
                );
              }
              return null
            })}
        </>
      )
    }
    return (
      <>
        {/* todo: true/false 대신 반 -> 구분/전체 선택박스를 만드시오 */}
        {false ? banSeparate() : banUnseparate()}


      </>)
  }
}

class ShowDates extends Component {
  render() {
    return this.props.dates.map(d => {
      return (
        <>
          <Lesson1
            key={this.props.key + "a" + d}
            date={d}
            lessons={this.props.lessons}
            student={this.props.student}
            pJin={this.props.pJin}
            tClass={this.props.tClass}
            withName={this.props.withName}
          />
        </>
      )
    });
  }
}

class Lesson1 extends Component {
  render() {
    const lesson = this.props.lessons
      .filter(l => l.date === this.props.date)
      .filter(m => m.student === this.props.student);
    return (
      <>
        <td key={this.props.key + lesson.student + lesson.date}>
          <Lesson
            key={this.props.key + lesson.student + lesson.date}
            lesson={lesson}
            tClass={this.props.tClass}
            withName={this.props.withName}
            pJin={this.props.pJin}
            student={this.props.student}
          />
        </td>
      </>
    );
  }
}
class Lesson extends Component {

  a = { ...this.props.lesson[0] };
  render() {
    let { pJin: pJin1 } = this.props
    let c;
    if (this.a.teacher !== undefined && this.props.tClass !== undefined) {
      this.props.tClass.map(m => {
        if (this.a.teacher in m) c = m[this.a.teacher];
        return null
      });
    }
    return (
      <div>
        <span className={"text-" + c}>{
          this.props.withName
            ? this.props.student + ',' + 복습포함진도(pJin1, false)
            : 복습포함진도(pJin1)
        }</span>
      </div>
    );
  }
}
